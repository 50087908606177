.ta-user-permission-types .ta-title__content .ta-btn,
.ta-user-permission-types__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-user-permission-types__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-user-permission-types .ta-title__content .ta-title__link {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
}
.ta-user-permission-types .ta-title__content :not(:last-child) {
  margin-right: 4px;
}

.ta-user-permission-types .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-user-permission-types .ta-icon-avatar {
  min-width: 48px;
  min-height: 48px;
}

.ta-user-permission-types .ta-permission-types__item.ta-btn {
  border-radius: 3px;
  margin-bottom: 8px;
  background-color: #fff;
  padding: 8px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(182, 186, 189, 0.5);
  height: fit-content;
}

.ta-user-permission-types .ta-permission-types__item .ta-permission-types__item__content {
  margin-left: 8px;
}

.ta-user-permission-types .ta-permission-types__item .ta-permission-types__item__content .ta-permission-types__item__content__name {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #505a5e;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  word-break: break-word;
}

.ta-user-permission-types .ta-permission-types__item .ta-permission-types__item__content .ta-permission-types__item__content__global {
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #505a5e;
  padding: 1px 4px;
  border-radius: 4px;
  border: solid 1px #b6babd;
  margin-left: 8px;
}

.ta-user-permission-types .ta-permission-types__item .ta-permission-types__item__content .ta-permission-types__item__content__name i {
  font-size: 12px;
  margin-left: 8px;
}

.ta-user-permission-types .ta-permission-types__item .ta-permission-types__item__content .ta-permission-types__item__content__resources i {
  margin-right: 4px;
}

.ta-user-permission-types .ta-permission-types__item .ta-permission-types__item__content .ta-permission-types__item__content__resources {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #505a5e;
}

.ta-user-permission-types .ta-permission-types__limit-banner {
  margin: 0 0 16px 0;
  border-radius: 3px;
  overflow: hidden;
}

.ta-user-permission-types .ta-permission-types__limit-banner .ta-permission-types__limit-banner__title {
  display: flex;
  justify-content: space-between;
  background-color: #e5e7e7;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: #505a5e;
  text-transform: uppercase;
  padding: 6px 8px;
}

.ta-user-permission-types .ta-permission-types__limit-banner span {
  color: #505a5e;
}

.ta-user-permission-types .ta-permission-types__limit-banner.ta-permission-types__limit-banner__near-limit .ta-permission-types__limit-banner__title {
  background-color: #FCF4E7;
}

.ta-user-permission-types .ta-permission-types__limit-banner.ta-permission-types__limit-banner__at-limit .ta-permission-types__limit-banner__title {
  background-color: rgba(203, 59, 69, 0.15);
}

.ta-user-permission-types .ta-permission-types__limit-banner.ta-permission-types__limit-banner__above-limit .ta-permission-types__limit-banner__title {
  background-color: #cb3b43;
  color: #fff;
}

.ta-user-permission-types .ta-permission-types__limit-banner.ta-permission-types__limit-banner__above-limit .ta-permission-types__limit-banner__title span {
  color: #fff;
}

.ta-user-permission-types .ta-permission-types__limit-banner .ta-permission-types__limit-banner__content {
  padding: 16px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
}

.ta-user-permission-types .ta-permission-types__limit-banner .ta-permission-types__limit-banner__content .ta-permission-types__limit-banner__content__title {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #505a5e;
  margin-bottom: 8px;
}

.ta-btn-disabled-opacity {
  opacity: 0.4;
}
