.ta-color-icon-select {
  position: relative
}

.ta-color-icon-select .ta-color-icon-select__selected {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 500;
  width: 60px;
  background: #fff;
  cursor: pointer;
}

.ta-color-icon-select__selected {
  width: 60px;
  height: 40px;
  border: 1px solid rgba(80, 90, 94, 0.7);
  border-radius: 0 6px 6px 0;
}

.ta-color-icon-select__selected.active {
  background-color: #f6f7f9;
  border-radius: 0 6px 0 0;
}

.ta-color-icon-select__selected.disabled {
  cursor: default;
  background-color: #f7f8f8;
  border-color: rgba(182, 186, 189, 0.3);
}

.ta-color-icon-select__selected.disabled .ta-color-icon-select__options__color {
  cursor: default;
}

.ta-color-icon-select__selected .ta-color-icon-select__options__color {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%) translateZ(0);
}

.ta-color-icon-select__selected i.fa-sort {
  position: absolute;
  top: 13px;
  right: 12px;
  font-size: 14px;
  color: rgba(80, 90, 94, 0.7);
}

.ta-color-icon-select__selected:not(.disabled) i.fa-sort, .ta-color-icon-select__selected:not(.disabled) i.fa-sort {
  color: #505a5e;
}

.ta-color-select__selected.disabled i.fa-sort {
  color: rgba(80, 90, 94, 0.3);
  cursor: default;
}

.ta-color-icon-select__options {
  position: absolute;
  top: 39px;
  right: 0;
  z-index: 501;
  width: 214px;
  background-color: #f6f7f9;
  padding: 10px 2px 2px 10px;
  border: 1px solid rgba(80, 90, 94, 0.7);
  border-radius: 6px 0 6px 6px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}

.ta-color-icon-select__options.active {
  visibility: visible;
  opacity: 1;
}

.ta-color-icon-select__options .ta-color-icon-select__options__color {
  margin-right: 8px;
  margin-bottom: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.ta-color-icon-select__options__color {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #eee;
  cursor: pointer;
  position: relative;
}

.ta-color-icon-select .ta-form-control {
  padding-right: 55px;
}

.ta-color-icon-select .ta-color-icon-select__options__color.dotted {
  background-image: url('../../../Assets/images/patterns/pattern-dots-light.png');
  background-repeat: repeat;
  background-size: 10px;
}

.ta-color-icon-select .ta-form-input-limit {
  margin-right: 48px;
}

.ta-color-icon-select .ta-form-input-with-limit {
  padding-right: 102px;
}
