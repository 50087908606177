.ta-customers-settings .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 4px;
}

.ta-customers-settings__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-customers-settings__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-customers-settings .ta-title__content .ta-title__link {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
}

.ta-customers-settings .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-settings .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-settings__form .ta-btn.ta-customer-settings__btn-delete {
  width: 24px;
  height: 40px;
  line-height: 38px;
  padding: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.ta-settings__form .ta-settings__btn-add {
  font-weight: 500;
}

.ta-settings__form .ta-settings__btn-add.top-offset {
  margin-top: 8px;
}
.ta-btn-with-icon > i {
  margin-right: 4px;
}

.ta-settings__form .ta-settings__btn-add i {
  font-size: 14px;
}

.ta-settings__form .ta-form-text.thin-text {
  font-size: 12px;
}

.ta-settings__form .ta-settings-customers__eugdpr__rule-container {
  position: relative;
}

.ta-settings__form .ta-settings-customers__eugdpr__rule-customer-type.has-delete {
  margin-right: 30px;
}

.ta-settings__form .ta-settings-customers__eugdpr__rule-customer-tag.has-delete {
  margin-right: 30px;
}

.ta-settings__form .ta-hover-popup {
  display: block;
}

/* .ta-settings__form .ta-bordered-box .ta-row:last-child {
  margin-bottom: 8px;
} */

.ta-settings__form .ta-settings__form--inner-box {
  margin-bottom: 0;
}

.ta-settings__form .ta-settings__form--outer-box {
  margin-bottom: 0;
}

.ta-settings__form .ta-row__no-margin {
  margin-bottom: 0;
}

.ta-customers-settings .ta-section-title {
  margin-bottom: 4px;
}

.ta-list-item__participant:before {
  color: #b6babd;
}
