.ta-title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ta-title.ta-title-md .ta-title__label__text,
.ta-title.ta-title-md .ta-title__label__icon {
  font-size: 16px;
}

.ta-title.ta-title-sm .ta-title__label__text, 
.ta-title.ta-title-sm .ta-title__label__icon {
  font-size: 14px;
}

.ta-title.ignore_top_offset {
  padding-top: 0;
}

.ta-title__label {
  display: flex;
  align-items: center;
  min-width: 0;
  margin-right: 8px;
}


.ta-title__label__addon {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.ta-title__label__icon {
  font-size: 14px;
  display: flex;
  padding: 6px;
  border-radius: 6px;
  margin-right: 12px;
  color: rgba(28, 39, 43, 0.6);
  min-width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
}

.ta-title__label__icon.icon-grey {
  background-color: #e5e6e7;
  color: rgba(28, 39, 43, 0.6);
}

.ta-title__label__icon.icon-dark-grey {
  background-color: #505a5e;
  color: #ffffff
}

.ta-title__label__text {
  font-size: 20px;
  font-weight: 500;
  color: #1c272b;
  letter-spacing: 0.2px;
  line-height: 1.6;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ta-title.ta-title__compact .ta-title__label__text {
  font-size: 14px;
  line-height: 1.5;
}

.ta-title.ta-title__centered {
  justify-content: center;
}

.ta-title.ta-title__centered .ta-title__label .ta-title__label__icon {
  position: absolute;
  left: 0;
}

.ta-title.ta-title-sm .ta-title__label__icon,
.ta-title.ta-title__compact .ta-title__label__icon {
  margin-right: 4px;
  min-width: 24px;
  height: 24px;
}

.ta-title-addon__text {
  color: #a1a6a8;
  font-size: 12px;
  font-style: italic;
  margin-left: 5px;
  position: relative;
  vertical-align: middle;
}

.ta-title__content {
  display: flex;
  align-items: center;
}

.ta-title__content .ta-btn.ta-title__link {
  color: #505a5e;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  height: 100%;
  margin-right: 4px;
  opacity: .5;
  padding: 0;
  width: 100%;
}

.ta-title__content .ta-btn.ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-title__content .ta-btn.ta-btn-icon-only {
  align-items: center;
  border: none;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-left: 4px;
}

.ta-title.ta-title-md .ta-title__label__icon:not(.icon-grey) {
  margin-right: 2px;
}