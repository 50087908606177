.ta-apps .ta-fixed-content__body {
  top: 120px;
}

.ta-apps .ta-title__content .ta-btn,
.ta-apps__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  border: none;
}

.ta-apps .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 30px;
  display: flex;
}

.ta-apps .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-apps__preview .ta-title__content :not(:last-child) {
  margin-right: 4px;
}

.ta-apps .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 54px;
  background: #fff;
  left: 24px;
  right: 24px;
}

.ta-apps__skeleton {
  font-size: 12px;
  line-height: 16px;
  color: #505a5e;
}

.ta-apps__skeleton__head-version {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  font-weight: 500;
  color: #505a5e;
  border-radius: 3px;
  background: #b6babd;
  margin-right: 3px;
  font-style: italic;
}

.ta-apps__skeleton__header-image {
  width: 100%;
  border-radius: 3px 3px 0 0;
}

.ta-apps__skeleton__header-text {
  margin-bottom: 16px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #505a5e;
}

.ta-apps__skeleton__container-box .ta-striped-title__label {
  background: #fff;
}

.ta-apps__skeleton__container-box {
  padding: 16px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  border-radius: 0 0 3px 3px;
  border-top: none;
  margin-top: -4px;
}

.ta-apps__skeleton__footer {
  margin-top: 16px;
  margin-bottom: 30px;
}

.ta-apps__uninstall .ta-striped-title .ta-striped-title__label {
  font-size: 10px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.5px;
  background: #fff;
}

.ta-apps__uninstall .ta-btn-primary,
.ta-apps__uninstall .ta-btn-black-ghost {
  font-weight: bold;
  margin-top: 8px;
}

.ta-apps__uninstall .ta-popup__trigger {
  width: 100%;
}

.ta-apps__uninstall .ta-form-text {
  line-height: 16px;
  padding-bottom: 9px;
  font-size: 12px;
  font-weight: 300;
}

.ta-apps__uninstall .ta-form {
  padding-bottom: 0;
}
  
.ta-apps__preview__roles > ul > li {
  border: 1px solid rgba(182, 186, 189, 0.5);
  border-radius: 3px;
  margin-bottom: 8px;
  position: relative;
  padding-left: 40px;
}

.ta-apps__preview__roles-role {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
}

.ta-apps__preview__roles-icon {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #cb3b43;
  color: #fff;
  border-radius: 3px 0 0 3px;
}

.ta-apps__preview__roles-types {
  display: inline-block;
  margin-left: 5px;
}

.ta-apps__list .ta-message .ta-form {
  padding-bottom: 0;
}

.ta-apps__uninstall .ta-bordered-box .ta-apps__skeleton__header-text {
  margin-bottom: 8px;
}

.ta-apps__uninstall .ta-hover-popup {
  width: 100%;
}

.ta-apps .ta-fixed-content:before {
  top: 165px;
  left: 35px;
  right: 35px;
  background: linear-gradient(to bottom, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
}

.ta-apps__list .ta-simple-tabs-privateApps .ta-alert .ta-btn,
.ta-apps__list .ta-simple-tabs-privateApps .ta-alert .ta-form {
  margin-top: 8px;
}

.ta-apps__list .ta-simple-tabs-privateApps .ta-alert .ta-form {
  padding-bottom: 0;
}

.ta-apps__list .ta-simple-tabs-privateApps .ta-alert .ta-copy-to-clipboard__button {
  margin-top: 0;
}

.ta-apps__preview .ta-fixed-content .ta-fixed-content__body .ta-form .ta-striped-title .ta-striped-title__label{
  background-color: #fff;
}

.ta-apps__filters .ta-form {
  padding-bottom: 8px;
}
