.ta-courses__category-form .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-courses__category-form .ta-courses__category-type {
  display: inline-block;
  margin-top: 3px;
  cursor: default;
}

.ta-courses__category-form .ta-section-title {
  margin-top: 0;
}

.ta-courses__category-form .ta-hover-popup {
  width: 100%;
}
