.ta-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80%;
  background: #fff;
  border-radius: 5px;
  z-index: 6000;
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s;
}

.ta-modal.active {
  visibility: visible;
  opacity: 1;
}

.ta-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #fff;
  color: #505a5e;
  border-radius: 0 3px 3px 0;
  margin-right: -32px;
  margin-top: 20px;
  cursor: pointer;
}
