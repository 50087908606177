.ta-notifications__title {
  padding: 20px 0 15px;
}

.ta-notifications__system-messages__list {
  width: calc(100% + 30px);
  height: 100%;
  overflow: auto;
  margin: 0 -15px;
  padding: 15px;
}

.ta-notifications__system-messages__title {
  padding: 20px 0 15px;
}

.ta-notifications__system-messages__filter .ta-form {
  padding-bottom: 8px;
}

.ta-notifications__system-messages.has-advanced-filter .ta-fixed-content__body {
  padding-top: 0;
}

.ta-notifications__system-messages__filter__emails-count {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  line-height: 20px;
}

.ta-notifications__system-messages__filter__btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #505a5e;
  float: right;
  cursor: pointer;
  user-select: none;
}

.ta-notifications__system-messages__filter__btn i {
  margin-left: 5px;
}

.ta-notifications__filter__notifications-count {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  line-height: 20px;
}

.ta-notifications__filter__notifications-advanced-btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #505a5e;
  float: right;
  cursor: pointer;
  user-select: none;
}

.ta-notifications__filter__notifications-advanced-btn i {
  margin-left: 5px;
}