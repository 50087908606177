.ta-logo {
  display: block;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  background: #cb3b43;
}

.ta-logo img {
  width: 40px;
  vertical-align: middle;
  font-size: 11px;
  color: #fff;
}

.ta-blue-template-badge {
  width: 104px;
  height: 24px;
  font-size: 9px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
