.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: #505a5e;
  background-color: #f0f1f2;
  padding: 4px;
  border-radius: 3px;
  border: solid 1px #c5c8ca;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__title:last-child {
  margin-bottom: 0;
}

.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__tag {
  display: inline-block;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  padding: 1px 4px;
  border-radius: 4px;
}

.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__tag__yes {
  background-color: #769851;
  text-transform: none;
}

.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__tag__no {
  background-color: #cb3b43;
  text-transform: none;
}

.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__wrapper .ta-permission-types__preview__summary__permission__title {
  border: none;
  border-radius: 0;
  margin: 0;
  text-transform: uppercase;
}

.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__wrapper {
  border: solid 1px #c5c8ca;
  margin-bottom: 8px;
  border-radius: 3px;
  overflow: hidden;
}

.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__wrapper .ta-permission-types__preview__summary__permission__content {
  padding: 8px;
  border-top: solid 1px #c5c8ca;
}

.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__content .ta-permission-types__preview__summary__permission__tag {
  padding: 1px 4px;
  background-color: #a7acae;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 4px;
}

.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__content .ta-permission-types__preview__summary__permission__row {
  display: flex;
  flex-direction: row;
}

.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__content .ta-permission-types__preview__summary__permission__row:not(:last-child) {
  margin-bottom: 8px;
}

.ta-permission-types__preview__summary__permissions .ta-permission-types__preview__summary__permission__row .ta-permission-types__preview__summary__permission__row__title {
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: #505a5e;
  opacity: 0.7;
  width: 112px;
  text-transform: uppercase;
}
