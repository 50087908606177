.ta-permission-types .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 4px;
}

.ta-permission-types__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-permission-types .ta-title__content .ta-title__link {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 0px;
  display: flex;
}

.ta-permission-types .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-permission-types .ta-permission-types__item__content__global {
  display: inline-block;
  transform: translateY(-1px);
}

.ta-permission-types .ta-permission-types__item__content__global .ta-btn {
  padding-left: 0;
}

.ta-permission-types .ta-permission-types__item__content__global .global-label {
  font-size: 10px;
  line-height: 14px;
  border: 1px solid #b6babd;
  color: #505a5e;
  font-weight: 500;
  padding: 1px 4px;
  border-radius: 3px;
}

.ta-permission-types .ta-permission-types__item__content__global i {
  margin-right: 2px;
}

.ta-permission-types .ta-permission-types__item__content__global .global-label.edited {
  border: solid 1px rgba(235, 182, 93, 0.4);
  background-color: rgba(235, 182, 93, 0.2);
}

.ta-permission-types .ta-permission-types__item__content__global .global-label.deleted {
  border: solid 1px rgba(203, 59, 67, 0.4);
  background-color: rgba(203, 59, 67, 0.2);
}

.ta-permission-types .ta-permission-types__item {
  position: relative;
  border-radius: 3px;
  margin-bottom: 8px;
  background-color: #fff;
  padding: 8px;
  display: flex;
  align-items: center;
  border: solid 1px rgba(182, 186, 189, 0.5);
  min-height: 64px;
  padding-left: 66px;
  height: fit-content;
}

.ta-permission-types .ta-permission-types__item .ta-icon-avatar {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.ta-permission-types .ta-permission-types__item:hover {
  background: #f6f8fa;
  border-color: #505a5e;
}

.ta-permission-types .ta-permission-types__item .ta-permission-types__item__content {
  width: 100%;
}

.ta-permission-types .ta-permission-types__item .ta-permission-types__item__content .ta-permission-types__item__content__name {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #505a5e;
  word-break: break-word;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;

}

.ta-permission-types .ta-permission-types__item .ta-permission-types__item__content .ta-permission-types__item__content__name .ta-permission-types__item__content__name_title {
  white-space: nowrap;
  text-overflow: ellipsis;    
  overflow: hidden;
}

.ta-permission-types .ta-permission-types__item__content__name__lock {
 font-size: 13px;
 display: inline-block;
}

.ta-permission-types .ta-permission-types__item .ta-permission-types__item__content .ta-permission-types__item__content__resources {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #505a5e;
}

.ta-permission-types .ta-permission-types__limit-banner {
  margin: 0 0 16px 0;
  border-radius: 3px;
  overflow: hidden;
}

.ta-permission-types .ta-permission-types__limit-banner .ta-permission-types__limit-banner__title {
  display: flex;
  justify-content: space-between;
  background-color: #e5e7e7;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: #505a5e;
  text-transform: uppercase;
  padding: 6px 8px;
}

.ta-permission-types .ta-permission-types__limit-banner span {
  color: #505a5e;
}

.ta-permission-types .ta-permission-types__limit-banner.ta-permission-types__limit-banner__near-limit .ta-permission-types__limit-banner__title {
  background-color: rgba(235, 182, 93, 0.15);
}

.ta-permission-types .ta-permission-types__limit-banner.ta-permission-types__limit-banner__at-limit .ta-permission-types__limit-banner__title {
  background-color: rgba(203, 59, 69, 0.15);
}

.ta-permission-types .ta-permission-types__limit-banner.ta-permission-types__limit-banner__above-limit .ta-permission-types__limit-banner__title {
  background-color: #cb3b43;
  color: #fff;
}

.ta-permission-types .ta-permission-types__limit-banner.ta-permission-types__limit-banner__above-limit .ta-permission-types__limit-banner__title span {
  color: #fff;
}

.ta-permission-types .ta-permission-types__limit-banner .ta-permission-types__limit-banner__content {
  padding: 16px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
}

.ta-permission-types .ta-permission-types__limit-banner .ta-permission-types__limit-banner__content .ta-permission-types__limit-banner__content__title {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #505a5e;
  margin-bottom: 8px;
}

.ta-permission-types__list .ta-simple-tabs.scrollable-content {
  display: flex;
  flex-direction: column;
}

.ta-permission-types__list .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  position: relative;
  top: 0;
  left: 0;
}