.ta-notifications__notification-card__event {
  position: relative;
  background: #fff;
  border-radius: 3px;
  margin-bottom: 8px;
  color: #505a5e;
  border: 1px solid rgba(182, 186, 189, 0.5);
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__head {
  height: 48px;
  line-height: 24px;
  border-radius: 0 3px 0 0;
  padding: 0 8px;
  border-bottom: 1px solid rgba(182, 186, 189, 0.5);
  display: flex;
  align-items: center;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__head .ta-notifications__notification-card__event__head-icon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #F1F4ED;
  border: 1px solid #ADC197;
  margin-right: 8px;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__head .ta-notifications__notification-card__event__head-icon ::before {
  color: #769851;
  font-weight: 400;
}

.ta-notifications__notification-card__event.pending .ta-notifications__notification-card__event__head .ta-notifications__notification-card__event__head-icon {
  background-color: #FCF4E6;
  border: 1px solid #F1CE94;
}

.ta-notifications__notification-card__event.pending .ta-notifications__notification-card__event__head .ta-notifications__notification-card__event__head-icon ::before {
  color: #CC9E52;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__head .ta-notifications__notification-card__event__head-title {
  font-size: 10px;
  line-height: 16px;
  color: #505A5E;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__head .ta-notifications__notification-card__event__head-title .ta-notifications__notification-card__event__head-title-text {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__head .ta-notifications__notification-card__event__head-title .ta-notifications__notification-card__event__head-title-time {
  display: flex;
  gap: 2px;
  letter-spacing: 0.5px;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__head .ta-notifications__notification-card__event__head-title .ta-notifications__notification-card__event__head-title-time .ta-notifications__notification-card__event__head-title-time-left {
  opacity: .5;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__head .ta-notifications__notification-card__event__head-title .ta-notifications__notification-card__event__head-title-time .ta-notifications__notification-card__event__head-title-time-right {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-notifications__notification-card__event__head .ta-notifications__notification-card__event__head-title .ta-notifications__notification-card__event__head-title-time .fal.fa-hourglass-half.ta-notifications__notification-card__event__head-title-time-right::before {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-notifications__notification-card__event__head .ta-notifications__notification-card__event__head-title .ta-notifications__notification-card__event__head-title-time .ta-countdown {
  opacity: .8;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body {
  padding: 12px;
  display: flex;
  gap: 12px;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__date-time-box {
  width: 84px;
  min-height: 84px;
  border-radius: 4px;
  background-color: #F4F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__date-time-box__content {
  text-align: center;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__date-time-box__content-date {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__date-time-box__content-time {
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  opacity: 0.8;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content .ta-notifications__notification-card__event__content-body {
  display: flex;
  gap: 8px;
}

/* requester avatar stylisation */
.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content .ta-notifications__notification-card__event__content-body .ta-notifications__notification-card__event__content-body-avatar {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  margin-bottom: 0;
}

/* requester avatar initials stylisation */
.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content .ta-notifications__notification-card__event__content-body .ta-notifications__notification-card__event__content-body-avatar .ta-avatar__initials {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content .ta-notifications__notification-card__event__content-body .ta-notifications__notification-card__event__content-body-text {
  display: flex;
  align-items: center;
  font-size: 12px;
}

/* button container stylisation */
.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content .ta-notifications__notification-card__event__content-actions {
  display: flex;
  gap: 8px;
}

/* button stylisation */
.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content .ta-notifications__notification-card__event__content-actions * {
  flex: 1;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content .ta-notifications__notification-card__event__content-actions .ta-notifications__notification-card__event__content-actions-decline {
  background-color: #B6BABD;
  color: #505A5E;
  font-weight: bold;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content .ta-notifications__notification-card__event__content-actions .ta-notifications__notification-card__event__content-actions-decline:hover {
  background-color: #A3A7A9;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content .ta-notifications__notification-card__event__content-actions .ta-notifications__notification-card__event__content-actions-decline-icon {
  margin-right: 4px;
  color: #505A5E;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content .ta-notifications__notification-card__event__content-actions .ta-notifications__notification-card__event__content-actions-accept-icon {
  margin-right: 4px;
  color: white;
}

.ta-notifications__notification-card__event .ta-notifications__notification-card__event__body .ta-notifications__notification-card__event__content .ta-notifications__notification-card__event__content-actions .ta-notifications__notification-card__event__content-actions-revoke-icon {
  margin-right: 4px;
  color: white;
}