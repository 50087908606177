.ta-tags__tag .violet.ta-simple-list-item:after {
  background-color: #A476CA
}

.ta-tags__tag .dusk.ta-simple-list-item:after {
  background-color: #5D5A79
}

.ta-tags__tag .grey.ta-simple-list-item:after {
  background-color: #8B8B8B
}

.ta-tags__tag .breaks.ta-simple-list-item:after {
  background-color: #EBB65D
}

.ta-tags__tag .ocher.ta-simple-list-item:after {
  background-color: #CC9E52
}

.ta-tags__tag .orange.ta-simple-list-item:after {
  background-color: #E5774C
}

.ta-tags__tag .brown.ta-simple-list-item:after {
  background-color: #C46F4E
}

.ta-tags__tag .wood.ta-simple-list-item:after {
  background-color: #7B6161
}

.ta-tags__tag .green.ta-simple-list-item:after {
  background-color: #82A774
}

.ta-tags__tag .light-green.ta-simple-list-item:after {
  background-color: #95C5A6
}

.ta-tags__tag .aqua.ta-simple-list-item:after {
  background-color: #8EBEC3
}

.ta-tags__tag .light-blue.ta-simple-list-item:after {
  background-color: #6882B0
}

.ta-tags__tag .purple.ta-simple-list-item:after {
  background-color: #7684CA
}

.ta-tags__tag .red.ta-simple-list-item:after {
  background-color: #B22E53
}

.ta-tags__tag .course.ta-simple-list-item:after {
  background-color: #B26492
}

.ta-tags__tag .default.ta-simple-list-item:after {
  background-color: #385f87
}
