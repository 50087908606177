.ta-tags .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 4px;
}

.ta-tags__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-tags__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-tags .ta-title__content .ta-title__link {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
}

.ta-tags .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-tags .ta-simple-list-item {
  color: #505a5e;
  font-weight: 500;
  margin-bottom: 8px !important;
}

.ta-tags-list-item-tag {
  border-radius: 3px;
  text-transform: capitalize;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  color: #ffffff;
  padding: 0 4px;
  float: left;
  margin-right: 4px;
}

.ta-tags__preview .ta-simple-tabs.scrollable-content .ta-simple-tabs__body{
  top: 54px;
  background: #fff;
  left: 24px;
  right: 24px;
}

.ta-tags__tag__is-stalled {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ecd007;
  width: 30px;
  border-radius: 0 2px 2px 0;
}

.ta-tags__tag__is-stalled i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
}

.ta-tags__tag.is-stalled .ta-tags__tag__is-stalled {
  display: block;
}

.ta-tags .ta-simple-list-item.draggable .ta-tags__tag__is-stalled {
  display: none;
}

.ta-tags__tag.updated {
  position: relative;
}

.ta-tags__tag.updated:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  animation-duration: 2s;
  animation-name: user-updated-before;
  animation-iteration-count: 1;
}

@keyframes user-updated-before {
  0% {
    opacity: 1;
    background: rgba(244, 243, 243, 1);
  }
  100% {
    opacity: 0;
    background: rgba(244, 243, 243, 0);
  }
}

.ta-tags__tag.updated:after {
  content: "\f021";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  opacity: 0;
  animation-duration: 2s;
  animation-name: user-updated-after;
  animation-iteration-count: 1;
}

@keyframes user-updated-after {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ta-tags__tag.deleted {
  position: relative;
}

.ta-tags__tag.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  animation-duration: 2s;
  animation-name: user-deleted-before;
  animation-iteration-count: 1;
}

@keyframes user-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0);
  }
  15% {
    background: rgba(244, 243, 243, 0.7);
  }
  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-tags__tag.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  animation-duration: 2s;
  animation-name: user-deleted-after;
  animation-iteration-count: 1;
}

.ta-tags__tag .ta-list-inline__item {
  word-break: break-all;
}

.ta-tags__tag .ta-list-inline__item i {
  margin-right: 8px;
}

.ta-tags__tag .ta-simple-list-item:after {
  background-image: -webkit-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
  background-image: -moz-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
  background-image: -ms-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
  background-image: repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
  -webkit-background-size: 3px 3px;
  -moz-background-size: 3px 3px;
  background-size: 3.5px 3.5px;
}
