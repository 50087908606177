.ta-account .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 4px;
}

.ta-account__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-account__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-account .ta-title__content .ta-title__link {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
}

.ta-account .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-account .ta-list .ta-popup__trigger {
  margin-left: 5px;
}

.ta-account-form__password-change .ta-account__password-type-toggle-btn,
.ta-account-form__email-change .ta-account__password-type-toggle-btn,
.ta-account-form .ta-account__password-type-toggle-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #505a5e;
}

.ta-account .ta-icon-list.not-confirmed .ta-icon-list__title {
  opacity: 0.5;
  padding-right: 115px;
}

.ta-account .ta-icon-list.not-confirmed .ta-icon-list__title span {
  font-style: italic;
  font-weight: 300;
  padding-left: 2px;
  font-size: 12px;
}

.ta-account__details .ta-icon-list .ta-popup__trigger a {
  color: #fff;
}

.ta-account__details__invitation__btn {
  font-size: 10px;
  font-weight: 300;
  color: #505a5e;
  cursor: pointer;
  text-decoration: underline;
  background: rgba(203, 59, 67, 0.15);
  border-radius: 3px;
  padding: 0 8px;
}

.ta-account__details__invitation__btn i {
  margin-right: 4px;
}

.ta-account .ta-account__details .ta-account__action-button {
  padding: 8px 12px;
}

.ta-account__details .ta-account__pending-invitation {
  display: inline-block;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  opacity: 0.5;
}

.ta-account__details .ta-account__pending-invitation-container .ta-icon-list__title {
  color: #a7acae;
}

.ta-form.ta-user-account-security-form {
  padding: 0;
}

.ta-form.ta-user-account-security-form .ta-list  {
  padding-bottom: 0;
}

.ta-user-account-security-2fa-enabled__title {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  margin-bottom: 4px;
}

.ta-user-account-security-2fa-enabled__description {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  margin-bottom: 8px;
}

.ta-user-account-security-2fa-enabled__button {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 3px;
  border: solid 1px #505a5e;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
  background-color: #fff;
}

.ta-user-account-security-2fa-enabled__bold {
  font-weight: bold;
}
