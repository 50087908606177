.ta-resources__category-type {
  font-weight: 500;
  cursor: pointer;
  height: 16px;
  line-height: 16px;
  background: #769851;
  color: #fff;
  padding: 0 5px;
  margin-right: 5px;
  border-radius: 3px;
  font-size: 10px;
  transform: translateY(-2px) translateZ(0);
  z-index: 10;
}

.ta-resources__category-type.dependency {
  background: #ecd007;
  color: #505a5e;
}

.ta-resources__category-form .ta-resources__category-type {
  display: inline-block;
  margin-top: 1px;
  cursor: default;
}

.ta-resources__category-form .ta-hover-popup {
  width: 100%;
}
