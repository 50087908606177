.ta-bordered-box {
  background: #fff;
  border: solid 1px rgba(182, 186, 189, 0.5);
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 10px;
}

.ta-bordered-box .ta-row:last-child {
  margin-bottom: 0;
}

.ta-bordered-box.top-offset {
  margin-top: 5px;
}

.ta-bordered-box.no-offset {
  margin-bottom: 0px;
  margin-top: 0px;
}
