.ta-account .ta-title__content .ta-btn,
.ta-account__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-account__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-account .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
}

.ta-account .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-account .ta-list .ta-popup__trigger {
  margin-left: 5px;
}

.ta-account .ta-list-underline {
  border-bottom: solid 1px #919191;
}

.ta-popup__content.top.ta-delete-account .ta-popup__content-inner, .ta-popup__content.right.ta-delete-account .ta-popup__content-inner {
  max-height: 600px;
}
