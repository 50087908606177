.ta-select.inline {
  height: auto;
}

.ta-select.inline.focused {
  transition: all 0.5s;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
}

.ta-select .ta-multi-select__values{
  position: relative;
}

.ta-select .ta-select-input {
  display: block;
  width: 100%;
  border: 1px solid rgba(80, 90, 94, 0.4);
  border-radius: 6px;
  height: 40px;
  line-height: 40px;
  padding: 14px 7px 0;
  font-size: 12px;
  font-weight: normal;
  color: #505a5e;
  outline: none;
  transition: 
    background-color 0.5s, 
    border-color 0.5s, 
    color 0.5s,
    box-shadow 0.5s,
    border-radius 0.5s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.33;
  cursor: default;
  caret-color: transparent;
}

.ta-select.searchable .ta-select-input {
  cursor: text;
  caret-color: auto;
}

.ta-select .ta-select-input:hover {
  color: rgba(80, 90, 94, 0.9);
  border-color: #505a5e;
  background-color: #edeeee;
}

.ta-select .ta-select-input::placeholder {
  height: 40px;
  line-height: 40px;
}

.ta-select .ta-select-input:disabled {
  background-color: #f7f8f8;
  border-color: rgba(182, 186, 189, 0.3);
  pointer-events: none;
  color: rgba(80, 90, 94, 0.4);
}

.ta-select.hasValue .ta-select-input:not(:disabled) {
  border-color: rgba(80, 90, 94, 0.7);
  font-size: 12px;
  color: #505a5e;
  font-weight: normal;
}

.ta-select.hasValue.isClearable .ta-select-input:not(:disabled) {
  padding-right: 38px;
}

.ta-select.active .ta-select-input {
  border-color: rgba(80, 90, 94, 0.8);
  background-color: #f6f7f7;
}

.ta-select.focused .ta-select-input {
  color: rgba(80, 90, 94, 0.9);
  border-color: rgba(80, 90, 94, 0.8);
  background-color: #f6f7f7;
  font-size: 12px;
}

.ta-select:not(.focused) .ta-select-input:hover {
  box-shadow: 0 0 5px 0 rgba(80, 90, 94, 0.3);
}

.ta-select.focused.inline .ta-select-input {
  box-shadow: none;
}

.ta-select.hasError:not(.active) .ta-select-input {
  border-color: #cb3b43;
  color: rgba(203, 59, 67, 0.7);
}

.ta-select.hasError:not(.active) .ta-select-input::placeholder {
  color: rgba(203, 59, 67, 0.7);
}

.ta-select.focused .ta-select-input::placeholder {
  color: transparent;
}

.ta-select.active.bottom .ta-select-input {
  border-radius: 6px 6px 0 0;
}

.ta-select.active.top .ta-select-input {
  border-radius: 0 0 6px 6px;
}

.ta-select.active.right .ta-select-input {
  border-radius: 6px;
}

.ta-select.active.left .ta-select-input {
  border-radius: 6px;
}

.ta-select.no-search .ta-select-input {
  color: transparent;
}

.ta-select.hasPrefix .ta-select-input {
  padding-left: 37px;
}

.ta-select .ta-select__options-input-wrapper {
  position: relative;
}

.ta-select .ta-select__options-input-wrapper:after {
  content: "\f002";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-size: 14px;
  color: rgba(80, 90, 94, 0.7);
}

.ta-select .ta-select__options-input {
  display: block;
  width: calc(100% - 8px);
  border: solid 1px rgba(80, 90, 94, 0.4);
  border-radius: 6px;
  height: 32px;
  line-height: 32px;
  padding: 0 8px;
  margin: 4px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: rgba(80, 90, 94, 0.6);
  outline: none;
  transition: 
    background-color 0.5s, 
    border-color 0.5s, 
    color 0.5s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-select .ta-select__options-input:hover,
.ta-select.focused .ta-select__options-input {
  color: rgba(80, 90, 94, 0.9);
  border-color: #505a5e;
  background-color: #edeeee;
}

.ta-select.active .ta-select__options-input {
  border-color: rgba(80, 90, 94, 0.8);
}

.ta-select .ta-select__options-input.has-search-value {
  border-color: rgba(80, 90, 94, 0.7);
  color: #505a5e;
}

.ta-select .ta-select__options-input:not(.has-search-value) {
  text-transform: uppercase;
}

.ta-select .ta-select__options-input:disabled {
  background-color: #f7f8f8;
  border-color: rgba(182, 186, 189, 0.3);
  pointer-events: none;
  color: rgba(80, 90, 94, 0.3);
}

.ta-select .ta-select__options-input:after {
  content: "\f002";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: calc(50% + 2px);
  right: 10px;
  font-size: 13px;
  color: #505a5e;
  transform: translateY(-50%) translateZ(0);
}

.ta-select .ta-select__arrow {
  cursor: pointer;
  position: absolute;
  color: rgba(80, 90, 94, 0.7);
  font-size: 14px;
  top: 13px;
  right: 10px;
}

.ta-select .ta-select__arrow.disabled {
  color: rgba(80, 90, 94, 0.3);
  cursor: default;
}

.ta-select.focused .ta-select__arrow:not(.disabled),
.ta-select.hasValue .ta-select__arrow:not(.disabled) {
  color: #505a5e;
}

.ta-select.hasError:not(.active) .ta-select__arrow {
  color: rgba(203, 59, 67, 0.7);
  cursor: default;
}

.ta-select.hasError.focused:not(.active) .ta-select__arrow,
.ta-select.hasError.hasValue:not(.active) .ta-select__arrow {
  color: #cb3b43;
}

.ta-select .ta-select__options-wrapper {
  position: absolute;
  box-shadow: none;
  border: 1px solid rgba(80, 90, 94, 0.8);
  background-color: #f6f7f7;
  z-index: 1000;
  max-height: 160px;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.ta-select .ta-select__options-wrapper .ta-select__options {
  overflow: auto;
  max-height: 160px;
  width: 100%;
  padding: 4px;
}

.ta-select.hasError:not(.active) .ta-select__options-wrapper {
  border-color: #cb3b43;
}

.ta-select .ta-select__options-wrapper.bottom {
  top: 38px;
  left: 0;
  right: 0;
  border-radius: 0 0 6px 6px;
  margin-top: 1px;
  margin-bottom: 10px;
}

.ta-select .ta-select__options-wrapper.top {
  bottom: calc(100% - 2px);
  left: 0;
  right: 0;
  border-radius: 6px 6px 0 0;
  margin-top: 10px;
  margin-bottom: 1px;
}

.ta-select .ta-select__options-wrapper.right {
  top: calc(-80px + 50%);
  left: 100%;
  right: -100%;
  border-radius: 6px;
  margin-left: 1px;
}

.ta-select .ta-select__options-wrapper.left {
  top: calc(-80px + 50%);
  left: -100%;
  right: 100%;
  border-radius: 6px;
  margin-right: 1px;
}

.ta-select .ta-select__options-wrapper.pending {
  min-height: 40px;
}

.ta-select.active .ta-select__options-wrapper {
  visibility: visible;
  opacity: 1;
}

.ta-select .ta-select__options__item {
  border-bottom: 1px dotted #505a5e80;
  font-size: 12px;
  padding: 4px;
  height: 32px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  color: #505a5e;
  display: flex;
  align-items: center;
}

.ta-select.hasError:not(.active) .ta-select__options__item {
  border-color: #cb3b43;
  color: rgba(203, 59, 67);
}

.ta-select .ta-select__options__item:not(:first-child):before {
  content: "";
  border-top: 2px solid #f6f7f7;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ta-select .ta-select__options__item:not(:last-child):after {
  content: "";
  border-top: 2px solid #f6f7f7;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ta-select .ta-select__options__item:last-child {
  border-bottom: none;
}

.ta-select .ta-select__options__item.hasAvatar {
  padding: 6px 4px;
}

.ta-select .ta-select__options__item.hasAvatar .ta-avatar__image {
  height: 20px;
  width: 20px;
}

.ta-select .ta-select__options__item.hasAvatar .ta-avatar__image img {
  height: 20px;
  width: 20px;
}

.ta-select .ta-select__options__item.active {
  font-weight: 500;
}

.ta-select .ta-select__options__item.selected,
.ta-select .ta-select__options__item:hover {
  background-color: rgba(80, 90, 94, 0.1);
}

.ta-select .ta-select__options__item .ta-avatar {
  width: 20px;
  height: 20px;
  line-height: 21px;
  font-size: 7px;
  margin: 0;
  padding: 0;
  margin-right: 4px;
  vertical-align: middle;
}

.ta-select .ta-select__options__item__color {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
}

.ta-select .ta-select__options__item__color.selected {
  position: absolute;
  top: 10px;
  left: 10px;
}

.ta-select .ta-select__options__item.active .ta-select__options__item__color {
  border: 2px solid #fff;
  border-radius: 2px;
}

.ta-select .ta-select__options__item__empty {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #e1e3e4;
  color: rgba(80, 90, 94, 0.7);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-select .ta-select__options__item__color.disabled,
.ta-select .ta-select__options__item__empty.disabled {
  opacity: 0.3;
}

.ta-select .ta-select__options__no-results-text {
  font-size: 11px;
  color: #969c9e;
  font-weight: 100;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
}

.ta-select .ta-select__options__category {
  border-bottom: 1px dotted #505a5e80;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 4px;
  height: 34px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: #1c272b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 15px;
}

.ta-select .ta-select__options__category-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.5px;
}

.ta-select .ta-select__options__category-button {
  width: 71px;
  height: 20px;
  padding: 4px 8px;
  line-height: 0;
}

.ta-select .ta-select__values__category {
  margin-top: 8px;
}

.ta-select .ta-select__values__category .ta-striped-title {
  margin-bottom: 0;
}

.ta-select .ta-select__values__category .ta-striped-title__label {
  font-weight: bold !important;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ta-select .ta-select__options__category-extraText {
  margin-left: 4px;
  font-weight: normal;
  color: #1c272b;
}

.ta-select .ta-multi-select__values-item {
  display: inline-block;
  opacity: 1;
  border-radius: 4px;
  background-color: #A4A8AA;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  padding-left: 8px;
  padding-right: 8px;
  margin: 4px 4px 0 0;
  cursor: default;
  height: 24px;
  transition: background-color 0.5s, opacity 0.5s;
}

.ta-select .ta-multi-select__values-item.hasPattern {
  background-image: url('../../Assets/images/patterns/pattern-dots-light.png');
  background-repeat: repeat;
  background-size: 10px;
  opacity: 0.8;
}

.ta-select .ta-multi-select__values-item:hover {
  background-color: #8C9092;
  opacity: 1;
}

.ta-select .ta-multi-select__values-item-delete {
  float: right;
  margin: 4px 0 4px 8px;
  cursor: pointer;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  transition: background-color 0.5s;
}

.ta-select .ta-multi-select__values-item-delete:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.ta-select .ta-multi-select__values-item-label {
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 205px;
  white-space: nowrap;
  height: 24px;
  line-height: 24px;
}

.ta-select .ta-multi-select__values-item-avatar {
  float: left;
}

.ta-select .ta-multi-select__values-item-avatar .ta-avatar {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 6px;
  margin: 0;
  padding: 0;
  margin-right: 4px;
  margin-top: 4px;
  vertical-align: middle;
}

.ta-select .ta-avatar__image {
  height: 16px;
  width: 16px;
}

.ta-select .ta-avatar__image img {
  height: 16px;
  width: 16px;
}

.ta-select .ta-select-multi__values {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
}

.ta-select .ta-multi-select__values.inline {
  display: flex;
  width: calc(100% - 20px);
  flex-wrap: wrap;
  max-width: 302px;
}

.ta-select .ta-multi-select__values.inline .ta-multi-select__values-item-delete {
  height: 12px;
  width: 12px;
}

.ta-select .ta-multi-select__values.inline .ta-multi-select__values-item-label {
  height: 20px;
  line-height: 20px;
}

.ta-select .ta-multi-select__values.inline .ta-multi-select__values-item-avatar {
  margin-top: 2px;
}

.ta-select .ta-multi-select__values.inline .ta-avatar {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.ta-select .ta-multi-select__values.inline .ta-multi-select__values-item {
  height: 20px;
  display: flex;
  margin: 5px 0 0 5px;
}

.ta-select .ta-multi-select__values.inline .ta-select-input {
  width: 100%;
  flex: 1;
}

.ta-select .ta-multi-select__values.inline.hasValue .ta-select-input {
  margin-left: 5px;
}

.ta-select .ta-select__options-header-wrapper:not(:last-child) {
  border-bottom: 1px dotted #505a5e80;
  padding-bottom: 2px;
}

.ta-select .ta-select__options-spinner-wrapper {
  position: relative;
  height: 60px;
}
