.ta-custom-fields__form__times .ta-simple-tabs__body {
  margin-bottom: 0;
}

.ta-custom-fields__form__times .ta-message {
  margin-bottom: 0;
}

.ta-custom-fields__form__avatar {
  float: left;
  width: 134px;
  text-align: center;
  font-size: 11px;
  background: #505a5e;
  color: #fff;
  border-radius: 2px;
  margin: 0 3px;
  box-shadow: rgba(29, 50, 71, 0.27) 0 0 0 3px;
}

.ta-custom-fields__form__details {
  min-height: 134px;
}

.ta-custom-fields__form__details-fields {
  padding-left: 150px;
}

.ta-customer-fields__form__is-active-checkbox {
  min-width: 200px;
}