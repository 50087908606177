.ta-inline-fields > * {
  display: inline-block;
  vertical-align: middle;
}

.ta-inline-fields .ta-form-group {
  margin: 0 8px;
  padding: 0;
}

.ta-inline-fields__text {
  color: #505a5e;
  font-size: 13px;
  line-height: 32px;
}
