.ta-single-slider__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  vertical-align: bottom;
}

.ta-single-slider__content .ta-single-slider__label-container {
  display: flex;
  justify-content: space-between;
}

.ta-single-slider__content .ta-single-slider__label-container > * {
  width: 22px;
  height: 16px;
  margin: 10px 5px 0 5px;
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: rgba(80, 90, 94, 0.8);
  width: fit-content;
}

.ta-single-slider__body {
  position: relative;
  display: table-cell;
  width: calc(100% - 20px);
  position: relative;
  margin: 0 10px;
}

/* restriction point base style */
.ta-single-slider__body:before,
.ta-single-slider__body:after  {
  content: '';
  width: 8px;
  height: 12px;
  border-radius: 4px;
  background: #cbced0;
  position: absolute;
  top: 3px;
  z-index: -1;
}
/* restriction point left style */
.ta-single-slider__body:after {
  right: -4px;
}
/* restriction point right style */
.ta-single-slider__body:before{
  left: -4px;
}
/* handler base styles */
.ta-single-slider .rc-slider-handle {
  background: #fff;
  width: 20px;
  height: 20px;
  margin-top: -6px;
  border: 4px solid #b6babd;
  opacity: 1;
}
/* handler hover styles */
.ta-single-slider .rc-slider-handle:hover,
.ta-single-slider .rc-slider-handle.rc-slider-handle-dragging {
  background: #505a5e;
  border: 4px solid #fff;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* handler hover styles */
.ta-single-slider .rc-slider-handle:hover:after,
.ta-single-slider .rc-slider-handle.rc-slider-handle-dragging:after {
  content: '';
  width: 20px;
  height: 20px;
  border: 1px solid #505a5e;
  border-radius: 50%;
  position: absolute;
}
.ta-single-slider .rc-slider {
  height: 12px;
}

.ta-single-slider .rc-slider-step {
  height: 1px;
}

.ta-single-slider .rc-slider-rail {
  height: 8px;
  background: #f0f1f2;
  z-index: -2;
}

.ta-single-slider .rc-slider-track {
  height: 8px;
  background:#b6babd;
  z-index: -2;
}

.ta-single-slider.ta-single-slider__non-filled .rc-slider-track {
  height: 8px;
  background:transparent;
}
