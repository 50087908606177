.ta-striped-title {
  line-height: 14px;
  position: relative;
  margin-bottom: 8px;
  opacity: 0.7;
  display: flex;
  align-items: center;
}

.ta-striped-title.top-offset {
  margin-top: 16px;
}

.ta-striped-title:after {
  content: "";
  background-color: #505A5E;
  opacity: 0.25;
  height: 1px;
  width: 100%;
}

.ta-striped-title__label {
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  color: #505A5E;
  text-transform: uppercase;
  padding: 0 4px 0 0;
  background: transparent;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  max-width: 80%;
}

.ta-striped-title__extraText {
  padding-right: 5px;
  font-style: italic;
  font-weight: 400;
  text-transform: none;
  display: inline-block;
  z-index: 1;
  position: relative;
  color: #505A5E;
  background: #fff;
  font-size: 11px;
  max-width: 150px;
}

.ta-striped-title__extraText span {
  display: block;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
