.ta-branches-preview .ta-simple-tabs-details .ta-section-title__label {
  font-weight: 500;
}

.ta-branches-preview .ta-form {
  padding-bottom: 15px;
}

.ta-branches-preview .ta-simple-list-item__content a {
  text-decoration: none;
}

.ta-branches-preview .ta-icon-list__body {
  font-weight: normal;
  line-height: 1.33;
}

.ta-branches-preview .ta-branches-preview__tabs-wrapper {
  margin-top: 16px;
}

.ta-branches-preview .ta-simple-tabs-resources,
.ta-branches-preview .ta-simple-tabs-statistics {
  padding-bottom: 8px;
}

.ta-branches-preview .ta-simple-box-big {
  border-radius: 3px;
  border: solid 1px #e5e6e7;
  background-color: #ffffff;
  padding: 0;
}

.ta-branches-preview .ta-simple-box-big .ta-simple-box__header {
  height: 40px;
  position: relative;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #505a5e;
  margin: 3px 3px 0 3px;
  text-transform: uppercase;
  background: rgba(80, 90, 94, 0.1);
}

.ta-branches-preview .ta-simple-box__content {
  padding: 16px 18px 8px 18px;
  min-height: 78px;
  margin-top: 5px;
}

.ta-branches-preview .ta-simple-box-resources .ta-avatar {
  width: 36px;
  height: 36px;
  line-height: 30px;
  font-size: 11px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.ta-branches-preview .ta-branches-preview-statistics {
  height: 120px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
}

.ta-branches-preview .ta-branches-preview-statistics .ta-simple-box__content {
  padding-top: 8px;
  opacity: 0.7;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: 0.4px;
  text-align: center;
}

.ta-branches-preview .ta-branches-preview-statistics .ta-simple-box__header {
  margin: -13px -13px 13px -13px;
  text-align: center;
}

/* resources */

.ta-branches-preview .ta-row .simple-list-item-resource {
  height: 64px;
  border: none;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-col {
  padding: 0;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-simple-list-item__content {
  margin-left: 55px;
  opacity: 1;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-list-inline {
  margin-right: 8px;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-list-inline li {
  padding-right: 4px;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-simple-list-item__title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.07;
  color: #505a5e;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-list-inline i {
  font-size: 12px;
}

.ta-branches-preview .simple-list-item-resource .ta-avatar {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

/* statistic boxes */

.ta-branches-preview .ta-row .simple-list-item-resource .ta-statistic-box-total,
.ta-branches-preview .ta-row .simple-list-item-resource .ta-statistic-box-total {
  width: 64px;
  height: 100%;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-statistic-box-total {
  border: 1px solid rgba(182, 186, 189, 0.5);
}

.ta-branches-preview .ta-simple-list-item__buttons {
  display: flex;
  align-items: center;
  padding: 4px 0 4px 4px;
  height: 100%;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-statistic-box {
  border-radius: 3px;
  margin-left: 4px;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-statistic-box__info {
  font-size: 9px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: #ffffff;
  opacity: 1;
  padding: 0 10px;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-statistic-box .ta-statistic-box__title__icon {
  margin-left: 0;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-row .ta-simple-list-item {
  border: 1px solid #e5e6e7;
}

/* List items */

.ta-branches-preview .ta-simple-list-item__title-text {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #505a5e;
  text-align: center;
  margin-top: 5px;
  max-width: calc(100% - 30px);
}

.ta-branches-preview .ta-row .ta-simple-list-item {
  width: 100%;
  text-align: left;
  border-radius: 3px;
  background-color: #ffffff;
  margin: 0;
  height: 52px;
}

.ta-branches-preview .big-box-three .ta-simple-list-item__title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-branches-preview .big-box-three .ta-simple-list-item__title-center {
  transform: translateY(40%);
}

.ta-branches-preview .ta-simple-box-resources .ta-simple-list-item__title-text {
  margin-left: 42px;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-simple-list-item__content i {
  margin-right: 0;
}

/* Resources preview */

.ta-branches-preview .ta-simple-tabs-resources .ta-row .ta-simple-list-item {
  display: flex;
  align-items: center;
  min-height: 78px;
}

.ta-branches-preview .ta-branches-preview-tag {
  display: inline-block;
  height: 16px;
  border-radius: 3.3px;
  font-size: 9px;
  font-weight: 500;
  line-height: 1.09;
  color: #ffffff;
  padding: 4px;
  background: #cc9e52;
  margin-right: 4px;
}

.ta-branches-preview .ta-simple-tabs-resources .ta-simple-list-item {
  padding: 0 0 0 11px;
}

/* label color*/

.ta-branches-preview .ta-custom-label {
  background-color: #385f87;
}

.ta-branches-preview .ta-row .simple-list-item-resource .ta-plan {
  max-width: 150px;
  margin: 0;
  border: none;
  cursor: pointer;
}

/* Statistics */

.ta-branches-preview .ta-simple-tabs-statistics .ta-dashboard__section {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  color: #505a5e;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-dashboard-export-btn {
  width: 124px;
  height: 32px;
  line-height: 30px;
  border-radius: 3px;
  background-color: #b6babd;
  color: #505a5e;
  font-weight: 900;
  margin-left: 8px;
  /* Remove when functionality is ready */
  opacity: 0.5;
  cursor: not-allowed;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-dashboard-export-btn i {
  margin-right: 5px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-form-group {
  padding-top: 0;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-form {
  white-space: nowrap;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-statistics__filters {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 8px;
  margin-bottom: 8px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-statistics__filters--inputs {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-message.with-border {
  border: 1px solid #f5daae;
  border-radius: 6px;
  top: 80;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-message.no-margin .ta-message__content {
  margin: 0;  
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-message.with-border .ta-message__title {
  font-size: 18px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-message.with-border .ta-message__content {
  font-size: 14px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-chart .ta-statistic-box__title__no-results {
  text-align: center;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-statistic-box__title__no-results {
  font-size: 12px;
  font-weight: normal;
  color: rgba(80, 90, 94, 0.6);
  text-align: left;
  margin-top: -7px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-statistic-box__title__no-results i  {
  font-size: 14px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-statistic-box__title__no-results > p {
  color: #505a5e;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-statistic-box__title__no-results--text {
  font-weight: 900;
  color: #505a5e;
}

.ta-branches-preview .ta-simple-tabs-statistics .small-statistic-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 44px;
  border-radius: 3px;
  background-color: #385f87;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  align-self: center;
}

.ta-branches-preview .ta-simple-tabs-statistics .small-statistic-box.small-statistic-box--red {
  background-color: #cb3b43
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-simple-box-content-center .ta-simple-box__content .ta-statistic-box__title {
  padding: 26px 0;
}

/* Simple Box */

.ta-branches-preview .ta-simple-tabs-statistics .ta-simple-box {
  border-radius: 6px;
  border: solid 1px #cbced0;
  background-color: #ffffff;
  padding: 4px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-simple-box__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  min-height: 48px;
  background: #dcdedf;
  color: #505a5e;
  line-height: 48px;
  border-radius: 3px 3px 0 0;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  margin: 0;
  height: max-content;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-statistic-box .ta-statistic-box__info {
  padding-bottom: 4px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-simple-box__content {
  padding: 12px;
  min-height: 78px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-simple-box-fixed-dimensions {
  height: 128px;
  min-width: 100%;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-simple-box-fixed-dimensions.large {
  height: 170px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-simple-box-fixed-dimensions .ta-simple-box__content {
  padding-top: 0;
}

/* List items */

.ta-branches-preview .ta-simple-tabs-statistics .ta-row .ta-simple-list-item {
  width: 100%;
  text-align: left;
  border-radius: 3px;
  border: solid 1px #e5e6e7;
  background-color: #ffffff;
  height: 52px;
}

/* .ta-branches-preview .ta-simple-tabs-statistics .ta-simple-list-item__title {
  margin-bottom: 0;
} */

.ta-branches-preview .ta-simple-tabs-statistics .ta-simple-list-item__title-text {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #505a5e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-simple-list-item__title-center {
  transform: translateY(40%);
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-simple-list-item__content {
  line-height: 0 !important;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-simple-list-item__content i {
  line-height: 0 !important;
  font-size: 12px;
  margin-top: 8px;
  font-weight: 600;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-list-inline li:first-child {
  padding-right: 4px;
}

/* Avatar */

.ta-branches-preview .ta-simple-tabs-statistics .ta-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  float: left;
  margin-right: 8px;
  transform: translateY(-9%); 
  margin-bottom: 0;
}

/* big boxes */

.ta-branches-preview .ta-simple-tabs-statistics .big-box-header .big-title {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #505a5e;
}

.ta-branches-preview .ta-simple-tabs-statistics .big-box-header .small-title {
  opacity: 0.7;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: normal;
  text-transform: uppercase;
  color: #505a5e;
  margin-top: 4px;
  margin-bottom: 12px;
  font-weight: 400;
}

.ta-branches-preview .ta-chart {
  height: 288px;
}

.ta-branches-preview .ta-chart .recharts-layer.recharts-pie {
  transform: translateY(31px);
}

.ta-branches-preview .ta-charts-row>.ta-chart:first-child {
  margin-right: 24px;
}

.ta-branches-preview .recharts-wrapper {
  width: 100% !important;
}

.ta-branches-preview .recharts-wrapper .recharts-surface {
  width: 100% !important;
}

.ta-branches-preview .ta-statistic-box .ta-statistic-box__info {
  padding-bottom: 4px;
}

.ta-branches-preview .ta-simple-list-item__content .ta-simple-list-item__title {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
}

.ta-simple-tabs-resources .ta-accordion .ta-accordion__title:after {
  right: 16px;
}

.ta-simple-tabs-resources .ta-icon-list .ta-popup__trigger a,
.ta-simple-tabs-customers .ta-icon-list .ta-popup__trigger a {
  color: #FFF;
}

.ta-simple-tabs-resources .ta-icon-list .ta-popup__trigger .ta-btn-success {
  color: #505A5E;
}

.ta-simple-tabs-resources .ta-btn-success {
  background: #769851;
  color: #fff;
  border: 1px solid #769851;
  cursor: default;
}

/* tags */

.ta-branches-preview .online.ta-simple-list-item:after {
  background: #769851
}

.ta-branches-preview .grey.ta-simple-list-item:after {
  background: rgba(182, 186, 189, 0.5)
}

.ta-branch__preview__resources,
.ta-branch__preview__services,
.ta-branch__preview__groups,
.ta-branch__preview__customers,
.ta-branch__preview__statistics {
  position: relative;
  min-height: 100px;
}

.ta-branch__preview__resources .ta-accordion .ta-accordion__title:after,
.ta-branch__preview__services .ta-accordion .ta-accordion__title:after,
.ta-branch__preview__groups .ta-accordion .ta-accordion__title:after,
.ta-branch__preview__customers .ta-accordion .ta-accordion__title:after {
  right: 16px;
}

.ta-branch__preview__statistics .ta-select .ta-select__arrow {
  top: 12px;
}

.ta-branch__preview__statistics .ta-statistics__filter-submit-btn {
  top: 0px;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-row .ta-col.ta-branch__preview__statistics--col {
  padding-left: 0;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-branch-statistics__form {
  padding-bottom: 0;
}

.ta-branches-preview .ta-simple-tabs-statistics .ta-branch-statistics__form .ta-select .ta-select-input {
  background: #fff;
}

.ta-branches-preview .ta-branch-preview__statistics--section {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  color: #505a5e;
}

.ta-branches-preview .ta-branch-preview__statistics--section:not(.first) {
  margin-top: 12px;
}

.ta-branch-preview__statistics--subsection {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: rgba(80, 90, 94, 0.8);
  margin-top: 5px;
  margin-bottom: 10px;
}

.ta-branch__preview__statistics .ta-simple-box .ta-statistics-box__label:not(:last-of-type) {
  margin-right: 4px;
}

.ta-branch__preview__statistics .ta-simple-box .ta-statistics-box__label {
  width: 20px; 
  height: 14px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-branch__preview__statistics .ta-simple-box .ta-statistics-box__label i {
  position: relative;
  left: 0.5px;
  color: #fff;
  font-size: 8px;
}

.ta-dashboard-export-btn--container {
  text-align: right;
}

.ta-dashboard-export-btn--container .ta-hover-popup {
  top: 5px;
}

.ta-branches-preview span.underline {
  text-decoration: underline;
  color: #cb3b43;
  font-weight: 900;
}