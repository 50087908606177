.ta-user-permission-types__form .ta-color-icon-select input.ta-form-control {
  padding-left: 70px;
}

.ta-user-permission-types__form .ta-color-icon-select__selected {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 500;
  background: #fff;
  cursor: pointer;
}

.ta-user-permission-types__form .ta-icon-select {
  position: absolute;
  left: 0;
  z-index: 500;
  background: #fff;
  cursor: pointer;
}

.ta-user-permission-types__form .ta-icon-select .ta-icon-select__selected {
  top: 0px;
}

.ta-user-permission-types__form .ta-color-icon-select .ta-form-group label {
  left: 70px;
}
