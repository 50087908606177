.ta-number-box {
  position: relative;
  text-align: center;
  border: 1px solid rgba(182, 186, 189, 0.5);
  color: #505a5e;
  border-radius: 3px;
}

.ta-number-box__content {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  transform: translate(-50%, -50%);
}

.ta-number-box__content__number {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 4px;
}

.ta-number-box__content__text {
  font-weight: 500;
  font-size: 9px;
}