.ta-carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.ta-carousel__container {
  width: 100%;
  display: flex;
  height: 100%;
}

.ta-carousel__slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.ta-carousel__slide img {
  width: 100%;
}

.ta-carousel__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  width: 32px;
  height: 40px;
  padding: 10px 6px;
  opacity: 0.8;
  border-radius: 3px;
  border:0;
  color: #fff;
  background-color: #1d3247;
}

.ta-carousel__button.left {
  left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ta-carousel__button.right {
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ta-carousel__control-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 8px;
  display: flex;
  gap: 4px;
  z-index: 1;
}

.ta-carousel__dot {
  width: 8px;
  height: 8px;
  border: solid 1px #fff;
  border-radius: 50%;
  cursor: pointer;
}

.ta-carousel__dot.active{
  background-color: #fff;
}

.ta-carousel:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: -moz-linear-gradient(top, rgba(29, 50, 71, 0) 0%, rgba(29, 50, 71, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(29, 50, 71, 0) 0%, rgba(29, 50, 71, 1) 100%);
  background: linear-gradient(to bottom, rgba(29, 50, 71, 0) 0%, rgba(29, 50, 71, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f4f3f3', GradientType=0);
}
