.ta-apps__preview__iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    border-radius: 0;
}

.ta-apps__preview__no__plan-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
  
.ta-apps__preview__no__plan-container .ta-blur {
    width: 100%;
}
