.ta-company-tags__tag .ta-simple-list-item:after {
  background-image: -webkit-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
  background-image: -moz-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
  background-image: -ms-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
  background-image: repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
  -webkit-background-size: 3px 3px;
  -moz-background-size: 3px 3px;
  background-size: 3.5px 3.5px;
}

.ta-company-tags__tag .violet.ta-simple-list-item:after {
   background-color: #A476CA
}

.ta-company-tags__tag .dusk.ta-simple-list-item:after {
  background-color: #5D5A79
}

.ta-company-tags__tag .grey.ta-simple-list-item:after {
  background-color: #8B8B8B
}

.ta-company-tags__tag .breaks.ta-simple-list-item:after {
  background-color: #EBB65D
}

.ta-company-tags__tag .ocher.ta-simple-list-item:after {
  background-color: #CC9E52
}

.ta-company-tags__tag .orange.ta-simple-list-item:after {
  background-color: #E5774C
}

.ta-company-tags__tag .brown.ta-simple-list-item:after {
  background-color: #C46F4E
}

.ta-company-tags__tag .wood.ta-simple-list-item:after {
  background-color: #7B6161
}

.ta-company-tags__tag .green.ta-simple-list-item:after {
   background: #82A774
}

.ta-company-tags__tag .light-green.ta-simple-list-item:after {
  background-color: #95C5A6
}

.ta-company-tags__tag .aqua.ta-simple-list-item:after {
  background-color: #8EBEC3
}

.ta-company-tags__tag .light-blue.ta-simple-list-item:after {
  background-color: #6882B0
}

.ta-company-tags__tag .purple.ta-simple-list-item:after {
  background-color: #7684CA
}

.ta-company-tags__tag .red.ta-simple-list-item:after {
  background-color: #B22E53
}

.ta-company-tags__tag .course.ta-simple-list-item:after {
  background-color: #B26492
}

.ta-company-tags__tag .default.ta-simple-list-item:after {
  background-color: #385f87
}
