.ta-billing__form .ta-form-group.hasPrefix label,
.ta-apps__subscription-form .ta-form-group.hasPrefix label {
  left: 31px;
}

.ta-billing__form .ta-form-group.filled.hasPrefix label,
.ta-billing__form .ta-form-group.focused.hasPrefix label,
.ta-apps__subscription-form .ta-form-group.filled.hasPrefix label,
.ta-apps__subscription-form .ta-form-group.focused.hasPrefix label {
  left: 28px;
}
