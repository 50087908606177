.ta-permission-types .ta-permission-types__resource-item-wrap {
  padding: 4px 4px 4px 8px;
  border-radius: 3px;
  border: solid 1px #cbced0;
  margin-bottom: 4px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.ta-services__preview--changed-locally--branch__buttons{
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ta-services__preview--changed-locally--branch__buttons{
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.ta-permission-types .ta-permission-types__resource-item-wrap .ta-permission-types__resource-item__body {
  width: 100%;
  display: flex;
  align-items: center;
}

.ta-permission-types .ta-permission-types__resource-item-wrap .ta-permission-types__resource-item__avatar {
  height: 32px;
  width: 32px;
  align-self: center;
  margin: 0;
}

.ta-permission-types .ta-permission-types__resource-item-wrap .ta-permission-types__resource-item__avatar .ta-avatar__image {
  height: 32px;
  width: 32px;
  display: flex;
}

.ta-permission-types .ta-title__content :not(:last-child) {
  margin-right: 4px;
}

.ta-permission-types .ta-permission-types__resource-item-wrap .ta-permission-types__resource-item__avatar .ta-avatar__image img {
  height: 32px;
  width: 32px;
  align-self: center;
  justify-content: center;
}

.ta-permission-types__resource-item-wrap .ta-permission-types__resource-item__avatar .ta-avatar__initials {
  line-height: 32px;
  font-size: 12px;
}

.ta-permission-types__resource-item-wrap .ta-permission-types__resource-item__content {
  margin-left: 12px;
}

.ta-permission-types__resource-item-wrap .ta-permission-types__resource-item__content .ta-permission-types__resource-item__content__resource-name {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #505a5e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 345px;
}

.ta-permission-types__resource-item-wrap .ta-permission-types__resource-item__buttons .ta-list-button {
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
}

.ta-permission-types__resource-item-wrap .ta-permission-types__resource-item__content .ta-permission-types__resource-item__content__resource-status {
  padding: 0 4px;
  border-radius: 6px;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  display: inline-block;
  text-transform: uppercase;
}

.ta-permission-types__resource-item-wrap .ta-permission-types__resource-item__content .ta-permission-types__resource-item__content__resource-status-online {
  background-color: #769851;
  color: #fff;
}

.ta-permission-types__resource-item-wrap .ta-permission-types__resource-item__content .ta-permission-types__resource-item__content__resource-status-offline {
  color: #505a5e;
  background-color: #a7acae;
}

.ta-permission-types__summary .ta-permission-types__summary__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.ta-permission-types__summary .ta-permission-types__summary__header .ta-permission-types__summary__header__icon {
  height: 88px;
  width: 88px;
  min-width: 88px;
  font-size: 36px;
}

.ta-permission-types__summary .ta-permission-types__summary__header .ta-permission-types__summary__header__content {
  margin-left: 16px;
}

.ta-permission-types__summary .ta-permission-types__summary__header .ta-permission-types__summary__header__content .ta-permission-types__summary__header__content__name {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #505a5e;
  margin-bottom: 4px;
  word-break: break-word;
}

.ta-permission-types__summary .ta-permission-types__summary__header .ta-permission-types__summary__header__content .ta-permission-types__summary__header__content__resources {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
}

.ta-permission-types .ta-simple-tabs__header:after {
  z-index: 1;
}

.ta-permission-types__preview__changed-locally__branch {
  min-height: 80px;
  border-radius: 3px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  background-color: #ffffff;
  color: #5b6569;
  padding: 16px;
  margin: 5px 0;
  display: flex;
}

.ta-permission-types__preview__changed-locally__branch__data {
  width: 70%;
}

.ta-permission-types__preview__changed-locally__branch__buttons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-permission-types__preview__changed-locally__branch__buttons button {
  font-size: 10px;
  font-weight: 500;
  color: #5b6569;
  background: #ecd007;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.ta-permission-types__preview__changed-locally__branch__name {
  font-size: 15px;
  font-weight: 500;
}

.ta-permission-types__preview__changed-locally__item {
  display: flex;
  margin: 5px 0;
  font-size: 12px;
}

.ta-permission-types__preview__changed-locally__item i {
  margin-right: 5px;
}
