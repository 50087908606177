.ta-services .ta-icon-list .ta-dotted-timeline__item {
  padding: 1.5px 0 1px 18px;
}

.ta-services .ta-icon-list .ta-dotted-timeline__item.small {
  padding: 0 0 0 18px;
}

.ta-simple-tabs-services .ta-icon-list .ta-popup__trigger a {
  color: #FFF;
}

.ta-services .ta-icon-list-duration-pattern {
  padding-left: 4px;
}

.ta-services .ta-accordion .ta-accordion__title:after {
  right: 16px;
}

.ta-btn.ta-btn-export {
  line-height: 1;
}