.ta-icon-select {
  position: relative
}

.ta-icon-select .ta-icon-select__selected {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  width: 60px;
  background: #fff;
  cursor: pointer;
}

.ta-icon-select__selected {
  width: 60px;
  height: 40px;
  border: 1px solid rgba(80, 90, 94, 0.7);
  border-radius: 6px 0 0 6px;
}

.ta-icon-select__selected.active {
  background-color: #f6f7f9;
  border-radius: 6px 0 0 0;
}

.ta-icon-select__selected.disabled {
  cursor: default;
  background-color: #f7f8f8;
  border-color: rgba(182, 186, 189, 0.3);
}

.ta-icon-select__selected .ta-icon-select__selected-icon {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #505a5e;
  padding-left: 10px;
}

.ta-icon-select__selected.disabled .ta-icon-select__options__icon {
  cursor: default;
}

.ta-icon-select__selected .ta-icon-select__options__icon {
  position: absolute;
  top: 36px;
  left: 0px;
  transform: translateY(-50%) translateZ(0);
}

.ta-icon-select__selected i.fa-sort {
  position: absolute;
  top: 13px;
  right: 12px;
  font-size: 14px;  
  color: rgba(80, 90, 94, 0.7);
}

.ta-icon-select__selected:not(.disabled) i.fa-sort, .ta-icon-select__selected:not(.disabled) i.fa-sort {
  color: #505a5e;
}

.ta-color-select__selected.disabled i.fa-sort {
  color: rgba(80, 90, 94, 0.3);
  cursor: default;
}

.ta-icon-select__options {
  position: absolute;
  top: 39px;
  left: 0;
  z-index: 501;
  width: 214px;
  background-color: #f6f7f9;
  padding: 10px 2px 2px 10px;
  border: 1px solid rgba(80, 90, 94, 0.7);
  border-radius: 0 6px 6px 6px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}

.ta-icon-select__options.active {
  visibility: visible;
  opacity: 1;
}

.ta-icon-select__options .ta-icon-select__options__icon {
  margin-right: 8px;
  margin-bottom: 8px;
  border: solid 1px #cbced0;
  border-radius: 50%;
  padding: 4px;
}

.ta-icon-select__options__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  position: relative;
  color: rgba(80, 90, 94, 0.7);
}

.ta-icon-select .ta-form-control {
  padding-right: 55px;
}

.ta-icon-select .ta-form-input-limit {
  margin-right: 48px;
}

.ta-icon-select .ta-form-input-with-limit {
  padding-right: 102px;
}
