.ta-service-combination-select__select {
  margin-bottom: 8px;
}

.ta-service-combination-select__selected-item__dnd-wrapper {
  margin-bottom: 4px;
}

.ta-service-combination-select__selected-item {
  background-color: #A5A8AB;
  border-radius: 4px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  user-select: none;
  transition: background-color 0.2s;
}

.ta-service-combination-select__selected-item:hover {
  background-color: #8E9193;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.ta-service-combination-select__selected-item.is-deleted {
  background: rgba(80, 90, 94, 0.3);
}

.ta-service-combination-select__selected-item__index {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 32px;
  text-align: center;
  background-color: #CBCBCD;
  font-weight: 500;
  color: rgba(28, 39, 43, 0.6);
  border-radius: 4px 0 0 4px;
  transition: background-color 0.2s;
}

.ta-service-combination-select__selected-item:hover .ta-service-combination-select__selected-item__index {
  background-color: #C5C7C8;
}

.ta-service-combination-select__selected-item__body {
  position: relative;
  font-weight: 500;
  margin-left: 38px;
  margin-right: 24px;
  padding-left: 22px;
}

.ta-service-combination-select__selected-item__body:after {
  content: "\f07d";
  font-family: "Font Awesome 5 Pro";
  font-size: 10px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
}

.ta-service-combination-select__selected-item__body span {
  font-size: 8px;
  color: #fff;
  text-transform: uppercase;
  background: #c2c6c7;
  border-radius: 10px;
  height: 12px;
  line-height: 13px;
  display: inline-block;
  padding: 0 4px;
  vertical-align: middle;
  transform: translateY(-1px);
  margin-left: 5px;
}

.ta-service-combination-select__selected-item__delete-btn {
  width: 16px;
  height: 16px;
  line-height: 1.6;
  position: absolute;
  top: 4px;
  right: 8px;
  text-align: center;
  cursor: pointer;
  font-size: 10px;
  font-weight: normal;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.ta-form-group.disabled .ta-service-combination-select__selected-item__delete-btn {
  cursor: not-allowed;
}

.ta-service-combination-select__selected-item__dnd-wrapper {
  left: 15px!important;
}

.ta-service-combination-select__selected-item__dnd-wrapper.is-dragging {
  opacity: 0.8;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
}
