.ta-user-permission-types .ta-permission-types__user-item-wrap {
  padding: 4px 4px 4px 8px;
  border-radius: 3px;
  border: solid 1px #cbced0;
  margin-bottom: 4px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.ta-user-permission-types .ta-permission-types__user-item-wrap .ta-permission-types__user-item__body {
  width: 100%;
  display: flex;
  align-items: center;
}

.ta-user-permission-types .ta-permission-types__user-item-wrap .ta-permission-types__user-item__avatar {
  height: 32px;
  width: 32px;
  align-self: center;
  margin: 0;
}

.ta-user-permission-types .ta-permission-types__user-item-wrap .ta-permission-types__user-item__avatar .ta-avatar__image {
  height: 32px;
  width: 32px;
  display: flex;
}

.ta-user-permission-types .ta-permission-types__user-item-wrap .ta-permission-types__user-item__avatar .ta-avatar__image img {
  height: 32px;
  width: 32px;
  align-self: center;
  justify-content: center;
}

.ta-permission-types__user-item-wrap .ta-permission-types__user-item__avatar .ta-avatar__initials {
  line-height: 32px;
  font-size: 12px;
}

.ta-permission-types__user-item-wrap .ta-permission-types__user-item__content {
  margin-left: 12px;
}

.ta-permission-types__user-item-wrap .ta-permission-types__user-item__content .ta-permission-types__user-item__content__user-name {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #505a5e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 345px;
}

.ta-permission-types__user-item-wrap .ta-permission-types__user-item__buttons .ta-list-button.ta-btn {
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  padding: 0;
}

.ta-permission-types__user-item-wrap .ta-permission-types__user-item__content .ta-permission-types__user-item__content__user-status {
  padding: 0 4px;
  border-radius: 6px;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  display: inline-block;
  text-transform: uppercase;
}

.ta-permission-types__summary .ta-permission-types__summary__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.ta-permission-types__summary .ta-permission-types__summary__header .ta-permission-types__summary__header__icon {
  height: 88px;
  width: 88px;
  min-width: 88px;
  font-size: 36px;
}

.ta-permission-types__summary .ta-permission-types__summary__header .ta-permission-types__summary__header__content {
  margin-left: 16px;
}

.ta-permission-types__summary .ta-permission-types__summary__header .ta-permission-types__summary__header__content .ta-permission-types__summary__header__content__name {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #505a5e;
  margin-bottom: 4px;
  word-break: break-word;
}

.ta-permission-types__summary .ta-permission-types__summary__header .ta-permission-types__summary__header__content .ta-permission-types__summary__header__content__users {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
}

.ta-permission-types .ta-simple-tabs__header:after {
  z-index: 1;
}

.ta-user-permission-types .ta-user-permission-types__users-spinner {
  position: relative;
  height: 50px;
}
