.ta-users-form .ta-fixed-content__body {
  top: 52px;
}

.ta-users__form .ta-buffer-times__box {
  bottom: auto;
  top: 27px;
}

.ta-users__form .ta-message.active {
  margin: 8px 0 0 0;
}

.ta-hover-popup.ta-users__form__permissions__select {
  width: 100%;
}
