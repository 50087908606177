.ta-booking-reminders-example {
  margin-top: 5px;
  position: relative;
}

.ta-booking-reminders-example__label {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.5;
  height: 20px;
  line-height: 20px;
}

.ta-booking-reminders-example__hours {
  font-size: 8px;
  font-weight: 500;
  color: #505a5e;
  position: relative;
  top: 4px;
  padding-left: 12px;
}

.ta-booking-reminders-example__hours:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background: #fff;
  border: 2px solid #505a5e;
  position: absolute;
  top: 10px;
  left: 0;
}

.ta-booking-reminders-example__timeline {
  height: 60px;
  position: relative;
}

.ta-booking-reminders-example__timeline.default {
  min-width: 430px;
}

.ta-booking-reminders-example__timeline.small {
  min-width: 412px;
}

.ta-booking-reminders-example__timeline-active {
  position: relative;
  border-bottom: 2px solid #505a5e;
  height: 20px;
  width: 55px;
  display: inline-block;
  transition: all .5s;
}

.ta-booking-reminders-example__timeline-active:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background: #505a5e;
  position: absolute;
  top: 15px;
  left: 0;
}

.ta-booking-reminders-example__timeline-active:after {
  content: "";
  width: 2px;
  height: 8px;
  background: #505a5e;
  position: absolute;
  top: 15px;
  right: 0;
}

.ta-booking-reminders-example__timeline-inactive {
  position: relative;
  border-bottom: 2px dashed #c9c8c6;
  height: 20px;
  transition: all .5s;
  display: inline-flex;
  top: -7px;
}

.ta-booking-reminders-example__timeline-inactive.default {
  width: 470px;
}

.ta-booking-reminders-example__timeline-inactive.small {
  width: 433px;
}

.ta-booking-reminders-example__slot {
  height: 20px;
  line-height: 20px;
  width: 40px;
  border-radius: 3px;
  font-size: 10px;
  transition: all .5s;
  position: absolute;
}

.ta-booking-reminders-example__slot:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  color: #fff;
  transform: translate(-50%, -50%);
}

.ta-booking-reminders-example__slot.active {
  top: 25px;
  background: #505a5e;
}

.ta-booking-reminders-example__slot.active.default {
  right: 13px;
}

.ta-booking-reminders-example__slot.active.small {
  right: 12px;
}

.ta-booking-reminders-example__slot.active:after {
  content: "\f274";
}

.ta-booking-reminders-example__slot.inactive {
  left: 11px;
  background: #b6babd;
}

.ta-booking-reminders-example__slot.inactive:after {
  content: "\f0e0";
}

/* 1 segment */

.ta-booking-reminders-example__timeline-inactive.segments-1 .ta-booking-reminders-example__hours {
  width: 456px;
}

/* 2 segments */

.ta-booking-reminders-example__timeline-inactive.segments-2 .ta-booking-reminders-example__hours {
  width: 184px;
}

/* 3 segments */

.ta-booking-reminders-example__timeline-inactive.segments-3 .ta-booking-reminders-example__hours {
  width: 123px;
}

/* 4 segments */

.ta-booking-reminders-example__timeline-inactive.segments-4 .ta-booking-reminders-example__hours {
  width: 92px;
}

/* 5 segments */

.ta-booking-reminders-example__timeline-inactive.segments-5 .ta-booking-reminders-example__hours {
  width: 74px;
}

/* 6 segments */

.ta-booking-reminders-example__timeline-inactive.segments-6 .ta-booking-reminders-example__hours {
  width: 61px;
}

/* 7 segments */

.ta-booking-reminders-example__timeline-inactive.segments-7 .ta-booking-reminders-example__hours {
  width: 53px;
}

/* 8 segments */

.ta-booking-reminders-example__timeline-inactive.segments-8 .ta-booking-reminders-example__hours {
  width: 46px;
  padding-left: 7px;
}
