.ta-toastr {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: -6000;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 440px;
  min-height: 92px;
  padding: 8px;
  border-radius: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4), 0 0 3px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  transition: opacity 600ms, bottom 600ms, z-index 600ms;
}

.ta-toastr .ta-message.active {
  margin-top: 4px;
  margin-bottom: 0;
}

.ta-toastr.visible {
  bottom: 80px;
  opacity: 1;
  z-index: 6000;
  pointer-events: initial;
  transition: opacity 600ms, bottom 600ms, z-index 600ms;
}
