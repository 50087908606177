.ta-status-box {
  display: inline-block;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 11px;
  font-weight: 500;
}

.ta-status-box i {
  margin-right: 4px;
}

.ta-status-box.green {
  background: rgba(118, 152, 81, 0.15);
  color: #769851;
}

.ta-status-box.grey {
  background: rgba(80, 90, 94, 0.15);
}

.ta-status-box.red {
  background: rgba(203, 59, 67, 0.15);
  color: #cb3b43;
}

.ta-status-box.yellow {
  background: rgba(236, 208, 7, 0.4);
  color: #505a5e;
}
