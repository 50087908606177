.ta-dashboard>.ta-row {
  padding-bottom: 0;
  border: solid 1px #e5e6e7;
  border: none;
  margin: 0 0 16px 0;
}

.ta-dashboard:first-child {
  width: 100%;
}

.ta-dashboard {
  margin-left: -13px;
}

.ta-dashboard .ta-row.buffer {
  padding: 20px;
}

.ta-dashboard .ta-row .ta-col.ta-dashboard__filters-container {
  padding-left: 0;
}

.ta-dashboard .ta-form.ta-statistics-filters {
  padding-bottom: 0;
  padding-left: 4px;
}

.ta-dashboard .ta-dashboard__section {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  color: #505a5e;
  margin-top: 8px;
}

.ta-blur.active.ta-dashboard {
  padding-left: 24px;
}

.ta-dashboard__section--subheading {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: rgba(80, 90, 94, 0.8);
  margin-top: 6px;
}

.ta-dashboard-export-btn-container {
  text-align: right;
}

.ta-dashboard .ta-dashboard__title {
  position: absolute;
  top: 29px;
  left: 24px;
  opacity: 0.8;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #505a5e;
}

.ta-dashboard .ta-row.no-margin {
  margin: 0;
}

.ta-dashboard .ta-dashboard__filter-form .ta-form-group {
  padding-top: 0;
}


.ta-dashboard .ta-dashboard__filter-form .ta-form-group.filled label {
  top: -5px;
}

.ta-statistics__filter-submit-btn {
  background: #525a5c;
  border: 1px solid #525a5c;
  color: #fff;
  border-radius: 3px;
  height: 40px;
  width: 40px;
  line-height: 38px;
  position: relative;
  vertical-align: bottom;
  padding: 0 16px;
}

.ta-statistics__filter-submit-btn.disabled {
  opacity: 0.5;
  cursor: default;
}

.ta-statistics__filter-submit-btn.ta-info {
  color: #505a5e;
  background: #b6babd;
  border: 1px solid #b6babd;
  font-size: 14px;
}

.ta-dashboard .ta-dashboard-export-btn {
  height: 32px;
  line-height: 30px;
  font-weight: bold;
  color: #505a5e;
  position: relative;
  top: 5px;
  border: solid 1px #505a5e;
}

.ta-dashboard .ta-dashboard-export-btn i {
  margin-right: 8px;
}

.ta-dashboard .ta-single-select-wrapper {
  width: 40%;
  float: right;
}

.ta-dashboard .ta-single-select-wrapper:last-child {
  margin-right: 8px;
}

/* Statistic box */

.ta-dashboard .small-statistic-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 44px;
  border-radius: 3px;
  background-color: #385f87;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  align-self: center;
}

.ta-dashboard .small-statistic-box.small-statistic-box--red {
  background-color: #cb3b43
}

.ta-dashboard .ta-simple-box-content-center .ta-simple-box__content .ta-statistic-box__title {
  padding: 26px 0;
}

.ta-statistic-box .ta-statistic-box__title.no-additional-data {
  padding-top: 25px;
}
/* Simple Box */

.ta-dashboard .ta-simple-box {
  border-radius: 6px;
  border: solid 1px #cbced0;
  background-color: #ffffff;
  padding: 4px;
}

.ta-dashboard .ta-simple-box .ta-statistics-box__label:not(:last-of-type) {
  margin-right: 4px;
}

.ta-dashboard .ta-simple-box .ta-statistics-box__label {
  width: 20px; 
  height: 14px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-dashboard .ta-simple-box .ta-statistics-box__label {
  letter-spacing: none;
}

.ta-dashboard-statistics__link i.active {
  color: #505a5e;
}

.ta-dashboard .ta-simple-box .ta-statistics-box__label i {
  position: relative;
  color: #fff;
  font-size: 8px;
}

.ta-dashboard .ta-message.with-border {
  border: 1px solid #f5daae;
  border-radius: 6px;
}

.ta-dashboard .ta-message.active.with-border {
  margin-bottom: 12px;
}

.ta-dashboard .ta-message.no-margin .ta-message__content {
  margin: 0;  
}

.ta-dashboard .ta-message.with-border .ta-message__title {
  font-size: 18px;
}

.ta-dashboard .ta-message.with-border .ta-message__content {
  font-size: 14px;
}

.ta-dashboard .ta-row .ta-col {
  padding: 0 8px;
}

.ta-dashboard .ta-col.no-right-padding {
  padding-right: 0;
}

.ta-dashboard .ta-simple-box__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  height: auto;
  min-height: 48px;
  background: #dcdedf;
  color: #505a5e;
  line-height: 48px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  margin: 0;
}

.ta-dashboard .ta-simple-box__content {
  padding: 12px;
  min-height: 78px;
}

.ta-dashboard .ta-simple-box-fixed-dimensions {
  height: 128px;
  min-width: 100%;
}

.ta-dashboard .ta-simple-box-fixed-dimensions .ta-simple-box__content {
  padding-top: 0;
}

/* List items */

.ta-dashboard .ta-row .ta-simple-list-item {
  width: 100%;
  text-align: left;
  border-radius: 3px;
  border: solid 1px #cbced0;
  background-color: #ffffff;
  height: 52px;
}

.ta-dashboard .ta-simple-list-item__title-text {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #505a5e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.ta-dashboard .ta-simple-list-item__title-center {
  transform: translateY(40%);
}

.ta-dashboard .ta-simple-list-item__content {
  line-height: 0 !important;
}

.ta-dashboard .ta-simple-list-item__content i {
  line-height: 0 !important;
  font-size: 12px;
  margin-top: 8px;
  font-weight: 600;
}

.ta-dashboard .ta-list-inline li:first-child {
  padding-right: 4px;
}

/* Avatar */

.ta-dashboard .ta-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  float: left;
  margin-right: 8px;
  transform: translateY(-9%); 
  margin-bottom: 0;
}

/* big boxes */

.ta-dashboard .big-box-header .big-title {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #505a5e;
}

.ta-dashboard .big-box-header .small-title {
  opacity: 0.7;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: normal;
  text-transform: uppercase;
  color: #505a5e;
  margin-top: 4px;
  margin-bottom: 12px;
  font-weight: 400;
}

/* Charts */

.ta-dashboard .ta-chart {
  height: 288px;
}

.ta-dashboard .ta-chart .recharts-layer.recharts-pie {
  transform: translate(9%, 31px);
}

.ta-dashboard .ta-charts-row>.ta-chart:first-child {
  margin-right: 24px;
}

.ta-dashboard .recharts-wrapper {
  width: 100% !important;
}

.ta-dashboard .recharts-wrapper .recharts-surface {
  width: 100% !important;
}

.ta-dashboard .ta-statistic-box .ta-statistic-box__info {
  padding-bottom: 4px;
}

.ta-dashboard .ta-simple-list-item__content .ta-simple-list-item__title {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
}

.ta-dashboard__footer {
  padding-bottom: 24px;
}

.ta-dashboard__footer__update-message {
  width: 100%;
  text-align: center;
  padding: 24px 24px 0 24px;
  color: #505a5e;
  font-weight: bold;
  font-size: 16px;
}

.ta-popup__content {
  width: 208px;
}

.ta-dashboard .ta-statistic-box__title__no-results i {
  font-size: 16px;
  margin-bottom: 4px;
}

.ta-dashboard .ta-statistic-box__title__no-results {
  font-size: 12px;
  font-weight: normal;
  color: rgba(80, 90, 94, 0.6);
  text-align: left;
}

.ta-dashboard .ta-statistic-box .ta-statistic-box__title__no-results {
  margin-top: -7px;
}

.ta-dashboard .ta-statistic-box__title__no-results > p {
  color: #505a5e;
  line-height: 1.33;
}

.ta-dashboard .ta-statistic-box__title__no-results--text {
  font-weight: 900;
  color: #505a5e;
}

.ta-dashboard span.underline {
  text-decoration: underline;
  color: #cb3b43;
  font-weight: 900;
}