.ta-accordion-list {
  border: none;
  padding: 0;
}

.ta-accordion-list .ta-accordion .ta-accordion__title:after {
  right: 16px;
}

.ta-accordion-list__title {
  padding: 16px;
  cursor: pointer;
  color: #505a5e;
}

.ta-accordion-list__title span:nth-of-type(2) {
  font-size: 10px;
  opacity: 0.6;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

.ta-accordion-list__body {
  padding: 0 16px 16px;
  user-select: text;
}