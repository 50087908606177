.ta-activity__title {
  padding: 20px 0 15px;
}

.ta-activity-logs__list {
  width: calc(100% + 30px);
  height: 100%;
  overflow: auto;
  margin: 0 -15px;
  padding: 15px;
}

.ta-notifications__activity-logs__filter .ta-form {
  padding-bottom: 8px;
}

.ta-activity-logs__filter__emails-count {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  line-height: 20px;
}

.ta-activity-logs__filter__btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #505a5e;
  float: right;
  cursor: pointer;
  user-select: none;
}

.ta-activity-logs__filter__btn i {
  margin-left: 5px;
}

