.ta-account-form__enforce-password-change .ta-account__password-type-toggle-btn,
.ta-account-form__password-change .ta-account__password-type-toggle-btn,
.ta-account-form__email-change .ta-account__password-type-toggle-btn,
.ta-account-form .ta-account__password-type-toggle-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #505a5e;
}

.ta-account-form__enforce-password-change .ta-account-select__box__content a {
  cursor: pointer;
}

.ta-account-form__enforce-password-change .ta-account-select__box__content a:hover {
  text-decoration: none;
}
