.ta-users__preview__details .ta-simple-table {
  text-align: center;
}

.ta-users__preview__details__header {
  position: relative;
  margin-bottom: 20px;
}

.ta-users__preview__details__status {
  display: inline-block;
  padding: 0 5px;
  height: 12px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  background: #b6babd;
  font-size: 8px;
  font-weight: 500;
  color: #505a5e;
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
}

.ta-users__preview__details__status.active {
  background: #769851;
  color: #fff;
}

.ta-users__preview__details__owner {
  border: 2px solid #f4f3f3;
  background: #ecd007;
  font-size: 13px;
  color: #505a5e;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translateX(14px);
}

.ta-users__preview__details .ta-user-preview {
  display: flex;
  align-items: center;
}

.ta-users__preview__details .ta-user-preview .ta-avatar {
  margin: 0 16px 16px 0;
  min-width: 96px;
  min-height: 96px;
  line-height: 96px;
  font-size: 32px;
}

.ta-users__preview__details .ta-user-preview__name {
  margin-top: -5px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 332px;
  word-break: break-all;
}

.ta-users__preview__details .ta-user-preview__name .ta-user-roles {
  margin-top: 4px;
}

.ta-users__preview__details .ta-user-preview__name .ta-user-roles > :nth-child(2){
  margin-left: 5px;
}

.ta-users__preview__details .ta-icon-list {
  border-top: 1px solid #c9c8c6;
  border-bottom: none;
}

.ta-users__preview__details .ta-user-roles {
  display: flex;
}

.ta-users__preview__details .ta-user-preview__name .ta-badge {
  display: block;
  margin-top: 4px;
  margin-right: 0;
  width: max-content;
  max-width: 318px;
}

.ta-users__preview__details .ta-icon-list a {
  color: #385f87;
}

.ta-users__preview__details .ta-icon-list:last-child {
  border-bottom: 1px solid #c9c8c6;
}


.ta-users__preview .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  padding-bottom: 20px;
}

.ta-users__preview .ta-simple-tabs__body-item.ta-simple-tabs-courses, .ta-users__preview .ta-simple-tabs__body-item.ta-simple-tabs-users {
  padding-top: 5px;
}

.ta-users__preview .ta-users__preview__courses .ta-no-results, .ta-users__preview .ta-users__preview__users .ta-no-results {
  margin-top: 15px;
}

.ta-users__preview .ta-users__preview__courses .ta-simple-list-item__title, .ta-users__preview .ta-users__preview__users .ta-simple-list-item__title {
  font-weight: 500;
  margin-bottom: 0;
  position: relative;
  top: 1px;
}

.ta-users__preview__details__color {
  position: static;
}

.ta-users__preview__details__confirmed {
  display: inline-block;
  background: rgba(118, 152, 81, 0.15);
  color: #769851;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 11px;
  font-weight: 500;
  margin-left: 5px;
}

.ta-users__preview__details__invitation {
  background: rgba(203, 59, 67, 0.15);
  color: #cb3b43;
  border-radius: 3px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  font-weight: 500;
  margin-top: 5px;
}

.ta-users__preview__details__invitation i {
  margin-right: 5px;
}

.ta-users__preview__details__invitation__cta {
  float: right;
  color: #505a5e;
  font-weight: 300;
  cursor: pointer;
  text-decoration: underline;
}

.ta-users__preview__deactivate-button {
  padding: 8px 16px;
  border-radius: 3px;
  border: solid 1px #505a5e;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #505a5e;
  cursor: pointer;
}

.ta-users__preview__deactivate-button:disabled {
  color: #b6babd;
  border: solid 1px #b6babd;
  cursor: default;
}
