.ta-table-list-item {
  background: #fff;
  color: #505a5e;
  border-radius: 3px;
  border: 1px solid #fff;
  margin-bottom: 8px;
  padding: 10px;
  position: relative;
  min-height: 40px;
  cursor: pointer;
}

.ta-table-list-item.no-pointer {
  cursor: default;
}

.ta-table-list-item.draggable {
  cursor: grab;
}

.ta-sortable-list__item.dragging .ta-table-list-item {
  opacity: 0;
  margin-bottom: 0;
}

.ta-table-list-item:after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  width: 5px;
  border-radius: 3px 0 0 3px;
}

.ta-table-list-item:hover:after {
  width: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 2px 0 0 2px;
}

.ta-table-list-item:hover {
  background: #f6f8fa;
  border-color: #505a5e;
}

.ta-table-list-item.stalled, .ta-table-list-item.green, .ta-table-list-item.light-green, .ta-table-list-item.aqua, .ta-table-list-item.light-blue, .ta-table-list-item.purple, .ta-table-list-item.red, .ta-table-list-item.course, .ta-table-list-item.violet, .ta-table-list-item.dusk, .ta-table-list-item.grey, .ta-table-list-item.breaks, .ta-table-list-item.ocher, .ta-table-list-item.orange, .ta-table-list-item.brown, .ta-table-list-item.wood, .ta-table-list-item.default, .ta-table-list-item.cancelled, .ta-table-list-item.inactive, .ta-table-list-item.active {
  padding-left: 15px;
}

.ta-table-list-item.stalled:after {
  background: #ecd007;
}

.ta-table-list-item.active:after {
  background: #769851;
}

.ta-table-list-item.inactive:after {
  background: rgba(182, 186, 189, 0.5);
}

.ta-table-list-item.cancelled:after {
  background: #cb3b43;
}

.ta-table-list-item.course-booking:after {
  background: #b26492;
}

.ta-table-list-item.single-booking:after {
  background: #385f87;
}

.ta-table-list-item.title-booking:after {
  background: #ebb65d;
}

.ta-table-list-item.booker-customer:after {
  background: #385f87;
}

.ta-table-list-item.normal-customer:after {
  background: #f79a83;
}

.ta-table-list-item__title {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
  position: relative;
}

.ta-table-list-item__title-text {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 100px);
}

.ta-table-list-item__title-icon {
  margin-left: 8px;
  display: inline-block;
  max-width: 100px;
  position: relative;
  top: 0;
  vertical-align: top;
}

.ta-table-list-item__content {
  line-height: 17px;
  font-size: 12px;
  font-weight: 300;
  opacity: 0.8;
}

.ta-table-list-item__content i {
  font-size: 10px;
  position: relative;
  margin-right: 3px;
}

.ta-table-list-item__content a {
  color: #385f87;
  text-decoration: underline;
}

.ta-table-list-item__content a:hover {
  text-decoration: none;
}

.ta-table-list-item__buttons {
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}
