.ta-branches-resources-password-change__generate-btn {
    font-weight: bold;
    margin-bottom: 16px;
}

.ta-branches-resources-password-change__generate-title {
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: #505a5e;
}

.ta-branches-resources-password-change__generate-password {
    opacity: 0.8;
    font-size: 12px;
    line-height: 1.33;
    color: #505a5e;
}

.ta-branches-resources-password-change__generate-box {
    margin-bottom: 0;
    position: relative;
}

.ta-branches-resources-password-change__generate-copy {
    position: absolute;
    right: 8px;
    top: 9px;
    font-size: 14px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.ta-branches-resources-password-change__password-type-toggle-btn {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    position: absolute;
    top: 5px;
    right: 6px;
    cursor: pointer;
    font-size: 14px;
    color: #505a5e;
}
