.ta-navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 120px;
  height: 100%;
  min-height: 700px;
  background: #1d3247;
  border-top: 3px solid #CB3B43;
  color: #fff;
  z-index: 1001;
  transform: translateX(-118px);
  transition: all 0.2s;
}

.ta-navigation.pending:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.ta-navigation.show-account-switch .ta-menu {
  top: 129px;
}

.ta-navigation.negative-1 {
  left: 440px;
  transition: all 0.5s;
}

.ta-navigation.hover {
  transform: translateX(-113px);
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px;
}

.ta-navigation.expand {
  transform: translateX(0);
}

.ta-navigation.show-account-switch .ta-menu .ta-menu__notifications{
  visibility: hidden;
}

.ta-navigation.expand.show-account-switch .ta-menu .ta-menu__notifications{
  visibility: visible;
}

.ta-navigation__toggle-arrow {
  position: absolute;
  top: 19px;
  left: 100%;
  z-index: 10;
  cursor: pointer;
}

.ta-navigation__toggle-arrow.disabled {
  display: none;
}

.ta-navigation__toggle-arrow__icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 16px;
  position: absolute;
  font-size: 11px;
  top: 11px;
  left: -9px;
}

.ta-navigation.expand .ta-navigation__toggle-arrow:hover .ta-navigation__toggle-arrow__icon {
  background: #CB3B43;
}

.ta-navigation__toggle-arrow.collapsed .ta-navigation__toggle-arrow__icon i {
  transform: translateX(2px);
}

.ta-navigation__expand {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  z-index: 99999;
  cursor: pointer;
  transition: all 0.5s;
  transform: translateX(-20px);
}

.ta-navigation__expand.active {
  transform: translateX(0);
}

.ta-navigation__account.ta-btn {
  height: 64px;
  display: block;
  color: #fff;
  background: #CB3B43;
  margin-bottom: 2px;
  position: relative;
  width: 100%;
  border-radius: 0;
}

.ta-navigation__account .ta-avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.ta-navigation__account .ta-avatar .ta-avatar__image {
  width: 30px;
  height: 30px;
}

.ta-navigation__account .ta-avatar .ta-avatar__image img {
  width: 30px;
  height: 30px;
}

.ta-navigation__account__avatar-icon {
  border: 1px solid #3E5062;
  background: #fff;
  color: #3E5062;
  font-size: 6px;
  width: 14px;
  height: 14px;
  line-height: 12px;
  border-radius: 12px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 32px;
  transform: translateY(-50%);
  z-index: 2;
}

.ta-navigation__account__name {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  line-height: 12px;
  position: absolute;
  top: 50%;
  left: 52px;
  right: 10px;
  transform: translateY(-50%);
  word-wrap: break-word;
  max-height: 50px;
  overflow: hidden;
}

.ta-navigation__account:hover .ta-navigation__account__name {
  color: #CB3B43;
}

.ta-navigation.expand .ta-navigation__account.active .ta-navigation__account__name {
  color: #CB3B43;
}

.ta-navigation.expand .ta-navigation__account {
  padding: 0 16px;
  background-color: #3e5062;
}

.ta-navigation.expand .ta-navigation__account.active {
  background: #f4f3f3;
}

.ta-navigation__plan {
  position: absolute;
  bottom: 88px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
}

.ta-navigation__links {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1d3247;
  text-align: center;
}

.ta-navigation__links__link {
  display: inline-block;
  width: 40px;
}

.ta-navigation__links__link a {
  color: #fff;
  opacity: 0.35;
  font-size: 16px;
  display: block;
  transition: all 0.5s;
}

.ta-navigation__links__link a:hover {
  opacity: 1;
}

.ta-navigation .ta-blue-template-badge {
  width: 100%;
  margin-top: 0;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: center;
  color: #505a5e;
}
