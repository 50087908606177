.ta-booking-intervals-input__gap,
.ta-booking-intervals-input__interval {
  padding-left: 32px;
  margin-bottom: 7px;
  position: relative;
}

.ta-booking-intervals-input__box {
  position: absolute;
  top: 30px;
  left: 0;
  bottom: 5px;
  width: 24px;
}

.ta-booking-intervals-input__interval.first .ta-booking-intervals-input__box {
  border-radius: 3px 3px 0 0;
  top: 25px;
}

.ta-booking-intervals-input__interval.last .ta-booking-intervals-input__box {
  border-radius: 0 0 3px 3px;
  bottom: 0;
}

.ta-booking-intervals-input__box__before {
  display: block;
  height: 5px;
  width: 100%;
  position: absolute;
  top: -5px;
  left: 0;
  background-position: right top !important;
  background-repeat: repeat-x !important;
  background-size: 7px !important;
  transition: all .2s;
}

.ta-booking-intervals-input__box__after {
  display: block;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: -5px;
  left: 0;
  background-position: right top !important;
  background-repeat: repeat-x !important;
  background-size: 7px !important;
  transition: all .2s;
}

.ta-btn.ta-booking-intervals-input__btn-add {
  margin-top: 16px;
  font-weight: 500;
}

.ta-btn.ta-booking-intervals-input__btn-add i {
  font-size: 10px;
  margin-right: 3px;
}

.ta-booking-intervals-input__delete-section {
  position: absolute;
  top: 9px;
  left: 32px;
  right: 0;
}

.ta-booking-intervals-input__btn-delete--hover {
  float: right;
  right: 18px; /* in order to have this correctly positioned */
}

.ta-booking-intervals-input__btn-delete--hover .ta-hover-popup__content {
  text-align: center;
}

.ta-booking-intervals-input__btn-delete {
  position: absolute;
  top: -10px;
  right: -18px; /* in order to have popup above correctly positioned */
  width: 18px;
  height: 18px;
  line-height: 19px;
  text-align: center;
  background: #B6BABD;
  border-radius: 3px;
  font-size: 8px;
  color: #505A5E;
  cursor: pointer;
}

.ta-booking-intervals-input__btn-delete.disabled {
  background: rgba(182, 186, 189, 0.5);
  color: rgba(80, 90, 94, 0.5);
  cursor: not-allowed;
}

.ta-booking-intervals-input .ta-form-text {
  display: inline-block;
  background: #fff;
  position: relative;
  z-index: 5;
  padding-right: 5px;
}

.ta-booking-intervals-input__gap .ta-form-text {
  padding-bottom: 4px;
}

.ta-booking-intervals-input .ta-booking-intervals-add__popup {
  display: block;
}

.ta-booking-intervals-input .ta-booking-intervals-add__popup .ta-hover-popup__content {
  text-align: center;
}

.ta-booking-intervals-input .ta-striped-title__label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
}

.ta-booking-intervals-input__gap.hasDelete .ta-striped-title:after {
  margin-right: 18px;
}
