.ta-branches-customers__form-row.avatar {
  width: 140px;
  float: left;
  margin-right: 4px;
}

.ta-branches-customers__form-avatar-wrapper {
  width: 140px;
  float: left;
}

.ta-branches-customers__form-row.with-avatar {
  float: right;
  width: calc(100% - 140px);
}

/* IE FIX */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ta-branches-customers__form-row.with-avatar {
    width: calc(100% - 145px);
  }
}

.ta-branches-customers__form-row.half {
  float: right;
  display: inline-table;
  width: calc(50% + 4px);
}

.ta-branches-customers__form-row.with-avatar.half {
  width: calc((100% - 140px) / 2);
}

/* IE FIX */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ta-branches-customers__form-row.with-avatar.half {
    width: calc((100% - 145px) / 2);
  }
}

.ta-branches-customers__form-row.left {
  margin-left: 4px;
}

.ta-branches-customers__form .Select-menu-outer {
  margin-bottom: 10px;
}

.ta-branches-customers__bookings-print {
  width: 480px;
  height: 210px;
  padding: 16px;
}

.ta-branches-customers__bookings-print .ta-customer__bookings-print__text {
  height: 16px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
}

.ta-branches-customers__bookings-print .ta-title {
  margin: 0px 0 15px;
}

.ta-branches-customers__bookings-print .ta-print-btn {
  float: right;
}
