.ta-tags__preview__details .ta-simple-table {
  text-align: center;
}

.ta-tags__preview__details__header {
  position: relative;
  margin-bottom: 20px;
}

.ta-tags__preview__details__status {
  display: inline-block;
  padding: 0 5px;
  height: 12px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  background: #b6babd;
  font-size: 8px;
  font-weight: 500;
  color: #505a5e;
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
}

.ta-tags__preview__details__status.active {
  background: #769851;
  color: #fff;
}

.ta-tags__preview__details__owner {
  border: 2px solid #f4f3f3;
  background: #ecd007;
  font-size: 13px;
  color: #505a5e;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translateX(14px);
}

.ta-tags__preview__details .ta-tag-preview__name {
  margin-top: -5px;
  margin-bottom: 0;
}

.ta-tags__preview .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  padding-bottom: 20px;
}

.ta-tags__preview .ta-simple-tabs__body-item.ta-simple-tabs-courses, .ta-tags__preview .ta-simple-tabs__body-item.ta-simple-tabs-users {
  padding-top: 5px;
}

.ta-tags__preview .ta-tags__preview__courses .ta-no-results, .ta-tags__preview .ta-tags__preview__users .ta-no-results {
  margin-top: 15px;
}

.ta-tags__preview .ta-tags__preview__courses .ta-simple-list-item__title, .ta-tags__preview .ta-tags__preview__users .ta-simple-list-item__title {
  font-weight: 500;
  margin-bottom: 0;
  position: relative;
  top: 1px;
}

.ta-tags__preview__details__color {
  position: static;
}

.ta-tags__preview__details__confirmed {
  display: inline-block;
  background: rgba(118, 152, 81, 0.15);
  color: #769851;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 11px;
  font-weight: 500;
  margin-left: 5px;
}

.ta-tags__preview__details__invitation {
  background: rgba(203, 59, 67, 0.15);
  color: #cb3b43;
  border-radius: 3px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  font-weight: 500;
  margin-top: 5px;
}

.ta-tags__preview__details__invitation i {
  margin-right: 5px;
}

.ta-tags__preview__details__invitation__cta {
  float: right;
  color: #505a5e;
  font-weight: 300;
  cursor: pointer;
  text-decoration: underline;
}
