.ta-resources__preview__permissions__item__label {
  position: absolute;
  top: 5px;
  left: 17px;
}

.ta-resources__preview__permissions__item__content {
  display: inline-block;
  padding-left: 80px;
  margin-top: -3px;
  transform: translateY(-2px) translateZ(0);
}

.ta-resources__preview__permissions__item__content__badge {
  border-radius: 3px;
  height: 16px;
  color: #fff;
  font-size: 9px;
  font-weight: 500;
  line-height: 16px;
  vertical-align: middle;
  padding: 0 3px;
  display: inline-block;
  margin: 2px;
}

.ta-resources__preview__permissions__item .ta-dotted-timeline__item.big {
  padding-bottom: 0;
}
