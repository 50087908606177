.ta-accordion-second-level {
  user-select: none;
  position: relative;
  z-index: 1;
}

.ta-accordion-second-level .ta-accordion-second-level__title:after {
  content: "\F106";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 13px;
  color: #505a5e;
  transform: translateY(-50%);
}

.ta-accordion-second-level.inactive .ta-accordion-second-level__title:after {
  display: none;
}

.ta-accordion-second-level .ta-accordion-second-level__body {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-5px);
  max-height: 0;
  transition: all 0.2s;
}

.ta-accordion-second-level.expand .ta-accordion-second-level__title:after {
  content: "\F107"
}

.ta-accordion-second-level.expand .ta-accordion-second-level__body {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  max-height: 10000px;
}

.ta-accordion-second-level__title {
  position: relative;
}

.ta-accordion-second-level.has-field-with-error:not(.expand) {
  background: rgba(203, 59, 67, 0.1);
}
