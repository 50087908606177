.ta-rich-text {
  position: relative;
  min-height: 100px;
  line-height: 18px;
  padding-top: 19px;
  resize: vertical;
  transition: border-color .2s, background-color .2s;
}

.ta-rich-text.fullscreen {
  height: 100%;
  padding: 15px;
  background: #fff;
}

.ta-rich-text em {
	font-style: italic;
}

.ta-rich-text strong {
	font-weight: 600;
}

.ta-rich-text .quill {
  border: solid 1px rgba(80, 90, 94, 0.4);
  border-radius: 6px;
  transition: border-color .5s, background-color .5s;
}

.ta-rich-text .ql-toolbar.ql-snow {
  border-color: transparent;
  border-bottom: 1px solid rgba(80, 90, 94, 0.4);
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 4px;
  transition: border-color .5s, background-color .5s;
}

.ta-rich-text .ql-container.ql-snow {
  border-color: transparent;
  transition: border-color .5s, background-color .5s;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #fff;
}

.ta-rich-text .ql-editor {
  color: #505a5e;
  max-width: 426px;
  transition: border-color .5s, background-color .5s;
}

.ta-rich-text .ql-snow.ql-toolbar button {
  background: #edeeee;
  border-radius: 4px;
  color: #505a5e;
  outline: none;
  width: 24px;
  padding: 4px;
}

.ta-rich-text .ql-snow.ql-toolbar button:not(:last-child) {
  margin-right: 4px;
}

.ta-rich-text .ql-snow.ql-toolbar button svg {
  transform: scale(0.8); 
}

.ta-rich-text .ql-snow.ql-toolbar button .ql-stroke {
  stroke: #505a5e;
}

.ta-rich-text .ql-snow.ql-toolbar button .ql-fill {
  fill: #505a5e;
}

.ta-rich-text__editor:not(.disabled) .ql-snow.ql-toolbar button.ql-active {
  border: solid 2px #969c9e;
}

.ta-rich-text .ql-tooltip.ql-editing,
.ta-rich-text .ql-snow .ql-tooltip {
  left: 0 !important;
  color: #505a5e;
  z-index: 10;
}

.ta-rich-text .ql-snow .ql-tooltip[data-mode=link]::before {
  content: attr(data-before-enter);
}

.ta-rich-text .ql-snow .ql-tooltip::before {
  content: attr(data-before-visit);
}

.ta-rich-text .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: attr(data-after-save);
}

.ta-rich-text .ql-snow .ql-tooltip a.ql-action::after {
  content: attr(data-after-edit);
}

.ta-rich-text .ql-snow .ql-tooltip a.ql-remove::before {
  content: attr(data-before);
}

.ta-rich-text .ql-fullscreen {
  position: absolute;
  top: 25px;
  right: 4px;
  height: 24px;
  width: 28px;
  color: #505a5e;
  background: #fff;
  border-radius: 3px;
  font-size: 12px;
  border: none;
  outline: none;
  cursor: pointer;
}

.ta-rich-text .ql-fullscreen:hover  {
  border: solid 1px #385f87;
  background-color: rgba(56, 95, 135, 0.1);
  color: #385f87;
}

.ta-rich-text.fullscreen .ql-fullscreen  {
  right: 20px;
  top: 40px;
}

.ta-rich-text__container {
  position: relative;
}

.ta-rich-text__editor.tall .ql-editor {
  min-height: 400px;
}

.ta-rich-text__editor.medium .ql-editor {
  min-height: 200px;
}

.ta-rich-text__editor.short .ql-editor {
  min-height: 96px;
}

.ta-rich-text.fullscreen .quill {
  min-height: calc(100% - 15px);
}

.ta-rich-text.fullscreen .ql-container {
  min-height: calc(100vh - 81px);
}

.ta-rich-text.fullscreen .ql-editor {
  min-height: calc(100vh - 81px);
}

.ta-rich-text .ta-rich-text__label {
  position: absolute;
  bottom: 100%;
}

.ta-rich-text .ta-rich-text__label span {
  color: rgba(80, 90, 94, 0.6);
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
  letter-spacing: 0.5px;
}

.ta-rich-text__label-bottom {
  color: #505a5e;
  font-size: 12px;
  font-weight: 300;
  margin-top: 4px;
  font-style: italic;
  opacity: 0.5;
}

.ta-rich-text__length {
  height: 10px;
  line-height: 10px;
  font-size: 10px;
  position: absolute;
  right: 4px;
  bottom: 4px;
  z-index: 3;
  color: #505a5e;
}

.ta-rich-text__bounce {
  animation: bouce 0.75s infinite;
  -webkit-animation: bounce 0.75s infinite;
  -ms-animation: bounce 0.75s infinite;
}

.ta-rich-text .ql-editor.ql-blank {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

.ta-rich-text .ql-editor.ql-blank::before {
  position: relative;
  top: -17px;
  left: initial;
  right: initial;
}

.ta-rich-text__wrapper {
  position: relative;
}

/* FILLED */
.ta-rich-text__editor.filled.quill {
  border-color: rgba(80, 90, 94, 0.7);
}

/* FOCUSED */
.ta-rich-text__editor.focused.quill {
  border: solid 1px rgba(80, 90, 94, 0.8);
}

.ta-rich-text__editor.focused .ql-editor {
  color: #505a5e;
}

.ta-rich-text__editor.focused .ql-toolbar.ql-snow {
  background: #f6f7f7;
}

.ta-rich-text__editor.focused .ql-container.ql-snow {
  border-color: #f6f7f7;
  background: #f6f7f7;
}

.ta-rich-text__editor.focused .ql-snow.ql-toolbar button  {
  background: #fff;
}

.ta-rich-text.focused:not(.disabled) .ta-rich-text__label span {
  color: #505a5e;
}

/* DISABLED */
.ta-rich-text__editor.disabled.quill {
  box-shadow: none;
  border: solid 1px rgba(182, 186, 189, 0.3);
}

.ta-rich-text__editor.disabled .ql-editor,
.ta-rich-text__editor.disabled .ql-editor.ql-blank::before {
  color: rgba(80, 90, 94, 0.3);
}

.ta-rich-text__editor.disabled .ql-toolbar.ql-snow {
  background-color: #f7f8f8;
  border-bottom-color: rgba(182, 186, 189, 0.3);
}

.ta-rich-text__editor.disabled .ql-container.ql-snow {
  border-color: #f7f8f8;
  background-color: #f7f8f8;
}

.ta-rich-text__editor.disabled .ql-snow.ql-toolbar button {
  background-color: #e1e3e4;
  cursor: not-allowed;
}

.ta-rich-text__editor.disabled + .ta-rich-text__length {
  opacity: 0.3;
}

.ta-rich-text__editor.disabled .ql-snow.ql-toolbar button svg {
  opacity: 0.3;
}

.ta-rich-text__editor.disabled .ql-snow.ql-toolbar button.ql-active,
.ta-rich-text__editor.disabled .ql-snow.ql-toolbar button:hover,
.ta-rich-text__editor.disabled .ql-snow.ql-toolbar button:focus  {
  border: solid 1px rgba(182, 186, 189, 0.1);
  background-color: rgba(182, 186, 189, 0.5);
  color: #505a5e;;
}

.ta-rich-text__editor.disabled .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ta-rich-text__editor.disabled .ql-snow.ql-toolbar button:hover .ql-stroke,
.ta-rich-text__editor.disabled .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #505a5e;
}

.ta-rich-text__editor.disabled .ql-snow.ql-toolbar button.ql-active .ql-fill,
.ta-rich-text__editor.disabled .ql-snow.ql-toolbar button:hover .ql-fill,
.ta-rich-text__editor.disabled .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #505a5e;
}

.ta-rich-text__editor.disabled .ta-rich-text__label span {
  color: rgba(80, 90, 94, 0.3);
}

/* HOVER STATE */
.ta-rich-text__editor:not(.disabled):hover {
  box-shadow: 0 0 5px 0 rgba(80, 90, 94, 0.3)
}

.ta-rich-text__editor:not(.disabled):hover .ql-toolbar.ql-snow,
.ta-rich-text__editor:not(.disabled):hover .ql-container.ql-snow {
  background: #edeeee;
}

.ta-rich-text__editor:not(.disabled):hover .ql-snow.ql-toolbar button {
  background: #fff;
}

.ta-rich-text__editor:not(.disabled) .ql-snow.ql-toolbar button:hover  {
  background: rgba(80, 90, 94, 0.1);
  color: #505a5e;
}

.ta-rich-text__editor:not(.disabled) .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ta-rich-text__editor:not(.disabled) .ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: #505a5e;
}

.ta-rich-text__editor:not(.disabled) .ql-snow.ql-toolbar button.ql-active .ql-fill,
.ta-rich-text__editor:not(.disabled) .ql-snow.ql-toolbar button:hover .ql-fill {
  fill: #505a5e;
}

.ta-rich-text:not(.disabled) .ta-rich-text__container:hover .ta-rich-text__label:hover span {
  color: rgba(80, 90, 94, 0.6);
}

.ta-rich-text:not(.disabled) .ta-rich-text__container:hover .ta-rich-text__label span {
  color: #505a5e;
}

/* ERROR */ 
.ta-rich-text.hasError .ta-rich-text__editor {
  border-color: #cb3b43;
}

.ta-rich-text.hasError .ta-rich-text__label span {
  color: rgba(203, 59, 67, 0.7);
}

.ta-rich-text.hasError .ql-editor,
.ta-rich-text.hasError .ql-editor.ql-blank::before {
  color: rgba(203, 59, 67, 0.6);
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
  }
  50% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@-moz-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
  }
  50% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
  }

  50% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}
