.ta-booking-interval-example {
  position: relative;
  height: 65px;
  width: 100%;
  color: #505a5e;
  margin: 10px 0;
  transition: all .5s;
}

.ta-booking-interval-example.narrow {
  min-width: 397px;
}

.ta-booking-interval-example.has-label {
  margin-top: 30px;
}

.ta-booking-interval-example.quarterly {
  height: 153px;
}

.ta-booking-interval-example.half-hourly {
  height: 153px;
}

.ta-booking-interval-example.hourly {
  height: 153px;
}

.ta-booking-interval-example__label {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.5;
  position: absolute;
  top: -30px;
  margin: 2px 0;
  line-height: 15px;
  overflow: hidden;
}

.ta-booking-interval-example__grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 58px;
  transition: all .5s;
}

.ta-booking-interval-example.quarterly .ta-booking-interval-example__grid {
  height: 146px;
}

.ta-booking-interval-example.half-hourly .ta-booking-interval-example__grid {
  height: 146px;
}

.ta-booking-interval-example.hourly .ta-booking-interval-example__grid {
  height: 146px;
}

.ta-booking-interval-example__grid-col {
  display: inline-block;
  width: 100px;
  height: 100%;
  border-right: 1px dashed #c9c8c6;
  text-align: center;
  transition: all .5s;
  position: relative;
}

.ta-booking-interval-example.narrow .ta-booking-interval-example__grid-col {
  width: 98px;
}

.ta-booking-interval-example__grid-col:after {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: #c9c8c6;
  position: absolute;
  top: 100%;
  right: -3px;
}

.ta-booking-interval-example__grid-col.col-1 {
  border-left: 1px dashed #c9c8c6;
  width: 101px;
}

.ta-booking-interval-example__grid-col.col-1:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: #c9c8c6;
  position: absolute;
  top: 100%;
  left: -3px;
}

.ta-booking-interval-example.quarterly .ta-booking-interval-example__grid-col {
  width: 82px;
}

.ta-booking-interval-example.narrow.quarterly .ta-booking-interval-example__grid-col {
  width: 77px;
}

.ta-booking-interval-example.half-hourly .ta-booking-interval-example__grid-col {
  width: 97px;
}

.ta-booking-interval-example.narrow.half-hourly .ta-booking-interval-example__grid-col {
  width: 94px;
}

.ta-booking-interval-example.quarterly .ta-booking-interval-example__grid-col.col-5:before {
  content: "";
  border-right: 1px dashed #c9c8c6;
  position: absolute;
  top: 0;
  right: -71px;
  height: 100%;
  opacity: 0.5;
}

.ta-booking-interval-example.narrow.quarterly .ta-booking-interval-example__grid-col.col-5:before {
  right: -77px;
}

.ta-booking-interval-example__grid-col-label {
  border-bottom: 2px solid #cb3b43;
  margin: 0 3px;
  padding: 2px;
  font-size: 10px;
  font-weight: 500;
  position: relative;
  color: #505a5e;
}

.ta-booking-interval-example__grid-col-label:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border: 4px solid #cb3b43;
  border-color: transparent #cb3b43 transparent transparent;
  bottom: -5px;
  left: -5px;
}

.ta-booking-interval-example.narrow .ta-booking-interval-example__grid-col-label:before {
  width: 3px;
}

.ta-booking-interval-example__grid-col-label:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border: 4px solid #cb3b43;
  border-color: transparent transparent transparent #cb3b43;
  bottom: -5px;
  right: -5px;
}

.ta-booking-interval-example.narrow .ta-booking-interval-example__grid-col-label:after {
  width: 3px;
}

.ta-booking-interval-example__slot {
  background: #505a5e;
  color: #fff;
  height: 20px;
  line-height: 20px;
  border-radius: 3px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 30px;
  left: 3px;
  width: 95px;
  transition: all .5s;
}

.ta-booking-interval-example.narrow .ta-booking-interval-example__slot {
  width: 92px;
}

.ta-booking-interval-example.quarterly .ta-booking-interval-example__slot {
  width: 181px;
}

.ta-booking-interval-example.narrow.quarterly .ta-booking-interval-example__slot {
  width: 179px;
}

.ta-booking-interval-example.half-hourly .ta-booking-interval-example__slot {
  width: 120px;
}

.ta-booking-interval-example.narrow.half-hourly .ta-booking-interval-example__slot {
  width: 101px;
}

.ta-booking-interval-example.hourly .ta-booking-interval-example__slot {
  width: 55px;
}

.ta-booking-interval-example.narrow.hourly .ta-booking-interval-example__slot {
  width: 63px;
}

.ta-booking-interval-example__slot.slot-1 {
  left: 3px;
}

.ta-booking-interval-example__slot.slot-2 {
  left: 103px;
}

.ta-booking-interval-example.narrow .ta-booking-interval-example__slot.slot-2 {
  left: 100px;
}

.ta-booking-interval-example.quarterly .ta-booking-interval-example__slot.slot-2 {
  left: 84px;
  top: 52px;
}

.ta-booking-interval-example.narrow.quarterly .ta-booking-interval-example__slot.slot-2 {
  left: 79px;
  top: 52px;
}

.ta-booking-interval-example.half-hourly .ta-booking-interval-example__slot.slot-2 {
  left: 99px;
  top: 52px;
}

.ta-booking-interval-example.narrow.half-hourly .ta-booking-interval-example__slot.slot-2 {
  left: 96px;
}

.ta-booking-interval-example.hourly .ta-booking-interval-example__slot.slot-2 {
  top: 52px;
}

.ta-booking-interval-example.narrow.hourly .ta-booking-interval-example__slot.slot-2 {
  top: 47px;
}

.ta-booking-interval-example__slot.slot-3 {
  left: 203px;
}

.ta-booking-interval-example.narrow .ta-booking-interval-example__slot.slot-3 {
  left: 198px;
}

.ta-booking-interval-example.quarterly .ta-booking-interval-example__slot.slot-3 {
  left: 166px;
  top: 74px;
}

.ta-booking-interval-example.narrow.quarterly .ta-booking-interval-example__slot.slot-3 {
  left: 156px;
  top: 74px;
}

.ta-booking-interval-example.half-hourly .ta-booking-interval-example__slot.slot-3 {
  left: 196px;
  top: 74px;
}

.ta-booking-interval-example.narrow.half-hourly .ta-booking-interval-example__slot.slot-3 {
  left: 190px;
  top: 74px;
}

.ta-booking-interval-example.hourly .ta-booking-interval-example__slot.slot-3 {
  top: 74px;
}

.ta-booking-interval-example.narrow.hourly .ta-booking-interval-example__slot.slot-3 {
  top: 69px;
}

.ta-booking-interval-example__slot.slot-4 {
  left: 303px;
}

.ta-booking-interval-example.narrow .ta-booking-interval-example__slot.slot-4 {
  left: 296px;
}

.ta-booking-interval-example.quarterly .ta-booking-interval-example__slot.slot-4 {
  left: 248px;
  top: 96px;
}

.ta-booking-interval-example.narrow.quarterly .ta-booking-interval-example__slot.slot-4 {
  left: 233px;
  top: 96px;
}

.ta-booking-interval-example.half-hourly .ta-booking-interval-example__slot.slot-4 {
  left: 293px;
  top: 96px;
}

.ta-booking-interval-example.narrow.half-hourly .ta-booking-interval-example__slot.slot-4 {
  left: 284px;
  top: 96px;
}

.ta-booking-interval-example.hourly .ta-booking-interval-example__slot.slot-4 {
  top: 96px;
}

.ta-booking-interval-example__slot.slot-5 {
  left: 403px;
}

.ta-booking-interval-example.narrow .ta-booking-interval-example__slot.slot-5 {
  left: 394px;
}

.ta-booking-interval-example.quarterly .ta-booking-interval-example__slot.slot-5 {
  left: 314px;
  top: 118px;
}

.ta-booking-interval-example.narrow.quarterly .ta-booking-interval-example__slot.slot-5 {
  left: 310px;
}

.ta-booking-interval-example.half-hourly .ta-booking-interval-example__slot.slot-5 {
  left: 390px;
  top: 118px;
}

.ta-booking-interval-example.narrow.half-hourly .ta-booking-interval-example__slot.slot-5 {
  left: 378px;
}

.ta-booking-interval-example.hourly .ta-booking-interval-example__slot.slot-5 {
  top: 118px;
}
