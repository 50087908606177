.ta-courses__form .ta-buffer-times__box {
  bottom: auto;
  top: 27px;
}

.ta-courses__form .ta-message.active {
  margin: 8px 0 0 0;
}

.ta-courses__form .ta-courses__form__reminder-container,
.ta-courses__form .ta-courses__form__recipient-container {
  position: relative;
}

.ta-courses__form .ta-courses__form__reminder.has-delete,
.ta-courses__form .ta-courses__form__recipient.has-delete {
  margin-right: 30px;
}

.ta-courses__form .ta-btn.ta-courses__form__btn-delete {
  width: 24px;
  height: 40px;
  line-height: 40px;
  padding: 0px;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 11px;
}

.ta-courses__form .ta-service-duration__question-circle-icon{
  font-size: 12px;
  color:rgba(80, 90, 94, 0.7)
}

.ta-courses__form .ta-courses__form__btn-add {
  font-weight: 500;
}

.ta-courses__form .ta-courses__form__btn-add i {
  font-size: 14px;
}

.ta-courses__form .ta-courses__form--outer-box {
  margin-bottom: 0;
}

.ta-list-item__participant:before {
  color: #b6babd;
}

.ta-courses__form .ta-buffer-times .ta-striped-title__label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
}

.ta-courses__form .ta-services-duration-tooltip {
  float: right;
}

.ta-booking-intervals-input .ta-striped-title .ta-striped-title__label{
  background-color: #fff;
}  

/* .ta-courses__form .ta-hover-popup {
  width: 100%;
}
*/

.ta-services__form__description-hover-popup {
  width: 100%;
}

.ta-courses__form .ta-bordered-box .ta-hover-popup {
  width: 100%;
}