.ta-table-row {
  background: #fff;
  border: 1px solid #d4d6d7;
  border-radius: 3px;
  margin-bottom: 8px;
  display: table;
  width: 100%;
  color: #505a5e;
  font-size: 12px;
  position: relative;
}

.ta-table-row:hover {
  background: #f6f8fa;
  border-color: #505a5e;
}

.ta-table-row:hover .ta-table-row__col {
  border-color: #505a5e;
}

.ta-table-row.color {
  padding-left: 5px;
}

.ta-table-row.color.transparent {
  border: 1px solid #505a5e;
}

.ta-table-row.color.transparent:before {
  background: transparent;
  border-left: 1px solid #505a5e;
  border-top: 1px solid #505a5e;
  border-bottom: 1px solid #505a5e;
}


.ta-popup__content.ta-statistics-box .ta-table-row:hover,
.ta-popup__content.ta-statistics-box .ta-table-row:hover:before {
  border-left: 1px solid #505a5e;
  border-top: 1px solid #505a5e;
  border-bottom: 1px solid #505a5e;
}

.ta-table-row.color:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  width: 5px;
  border-radius: 3px 0 0 3px;
  background: rgba(182, 186, 189, 0.5);
}

.ta-table-row.color.green:before {
  background: #82A774;
}

.ta-table-row.color.red:before {
  background: #B22E53;
}

.ta-table-row.color.breaks:before {
  background: #ebb65d;
}

.ta-table-row.color.light-green:before {
  background: #95C5A6;
}

.ta-table-row.color.course:before {
  background: #b26492;
}

.ta-table-row.color.ocher:before {
  background: #cc9e52;
}

.ta-table-row.color.aqua:before {
  background: #8EBEC3;
}

.ta-table-row.color.violet:before {
  background: #A476CA;
}

.ta-table-row.color.purple:before {
  background: #7684CA;
}

.ta-table-row.color.dusk:before {
  background: #5D5A79;
}

.ta-table-row.color.grey:before {
  background: #8B8B8B;
}

.ta-table-row.color.brown:before {
  background: #C46F4E;
}

.ta-table-row.color.wood:before {
  background: #7B6161;
}

.ta-table-row.color.light-blue:before {
  background: #6882B0;
}

.ta-table-row.color.yellow:before {
  background: #ebb65d;
}

.ta-table-row.color.default:before {
  background: #385f87;
}

.ta-table-row.color.orange:before {
  background: #e5774c;
}

.ta-table-row__col {
  display: table-cell;
  vertical-align: middle;
  border-left: 1px solid #d4d6d7;
  padding: 8px;
  text-align: center;
  cursor: pointer;
}

.ta-table-row__col.text-left {
  text-align: left;
}

.ta-table-row__col.text-right {
  text-align: left;
}

.ta-table-row__col__arrow-separator {
  padding: 4px 0;
  font-size: 10px;
  opacity: 0.5;
}

.ta-table-row__main-col {
  display: table-cell;
  padding: 8px;
}

.ta-table-row__main-col.middle {
  vertical-align: middle;
}

.ta-table-row__main-col.text-center {
  text-align: center;
}

.ta-table-row__main-col.text-right {
  text-align: right;
}

.ta-table-row__main-col__title {
  font-size: 14px;
  font-weight: 500;
}

.ta-table-row__main-col__content {
  font-weight: 300;
  margin-top: 4px;
}

/* HEADER */

.ta-table-row__header {
  display: table;
  width: 100%;
  vertical-align: middle;
  border-bottom: 1px solid rgba(182, 186, 189, 1);
  margin-bottom: 8px;
  font-size: 10px;
  font-weight: 500;
  color: #505a5e;
  text-transform: uppercase;
  padding-right: 1px;
}

.ta-table-row__header .ta-table-row__main-col {
  vertical-align: middle;
}

.ta-table-row__header .ta-table-row__col {
  border-color: transparent;
}


/* OVERWRITES */

.ta-table-row__main-col__content ul {
  margin-top: 6px;
}

.ta-table-row__main-col__content li {
  line-height: 18px;
}

.ta-table-row .ta-plan-badge.classic {
  border: 1px solid #ddd;
}
