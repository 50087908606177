.ta-settings__security .ta-title__content .ta-btn,
.ta-settings__security__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 4px;
}

.ta-settings__security__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-settings__security .ta-title__content .ta-title__link {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
}

.ta-settings__security .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-settings__form.ta-security-settings__form .left-offset {
  margin-left: 0;
}

.ta-security-settings__form .ta-form-text.thin-text {
  font-size: 12px;
}
