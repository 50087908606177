.ta-statistics-composite__wrapper {
  max-height: 288px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #fff;
  border: solid 1px #cbced0;
}

.ta-statistics-composite__header {
  background: #dcdedf;
  border-radius: 3px 3px 0 0;
  padding: 8px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
}

.ta-statistics-composite__header__filter-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-statistics-composite__header__filter-wrapper .ta-simple-box-label-button {
  font-size: 8px;
  font-weight: 900;
  margin-right: 8px;
}

.ta-statistics-composite__header__filter-wrapper .ta-popup__trigger {
  font-size: 16px;
  color: rgba(80, 90, 94, 0.6);
  cursor: pointer;
}

.ta-statistics-composite__header-main-text {
  color: rgba(80, 90, 94, 1);
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
}

.ta-statistics-composite__header-sub-text {
  color: rgba(80, 90, 94, 0.8);
  font-size: 10px;
  font-weight: 500;
  line-height: 1.4;
  margin-top: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ta-statistics-composite__wrapper.short .ta-statistics-composite__body {
  padding: 12px 4px 8px 4px;
} 

.ta-statistics-composite__body {
  padding: 12px 4px 8px 4px;
  display: grid;
  grid-template-columns: 1fr 20px 1fr 20px 1fr;
  grid-template-rows: 1fr;
  align-items: center;
}

.ta-statistics-composite__body.pending {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 230px;
  position: relative;
}

.ta-statistics-composite__body-item {
  width: 100%;
}

.ta-statistics-composite__body .ta-hover-popup {
  position: absolute;
  right: 0;
  bottom: 0%;
}

/* child components */

.ta-statistics-composite-child {
  height: 212px;
  border-radius: 6px;
  border: solid 1px #cbced0;
  padding: 4px;
  width: 100%;
}

.ta-statistics-composite-child.short {
  height: 94px;
}

.ta-statistics-composite-child.total {
  background: #ebeff3;
  position: relative;
}

.ta-statistics-composite-child.total .ta-statistics-composite-child__header {
  background: #d3d9de;
}

.ta-simple-box-label-button {
  height: 14px;
  padding: 4px 4px 3px 4px;
  line-height: 14px;
  font-size: 8px;
  background: #b0b5b6;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-transform: uppercase;
}

.ta-statistics-composite-child__header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #edeeee;
}

.ta-statistics-composite-child__body {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 36%; */
  /* height: 162px; */
  height: 79%;
}

.ta-statistics-composite-child__body.short {
  height: 50%;
}

.ta-statistics-composite-child__body.equation {
  display: grid;
  grid-template-rows: 1fr 20px 1fr 20px 1fr;
  grid-template-columns: 1fr;
  padding-top: 10px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
}

.ta-statistics-composite-child__body__equation-sign {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cbced0;
}

.ta-statistics-composite-child__body__equation-sign p {
  width: 7%;
}

.ta-statistics-composite-child__body__equation-sign .ta-borderline {
  width: 100%;
  height: 1px;
  background: #cbced0;
  margin-left: 2px;
}

.ta-statistics-composite-child__body__equation-sign .ta-borderline.dashed {
  width: 93%;
  border: 1px dashed #cbced0;
  background: transparent;
  transform: translateY(1px);
}

.ta-statistics-composite-child__header__text {
  color: #505a5e;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  padding: 0 4px;
}

.ta-statistics-composite-child.short {
  height: 94px;
}

.ta-statistics-composite-child__number {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 900;
  color: #365e89;
}

.ta-statistics-composite__sign {
  margin: 0 4px;
  transform: translateY(105%);
  font-weight: 500;
  font-size: 20px;
  color: #cbced0;
}

/* .ta-statistics-composite-child__body__equation-item {
} */

.ta-statistics-composite-child__body__equation-item__data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ta-statistics-composite-child__body__equation-item__data-text {
  font-size: 18px;
  font-weight: 900;
  color: #365e89;
  line-height: 1.2;
}

.ta-statistics-composite-child__body__equation-item__data-number {
  font-size: 24px;
  font-weight: 900;
}

.ta-statistics-composite-child__body__equation-item__data-label {
  color: #fff;
  border-radius: 7px;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 900;
  padding: 2px 5px;
  text-align: center;
}

.ta-statistics-composite-child__body__equation-item__data-label i {
  margin-right: 2px;
}
