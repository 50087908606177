.ta-resources__form__acl .ta-blur {
  height: 170px;
  margin: 28px 0;
}

.ta-resources__form__acl .ta-blur .ta-blur__content__text {
  margin-top: 0;
}

.ta-resources__form__acl .ta-accordion {
  margin-bottom: 4px;
}

.ta-resources__form__acl .ta-bordered-box .ta-bordered-box {
  margin-top: 4px;
  margin-bottom: 2px;
}

.ta-resources__form__acl .ta-bordered-box .ta-bordered-box .ta-row {
  margin-bottom: 4px;
}

.ta-resources__form__acl .ta-bordered-box .ta-bordered-box .ta-row:last-child {
  margin-bottom: 0;
}

.ta-resources__form__acl .ta-bordered-box .ta-bordered-box .ta-striped-title {
  margin: 4px 0;
}

.ta-resources__form__acl .ta-hint-text {
  margin-bottom: 4px;
}

.ta-resources__form__acl__label {
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: #505a5e;
  margin: 2px 0;
}

.ta-resources__form__acl .ta-accordion__title .ta-title {
  border: none;
}

.ta-resources__form__acl .ta-accordion {
  padding: 3px 8px 0 8px;
  border-radius: 3px;
  border: solid 1px #cbced0;
}

.ta-resources__form__acl .ta-accordion.expand {
  border: solid 1px #969c9e;
  padding-bottom: 8px;
}

.ta-resources__form__acl .ta-title.small {
  margin-bottom: 0;
}

.ta-resources__form__acl .ta-row.ta-resources__form__acl__row-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  border-radius: 3px;
  border: solid 1px #c5c8ca;
  background-color: #f0f1f2;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 8px;
}

.ta-resources__form__acl .ta-row.ta-resources__form__acl__row-title:last-child {
  margin-bottom: 0;
}

.ta-resources__form__acl .ta-row.ta-resources__form__acl__row-title .ta-form-group {
  padding: 0;
}

.ta-resources__form__acl .ta-resources__form__acl__row__wrapper {
  border: solid 1px #c5c8ca;
  border-radius: 3px;
  margin-bottom: 8px;
}

.ta-resources__form__acl .ta-row.ta-resources__form__acl__row-title .ta-checkbox {
  margin-left: -34px;
  padding-left: unset;
}

.ta-resources__form__acl .ta-resources__form__acl__row__wrapper .ta-row.ta-resources__form__acl__row-title {
  border: none;
  border-radius: 3px 3px 0 0;
}

.ta-resources__form__acl .ta-resources__form__acl__row__wrapper .ta-bordered-box {
  border: none;
  margin-bottom: 0;
}
