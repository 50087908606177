.ta-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 800;
  background: -moz-linear-gradient(top, #385f87 0%, #1d3247 100%);
  background: -webkit-linear-gradient(top, #385f87 0%, #1d3247 100%);
  background: linear-gradient(to bottom, #385f87 0%, #1d3247 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#1d3247', startColorstr='#385f87',GradientType=0 );
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s;
}

.ta-overlay.active {
  visibility: visible;
  opacity: 0.9;
  animation: overlay-animation 0.8s linear;
}

.ta-overlay.position-top {
  z-index: 5000;
}

@keyframes overlay-animation {
  from {
    opacity: 0;
    visibility: hidden;
  }
}


