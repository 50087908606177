.ta-apps__list .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 110px;
  background: #fff;
  left: 24px;
  right: 24px;
}

.ta-apps__list .ta-offline .ta-offline-body,
.ta-apps__list .ta-offline {
  width: 100%;
}

.ta-apps__list .ta-simple-tabs__body::after {
  content: "";
  position: fixed;
  top: 174px;
  left: 0px;
  height: 15px;
  width: calc(100% - 48px);
  margin: 0 24px;
  z-index: 999;
  background: linear-gradient(to bottom, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
}

.ta-apps__list .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  padding-bottom: 20px;
}

.ta-apps__list .ta-plan-badge.classic {
  border: 1px solid #ddd;
}

.ta-apps__list .ta-simple-list-item.ta-apps__list__app {
  border: 1px solid rgba(182, 186, 189, 0.5);
  padding-left: 112px;
  min-height: 66px;
}

.ta-apps__list .ta-simple-list-item.ta-apps__list__app .ta-simple-list-item__title-text {
  max-width: 70%;
}

.ta-apps__list .ta-simple-list-item.ta-apps__list__app .ta-simple-list-item__title {
  font-weight: 500;
  margin-bottom: 0;
}

.ta-apps__list .ta-simple-list-item.ta-apps__list__app .ta-list-button {
  height: 57px;
  line-height: 57px;
}

.ta-apps__list .ta-simple-list-item.ta-apps__list__app .ta-list-button:hover,
.ta-apps__list .ta-simple-list-item.ta-apps__list__app .ta-list-button.active {
  background-color: #9b9ea1;
  color: #505a5e;
}

.ta-apps__list .ta-simple-list-item.ta-apps__list__app:hover .ta-apps__list__btn-arrow {
  background: #cb3b43;
  color: #fff;
}

.ta-apps__list .ta-section-title {
  margin-top: 0;
}

.ta-apps__list .ta-form-section:last-child {
  margin-bottom: -8px;
}

.ta-apps__list .ta-simple-list-item__buttons {
  top: 4px;
  transform: translateY(0) translateZ(0);
}

.ta-apps__list .ta-message .ta-form {
  padding-bottom: 0;
}

.ta-apps__list .ta-message.active {
  margin-bottom: 0;
}

.ta-apps__list__app-image {
  position: absolute;
  left: 8px;
  top: 8px;
  width: 96px;
  height: 48px;
  border-radius: 3px;
  background: #eee;
  font-size: 0px;
}

.ta-apps__list__app-developer {
  font-size: 12px;
  font-weight: 300;
  color: #505a5e;
  line-height: 12px;
  margin-bottom: 3px;
}

.ta-apps__list__app-data {
  font-size: 10px;
  line-height: 10px;
  display: flex;
}

.ta-apps__list__app-data i {
  font-size: 14px;
}

.ta-apps__list__app-data__item {
  font-weight: 300;
  margin-right: 8px;
  color: #505a5e;
}

.ta-apps__list__app-data__item.green {
  font-weight: 500;
  color: #95c5a6;
}

.ta-apps__list__app-data__item.bold {
  font-weight: 500;
}

.ta-apps__list__app-data__item.italic {
  font-weight: 500;
  font-style: italic;
  color: #505a5e;
}

.ta-apps__list__app-version-update-available {
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  color: #cb3b43;
  display: inline-block;
  margin-left: 5px;
}

.ta-apps__list__app-payment-failed {
  margin-top: 8px;
  padding: 8px;
  border-radius: 0 0 2px 2px;
  background-color: rgb(205, 57, 63, .15);
  color: #cb3b43;
  font-size: 12px;
  font-weight: bold;
  margin-left: -112px;
  margin-right: -10px;
  margin-bottom: -10px;
}

.ta-apps__list__app-payment-failed i {
  margin-right: 8px;
}

.ta-apps__list__app .ta-simple-list-item__buttons {
  z-index: 1;
}

.ta-apps-actions__popup {
  opacity: 0;
  width: 200px;
  position: absolute;
  top: 50%;
  right: 37px;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 4px;
  color: #505a5e;
  font-size: 13px;
  font-weight: 400;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, .4));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, .4));
  z-index: 1500;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  padding: 10px;
}

.ta-apps-actions__popup.active {
  opacity: 1;
  visibility: visible;
}

.ta-apps-actions__popup::after {
  content: "";
  border: 8px solid transparent;
  position: absolute;
  border-left-color: #fff;
  top: 50%;
  left: 100%;
  transform: translateY(-50%) translateZ(0);
}

.ta-simple-list-item__buttons .ta-apps-actions__popup .ta-hover-popup {
  margin-left: 0;
  width: 100%;
}

.ta-apps-actions__popup__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #b6babd;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  transition: color .2s;
}

.ta-apps-actions__popup__item.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.ta-apps-actions__popup__item:not(.disabled):hover {
  color: #505a5e;
}

.ta-apps-actions__popup__item:not(:last-of-type),
.ta-simple-list-item__buttons .ta-apps-actions__popup .ta-hover-popup:not(:last-child) {
  margin-bottom: 8px;
}

.ta-apps-actions__popup__item i {
  font-size: 14px;
  margin-right: 4px;
  line-height: 1.43;
  width: 20px;
  text-align: center;
}
