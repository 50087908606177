.ta-hover-popup {
  position: relative;
  display: inline-block;
}

.ta-hover-popup.block {
  display: block;
}

.ta-hover-popup__content {
  position: absolute;
  width: 200px;
  background: #fff;
  border-radius: 4px;
  color: #505a5e;
  font-size: 12px;
  font-weight: 400;
  padding: 8px;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, .4));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, .4));
  z-index: 1600;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  line-height: 1.33;
}

.ta-hover-popup__content.auto-size {
  width: auto;
  white-space: nowrap;
}

.ta-hover-popup.is-hover .ta-hover-popup__content {
  opacity: 1;
  visibility: visible;
}

.ta-hover-popup.is-hover.disabled .ta-hover-popup__content {
  opacity: 0;
  visibility: hidden;
}

/* TOOLTIP STYLES */
.ta-hover-popup__content.top {
  bottom: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);
}

.ta-hover-popup__content.top-left {
  bottom: calc(100% + 12px);
  left: 0;
}

.ta-hover-popup__content.top-right {
  bottom: calc(100% + 12px);
  right: 0;
}

/* bottom side styles */
.ta-hover-popup__content.bottom {
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);
}

.ta-hover-popup__content.bottom-left {
  top: calc(100% + 12px);
  left: 0;
}

.ta-hover-popup__content.bottom-right {
  top: calc(100% + 12px);
  right: 0;
}

  /* left side styles */
.ta-hover-popup__content.left {
  right: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%) translateZ(0);
}

.ta-hover-popup__content.left-top {
  right: calc(100% + 12px);
  top: 0;
}

.ta-hover-popup__content.left-bottom {
  right: calc(100% + 12px);
  bottom: 0;
}

/* right side styles */
.ta-hover-popup__content.right {
  left: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%) translateZ(0);
}

.ta-hover-popup__content.right-top {
  left: calc(100% + 12px);
  top: 0;
}

.ta-hover-popup__content.right-bottom {
  left: calc(100% + 12px);
  bottom: 0;
}


/* ARROW STYLES */

.ta-hover-popup__content:after {
  content: "";
  border: 8px solid transparent;
  position: absolute;
  pointer-events: none;
}

/* top side arrow styles  */
.ta-hover-popup__content.top:after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top-color: #fff;
}

.ta-hover-popup__content.top-left:after {
  top: 100%;
  left: 8px;
  border-top-color: #fff;
}

.ta-hover-popup__content.top-right:after {
  top: 100%;
  right: 8px;
  border-top-color: #fff;
}

/* left side arrow styles  */
.ta-hover-popup__content.left:after {
  top: 50%;
  left: 100%;
  transform: translateY(-50%) translateZ(0);
  border-left-color: #fff;
}

.ta-hover-popup__content.left-top:after {
  top: 8px;
  left: 100%;
  border-left-color: #fff;
}

.ta-hover-popup__content.left-bottom:after {
  bottom: 8px;
  left: 100%;
  border-left-color: #fff;
}

/* right side arrow styles */
.ta-hover-popup__content.right:after {
  top: 50%;
  right: 100%;
  transform: translateY(-50%) translateZ(0);
  border-right-color: #fff;
}

.ta-hover-popup__content.right-top:after {
  top: 8px;
  right: 100%;
  border-right-color: #fff;
}

.ta-hover-popup__content.right-bottom:after {
  bottom: 8px;
  right: 100%;
  border-right-color: #fff; 
}

/* bottom styles */
.ta-hover-popup__content.bottom:after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-bottom-color: #fff;
}

.ta-hover-popup__content.bottom-left:after {
  bottom: 100%;
  left: 8px;
  border-bottom-color: #fff;
}

.ta-hover-popup__content.bottom-right:after {
  bottom: 100%;
  right: 8px;
  border-bottom-color: #fff;
}

.ta-hover-popup__content.bottom-left.auto-size,
.ta-hover-popup__content.top-left.auto-size,
.ta-hover-popup__content.top-right.auto-size {
    white-space: nowrap;
    min-width: inherit;
}