.ta-category {
  position: relative;
  border-bottom: 1px solid #c9c8c6;
  padding-left: 22px;
  height: 28px;
  line-height: 28px;
  margin-bottom: 10px;
  color: #505a5e;
}

.ta-category.loading:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -1px;
  background: rgba(244, 243, 243, 0.9);
  z-index: 100;
}

.ta-category.loading:after {
  content: "\f110";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  z-index: 100;
}

.ta-category:hover:not(.uncategorized) {
  color: #385f87;
  border-color: #385f87;
}

.ta-category__icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  color: #adbdc6;
}

.ta-category:hover:not(.uncategorized) .ta-category__icon {
  color: #385f87;
}

.ta-category__label {
  font-size: 15px;
}

.ta-category__form-control input.ta-form-control {
  height: 26px;
  line-height: 26px;
  padding: 0;
  width: calc(100% - 70px);
  border: none;
  background: transparent;
}

.ta-category__form-control.hide {
  opacity: 0;
  transform: translateY(-5000px);
}

.ta-category__actions, .ta-category__edit-actions {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ta-category__actions {
  opacity: 0;
  visibility: hidden;
}

.ta-category:hover .ta-category__actions {
  opacity: 1;
  visibility: visible;
}

.ta-category__actions__btn {
  display: inline-block;
  width: 25px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  font-size: 15px;
  color: #505a5e;
  cursor: pointer;
  margin-left: 5px;
}

.ta-category__actions__btn:hover {
  color: #385f87;
}

.ta-category.uncategorized .ta-category__actions, .ta-category.uncategorized .ta-category__edit-actions {
  display: none;
}

.ta-category-popup {
  transform: translate(37px, 12px);
}
