.ta-statistic-box .ta-statistic-box__title {
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #365e89;
  padding-top: 16px;
}

.ta-statistic-box .ta-statistic-box__info {
  opacity: 0.7;
  font-family: Ubuntu;
  margin-top: 8px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  color: #505a5e;
  padding-bottom: 17px;
}

.ta-statistic-box .ta-statistic-box__title__icon {
  font-size: 16px;
  margin-left: 8px;
}

.ta-statistic-box .ta-statistic-box__span {
  font-weight: 600;
}

.ta-statistic-box .ta-statistic-box__info__item {
  margin-bottom: 4px;
}
