.ta-branches__form .ta-branches-personal-info, .ta-branches__form .ta-branches-address-info {
  border-radius: 3px;
  border: 1px solid rgba(182, 186, 189, 0.5);
  padding: 14px 8px;
}

.ta-branches__form .ta-form-section .ta-branches-personal-info {
  padding-bottom: 8px;
}

.ta-branches__form .ta-message.active {
  margin-bottom: 0;
}

.ta-branches__form .ta-message__title {
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #505a5e;
}

.ta-branches__form .ta-message__title>i {
  display: inline-block;
  font-weight: 600;
}
