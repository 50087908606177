.ta-branches .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-branches .ta-title__content .ta-btn,
.ta-branches-preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-branches .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: inline-block;
}

.ta-branches .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-branches .ta-form {
  padding-bottom: 0;
}

.ta-branches .ta-form .ta-select {
  background: #fff;
}

.ta-branches .ta-fixed-content:before {
  top: 137px;
  transition: top .4s;
}

.ta-branches.no-permissions .ta-fixed-content:before {
  top: 94px;
}

.ta-branches.import-message-open .ta-fixed-content:before {
  top: 235px;
}

.ta-branches .ta-fixed-content__body {
  top: 142px;
  transition: top .4s;
}

.ta-branches.no-permissions .ta-fixed-content__body {
  top: 94px;
}

.ta-branches__list__item {
  cursor: pointer;
}

.ta-branches__list__item__title i {
  font-size: 12px;
  margin: 0 5px;
}

.ta-branches__list__item__buttons {
  display: flex;
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
}

.ta-branches__list__item__buttons .ta-hover-popup {
  width: 100%;
}

.ta-branches__list__item__buttons .ta-hover-popup:not(:last-child) {
  margin-right: 4px;
}

.ta-branches__list__item__buttons .ta-hover-popup__trigger {
  height: 100%;
}

.ta-btn.ta-branches__list__item__link-btn.ta-btn__login-without-customisations {
  padding: 0 5px;
  width: 100%;
  height: 100%
}

.ta-branches__list__item__link-btn:hover {
  background: #CB3B43;
  border-color: #CB3B43;
  color: #fff;
}

.ta-branches__list__item__link-btn i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
}

.ta-number-box.ta-branches__list__item__total-resources-btn {
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  height: calc(100% - 8px);
  width: calc(50% - 6px);
}

.ta-number-box.ta-branches__list__item__total-resources-btn.full {
  background-color: #505a5e;
  border-color: #505a5e;
  color: #fff;
}

.ta-number-box.ta-branches__list__item__total-bookings-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  height: calc(100% - 8px);
  width: calc(50% - 6px);
}

.ta-number-box.ta-branches__list__item__total-bookings-btn.full {
  background-color: #385f87;
  border-color: #385f87;
  color: #fff;
}

/* Branches Import */
.ta-branches.import-message-open .ta-fixed-content__body {
  top: 235px;
}

.ta-branches__import {
  font-size: 12px;
  line-height: 1.33;
}

.ta-branches__import .ta-fixed-content__body {
  top: 68px;
}

.ta-branches__import__step {
  display: flex;
  align-items: center;
}

.ta-branches__import__step-number {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 50%;
  background: #505a5e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}

.ta-branches__import__step-title {
  font-weight: 900;
  color: #505a5e;
}

.ta-branches__import .ta-btn {
  font-weight: 900;
}

.ta-branches__import__step-description {
  color: #505a5e;
}

.ta-branches__import__step-hint {
  color: #505a5e;
}

.ta-branches__import .ta-link__list-item {
  position: relative;
  text-decoration: underline;
  color: #385f87;
  cursor: pointer;
  height: fit-content;
  padding: 0 0 0 12px;
}

.ta-branches__import .ta-link__list-item::before {
  content: "\f111";
  font-family: "Font Awesome 5 Pro";
  margin-right: 6px;
  font-weight: 700;
  font-size: 6px;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 4px;
  color: #d4d6d7;
}

.ta-branches__import .ta-btn-submit i {
  margin-right: 5px;
}

.ta-branches__import .ta-files-input {
  margin-top: 5px;
}

.ta-branches__import .ta-btn.disabled {
  background: #b6babd;
  border-color: #b6babd;
  color: #505a5e;
}

.ta-password__type-toggle-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #505a5e;
}

.ta-branches-preview .ta-branches-export-btn {
  height: 32px;
  line-height: 30px;
  font-weight: bold;
  color: #505a5e;
  position: relative;
  border: solid 1px #505a5e;
}

.ta-branches-preview .ta-branches-export-btn i {
  margin-right: 8px;
}

.ta-export-branches-form__wrapper {
  width: 460px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  transition: min-height .3s;
  padding: 15px;
}

.ta-export-branches-form__wrapper .ta-title {
  margin-top: 0;
}

.ta-export-branches-form__wrapper .ta-message.type-success.active {
  margin-top: 0;
}

.ta-export-branches-form__wrapper .ta-form {
  padding-bottom: 0;
}

.ta-export-branches-form__wrapper .ta-form .ta-form-buttons {
  display: flex;
  justify-content: space-between;
}

.ta-export-branches-form__wrapper .ta-form .ta-form-buttons i {
  margin-right: 5px;
}

.ta-export-branches-form__wrapper.success {
  min-height: 240px;
}

/* 
  these pointer events are needed in order to make
  onMouseLeave function of HoverTrigger work
  (nasty browser bug, see https://github.com/facebook/react/issues/4492#issuecomment-426356566)
*/

.ta-export-branches-form__wrapper .ta-btn.ta-btn-secondary.disabled {
  font-weight: 700;
  pointer-events: none;
}

.ta-export-branches-form__wrapper .ta-btn.ta-btn-primary {
  pointer-events: none;
}

.ta-export-branches-form__wrapper .ta-btn:not(:disabled) {
  pointer-events: all;
}

.ta-export-branches-form__success-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ta-export-branches-form__success-icon-container {
  display: flex;
  justify-content: center;
}

.ta-export-branches-form__success-icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #769851;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #fff;
}

.ta-export-branches-form__success-text-container {
  padding: 8px 20px 16px 20px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  text-align: center;
  color: #505a5e;
}

.ta-export-branches-form__success-button-container {
  display: flex;
  justify-content: center;
}