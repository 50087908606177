.ta-sso {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: #edeeee;
  border-top: 4px solid #505a5e;
}

.ta-sso__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  padding: 16px;
  position: relative;
  z-index: 10;
}

.ta-sso__box .ta-loader {
  border-radius: 3px;
}

.ta-sso .ta-form {
  padding-bottom: 15px;
}

.ta-sso__title {
  margin: -14px -14px 16px -14px;
  height: 60px;
  line-height: 60px;
  background: #edeeee;
  font-size: 16px;
  font-weight: 500;
  color: #505a5e;
  border-radius: 3px 3px 0 0;
  text-align: center;
}

.ta-sso__box__login-btn a {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  text-align: center;
  display: block;
  opacity: 0.5;
  padding: 5px 0;
}

.ta-sso__box__login-btn a:hover {
  text-decoration: underline;
}
