.ta-btn {
  height: 32px;
  padding: 8px 12px;
  border-radius: 6px;
  border: none;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  display: inline-block;
}

.ta-btn.ta-btn-block {
  display: block;
  width: 100%;
  text-align: center;
}

.ta-btn.ta-btn-md {
  padding: 4px 8px;
  height: 24px;
}

.ta-btn.ta-btn-icon-only.ta-btn-md {
  height: 24px;
  width: 24px;
  padding: 4px;
  margin-right: 4px;
}

.ta-btn.ta-btn-sm {
  padding: 4px;
  height: 20px;
  font-size: 10px;
  line-height: 1;
}

.ta-btn.ta-btn-xl {
  height: 40px;
}

.ta-btn.ta-btn-icon-only.ta-btn-xl {
  height: 40px;
  width: 40px;
  padding: 4px;
  font-size: 14px;
}

.ta-btn.ta-btn-border-md {
  border-radius: 4px;
}

.ta-btn.ta-btn-icon-only.ta-btn-sm {
  height: 20px;
  width: 20px;
  padding: 4px;
}

.ta-btn.ta-btn-rounded { 
  border-radius: 50%;
}

.ta-btn:hover:not(.ta-btn-disabled) {
  cursor: pointer;
}
.ta-btn.ta-btn-icon-only {
  padding: 8px;
  width: 32px;
  height: 32px;
}

.ta-btn.ta-btn-primary {
  background-color: #cb3b43;
  color: #fff;
}
.ta-btn.ta-btn-primary:hover {
  background-color: #b6343c;
}
.ta-btn.ta-btn-primary.ta-btn-ghost {
  color: #cb3b43;
  border: 1px solid #cb3b43;
  background-color: transparent;
}
.ta-btn.ta-btn-primary.ta-btn-ghost:hover {
  background-color: rgba(203, 59, 67, 0.15);
}

.ta-btn.ta-btn-secondary {
  background-color: #b6babd;
  color: #505a5e;
}
.ta-btn.ta-btn-secondary:hover {
  background-color: #a3a7a9;
}
.ta-btn.ta-btn-secondary.ta-btn-ghost {
  color: #b6babd;
  background-color: transparent;
  border: solid 1px #b6babd;
}

.ta-btn.ta-btn-secondary.ta-btn-ghost:hover {
  background-color: rgba(182, 186, 189, 0.15);
}

.ta-btn.ta-btn-tertiary {
  background-color: #505a5e;
  color: #fff;
}
.ta-btn.ta-btn-tertiary:hover {
  background-color: #475054;
}
.ta-btn.ta-btn-tertiary.ta-btn-ghost {
  background-color: transparent;
  color: #505a5e;
  border: solid 1px #505a5e;
}
.ta-btn.ta-btn-tertiary.ta-btn-ghost:hover {
  background-color: rgba(80, 90, 94, 0.15);
}

.ta-btn.ta-btn-primary.ta-btn-disabled,
.ta-btn.ta-btn-secondary.ta-btn-disabled,
.ta-btn.ta-btn-tertiary.ta-btn-disabled {
  background-color: #e1e3e4;
  color: rgba(80, 90, 94, 0.3);
}

.ta-btn.ta-btn-primary.ta-btn-disabled:hover,
.ta-btn.ta-btn-secondary.ta-btn-disabled:hover,
.ta-btn.ta-btn-tertiary.ta-btn-disabled:hover {
  background-color: #dadcde;
}

.ta-btn.ta-btn-primary.ta-btn-ghost.ta-btn-disabled,
.ta-btn.ta-btn-secondary.ta-btn-ghost.ta-btn-disabled,
.ta-btn.ta-btn-tertiary.ta-btn-ghost.ta-btn-disabled {
  border: solid 1px rgba(182, 186, 189, 0.4);
  color: rgba(80, 90, 94, 0.3);
  background-color: transparent;
}

.ta-btn.ta-btn-primary.ta-btn-ghost.ta-btn-disabled:hover,
.ta-btn.ta-btn-secondary.ta-btn-ghost.ta-btn-disabled:hover,
.ta-btn.ta-btn-tertiary.ta-btn-ghost.ta-btn-disabled:hover {
  background-color: rgba(225, 227, 228, 0.15);
}

.ta-btn.ta-btn-premium {
  background: #ecd007;
  border-color: #ecd007;
  color: #505a5e;
  font-weight: 700;
  text-align: center;
}
