/* activity log card manin container */
.ta-history__activity-logs__system-action {
  position: relative;
  background: #fff;
  border-radius: 3px;
  padding-left: 4px;
  margin-bottom: 8px;
  color: #505a5e;
}

/* activity log card color stripe section main syle */
.ta-history__activity-logs__system-action:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  border-radius: 3px 0 0 3px;
}

/* activity log card color stripe section syle for approve */
.ta-history__activity-logs__system-action.approved:after {
  background: #769851;
}

/* activity log card color stripe section syle for expire,decline and revoke */
.ta-history__activity-logs__system-action.expired:after,
.ta-history__activity-logs__system-action.declined:after,
.ta-history__activity-logs__system-action.revoked:after {
  background: #cb3b43;
}

/* activity log main header container */
.ta-history__activity-logs__system-action__head {
  height: 24px;
  line-height: 24px;
  border-radius: 0 3px 0 0;
  background: #e5e7e7;
  padding: 0 8px;
}

/* activity log header type section */
.ta-history__activity-logs__system-action__type {
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  float: left;
  color: #505A5E;
  letter-spacing: 0.5px;
}

/* activity log header created by section */
.ta-history__activity-logs__system-action__created-by {
  float: right;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #505A5E;
  letter-spacing: 0.5px;
}

/* activity log main body container */
.ta-history__activity-logs__system-action__body {
  padding: 8px;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 4px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e5e7e7;
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 3px;
  min-height: 48px;
}

/* date section main body container */
.ta-history__activity-logs__system-action__date-time-box {
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 80px;
  border: 1px solid rgba(182, 186, 189, 0.5);
  border-radius: 3px;
}

/* date section content */
.ta-history__activity-logs__system-action__date-time-box__content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%) translateZ(0);
  text-align: center;
}

/* date section date section */
.ta-history__activity-logs__system-action__date-time-box__content-date {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

/* date section time section */
.ta-history__activity-logs__system-action__date-time-box__content-time {
  font-size: 9px;
  font-weight: 500;
  opacity: 0.8;
  letter-spacing: 0.41px;
  line-height: 9px;
}

/* activity log body contnent main body */
.ta-history__activity-logs__system-action__content {
  width: 100%;
  padding-left: 90px;
}

/* activity log body contnent header */
.ta-history__activity-logs__system-action__content__head {
  font-size: 14px;
  color: #1C272B;
  line-height: 20px;
  text-transform: none;
  direction: ltr;
  font-weight: 500;
  border-bottom: 1px dotted rgba(182, 186, 189, 0.43);
}

/* activity log body contnent header decline */
.ta-history__activity-logs__system-action.declined .ta-history__activity-logs__system-action__body .ta-history__activity-logs__system-action__content .ta-history__activity-logs__system-action__content__head {
  border-bottom: 0;
}

/* activity log body contnent body */
.ta-history__activity-logs__system-action__content__body {
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.2;
  color: #505a5e;
  display: inline;
  letter-spacing: 0.5px;
}

/* activity log body contnent body clock style */
.ta-history__activity-logs__system-action__content__body .fal.fa-hourglass-half::before {
  margin-right: 3px;
}