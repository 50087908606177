.ta-list-button.ta-btn {
  font-size: 14px;
  text-align: center;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  width: 24px;
  cursor: pointer;
  margin-left: 4px;
  padding: 0;
}

.ta-list-button.square {
  width: 50px;
}

.ta-list-button.has-text {
  width: auto;
  font-size: 12px;
  font-weight: bold;
  padding: 0 10px;
}