.ta-blue-template {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -moz-linear-gradient(top, rgba(56,95,135,0.8) 0%, rgba(29,50,71,0.8) 100%);
  background: -webkit-linear-gradient(top, rgba(56,95,135,0.8) 0%,rgba(29,50,71,0.8) 100%);
  background: linear-gradient(to bottom, rgba(56,95,135,0.8) 0%,rgba(29,50,71,0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc385f87', endColorstr='#cc1d3247',GradientType=0 );
}

.ta-blue-template:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 4px solid #cb3b43;
}

.ta-blue-template .ta-logo {
  position: relative;
  top: 4px;
  left: 100px;
  width: 80px;
  height: 95px;
  line-height: 100px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
}
