.ta-groups .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-groups .ta-accordion .ta-accordion__title:after {
  right: 16px;
}

.ta-groups .ta-form {
  padding-bottom: 15px;
}

.ta-groups__list .ta-sortable-list {
  margin-top: 5px;
}

.ta-groups__form .ta-fixed-content__body {
  padding-top: 7px;
}

.ta-groups__preview .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-simple-tabs-groups .ta-icon-list .ta-popup__trigger a {
  color: #FFF;
}

.ta-groups__list .ta-hover-popup {
  margin-left: 8px;
  color: #505a5e;
}

.ta-groups__list .ta-hover-popup:hover {
  color: #385f87;
}

.ta-popup__groups-help-box iframe {
  margin-top: 8px;
}

.ta-groups__group.updated {
  position: relative;
}

.ta-groups__group.updated:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  animation-duration: 2s;
  animation-name: group-updated-before;
  animation-iteration-count: 1;
}

@keyframes group-updated-before {
  0% {
    opacity: 1;
    background: rgba(244, 243, 243, 1);
  }

  100% {
    opacity: 0;
    background: rgba(244, 243, 243, 0);
  }
}

.ta-groups__group.updated:after {
  content: "\f021";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  opacity: 0;
  animation-duration: 2s;
  animation-name: group-updated-after;
  animation-iteration-count: 1;
}

@keyframes group-updated-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-groups__group.deleted {
  position: relative;
}

.ta-groups__group.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  animation-duration: 2s;
  animation-name: group-deleted-before;
  animation-iteration-count: 1;
}

@keyframes group-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-groups__group.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  animation-duration: 2s;
  animation-name: group-deleted-after;
  animation-iteration-count: 1;
}

@keyframes group-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-groups__group__is-stalled {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ecd007;
  width: 30px;
  border-radius: 0 2px 2px 0;
}

.ta-groups__group__is-stalled i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
}

.ta-groups__group.is-stalled .ta-groups__group__is-stalled {
  display: block;
}

.ta-groups .ta-simple-list-item.draggable .ta-groups__group__is-stalled {
  display: none;
}

.ta-groups__group>div {
  padding-bottom: 8px;
}

.ta-groups__category {
  position: relative;
  padding: 0 0 15px 0;
}

.ta-groups__category .ta-section-title {
  margin-top: 0;
}

.ta-groups__category .ta-section-title .ta-grab {
  cursor: grab;
}

.ta-groups__category.pending:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f4f3f3;
  opacity: 0.5;
  z-index: 99;
}

.ta-groups__category.pending:after {
  content: "\f110";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #505a5e;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  z-index: 100;
}

.ta-groups__category.deleted {
  position: relative;
}

.ta-groups__category.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  animation-duration: 2s;
  animation-name: group-category-deleted-before;
  animation-iteration-count: 1;
}

@keyframes group-category-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0.5);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-groups__category.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  animation-duration: 2s;
  animation-name: group-category-deleted-after;
  animation-iteration-count: 1;
}

@keyframes group-category-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-groups .ta-simple-list-item .ta-list-button {
  cursor: grab;
}

.ta-groups .ta-simple-list-item .ta-list-button:hover {
  background: #b6babd;
  color: #505a5e;
}

.ta-groups.categories-collapse .ta-groups__list {
  max-height: 50px;
  overflow: hidden;
}

.ta-groups.categories-collapse .ta-groups__list {
  position: relative;
}

.ta-groups.categories-collapse .ta-groups__list:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.ta-groups.categories-collapse .ta-groups__list:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  z-index: 99;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#f4f3f3', GradientType=0);
}

.ta-groups.categories-collapse .ta-list-button {
  display: none;
}

/* ***********************************************

FIX DND COMPONENT

Issue: https://github.com/atlassian/react-beautiful-dnd/issues/703
Remove left prop when fix is provide

*********************************************** */

.ta-groups__category__dnd-wrapper,
.ta-groups__group__dnd-wrapper {
  left: 15px !important;
}

.ta-groups__category__dnd-wrapper.is-dragging .ta-groups__category {
  opacity: 0.8;
}

.ta-groups__group__dnd-wrapper.is-dragging .ta-simple-list-item {
  opacity: 0.8;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
}

.ta-groups .ta-groups__category.has-buttons .ta-accordion .ta-accordion__title:after {
  right: 90px;
}

.ta-groups .ta-groups__category.has-buttons.default .ta-accordion .ta-accordion__title:after {
  right: 62px;
}

.ta-hover-popup.ta-group__popup {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30px;
}

.ta-group__popup .ta-hover-popup__content.left {
  width: 300px;
}

.ta-groups .ta-simple-list-item {
  padding: 8px;
}

.ta-groups .ta-hover-popup__trigger i {
  line-height: 23px;
  font-size: 12px;
}

.ta-groups .ta-groups__group__title-icon {
  font-size: 12px;
  line-height: 12px;
  margin-right: 6px;
}

.ta-groups .ta-groups__group__description {
  display: block;
  border-top: 1px solid #d6d9da;
  margin-top: 8px;
  padding: 4px 2px 0 2px;
  font-style: italic;
  font-weight: 500;
}

.ta-groups .ta-groups__group__description i {
  font-size: 10px;
  position: relative;
  margin-right: 6px;
  top: 1px;
}

.ta-groups .ta-groups__group__meta-info {
  border-top: 1px solid #d6d9da;
  margin-top: 8px;
  padding: 4px 2px 0 2px;
}

.ta-groups .ta-simple-list-item__title-text {
  font-size: 14px;
  font-weight: bold;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: #505a5e;
  text-align: center;
  margin-top: 0;
  max-width: calc(100% - 30px);
}

.ta-groups .ta-list-inline li {
  display: inline-block;
}

.ta-groups .ta-groups__group__meta-info li {
  font-size: 10px;
}
