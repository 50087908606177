.ta-user-group-details .ta-user-group-details__tabs-wrapper {
  margin-top: 16px;
}

.ta-user-group-details .ta-simple-list-item__content {
  opacity: 1;
}

/* Cols */

.ta-user-group-details .col-33 {
  width: 33.3%;
}

.ta-user-group-details .col-84 {
  width: 84.5%;
}

.ta-user-group-details .ta-col-users-preview {
  width: 294px;
}

/* title */

.ta-user-group-details .ta-title__content .ta-btn {
  width: 24px;
  height: 24px;
  padding: 0 6px;
}

/* tab content details*/

.ta-user-group-details .ta-simple-tabs__body-item {
  padding: 1px 20px 16px 20px;
}

.ta-user-group-details .ta-icon-list {
  min-height: 32px;
}

.ta-user-group-details .ta-icon-list a {
  color: #385f87;
}

.ta-user-group-details .ta-icon-list__body {
  font-weight: 400;
}

.ta-user-group-details .ta-icon-list__title {
  font-weight: 500;
}

.ta-user-group-details .ta-simple-tabs__header-item {
  margin-right: 2.5px;
}

/* tab content resources*/

.ta-user-group-details .ta-simple-tabs-resources .ta-simple-list-item {
  padding: 0;
  min-height: 10px;
  height: 32px;
}

.ta-user-group-details .ta-simple-tabs-resources .ta-hint-text {
  line-height: 19px;
}

.ta-user-group-details .ta-simple-list-item .ta-btn {
  width: 24px;
  height: 24px;
  padding: 0px 9px;
  opacity: 1;
  line-height: 0;
}

.ta-user-group-details .ta-simple-list-item .ta-btn>i {
  font-weight: 600;
  font-size: 14px;
}

.ta-user-group-details .ta-simple-list-item .ta-btn-secondary:hover {
  background: #cb3b43;
  color: #ffffff;
}

.ta-user-group-details .ta-simple-tabs-resources .ta-simple-list-item__title {
  padding: 6px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.07;
  color: #505a5e;
}

/* font awesome */

.ta-user-group-details .ta-section-title .ta-section-title__icon {
  color: #505a5e;
}

/* status */

.ta-user-group-details .ta-user-group-details__verified {
  font-weight: 400;
  color: #769851;
}

.ta-user-group-details .ta-user-group-details__not-verified {
  color: rgb(218, 54, 36);
}

/* Plan box */

.ta-user-group-details .ta-user-group-plan {
  display: inline-block;
  height: 16px;
  border-radius: 3.3px;
  font-size: 9px;
  font-weight: 500;
  line-height: 1.09;
  color: #ffffff;
  padding: 4px;
  font-weight: 600;
}

.ta-user-group-details .ta-user-group-box-classic {
  background-color: #ffffff;
  color: #505a5e;
}

.ta-user-group-details .ta-user-group-box-premium {
  background-color: #ecd007;
  color: #505a5e;
}

.ta-user-group-details .ta-user-group-box-enterprise {
  background-color: #cb3b43;
}

/* History tab */

.ta-simple-tabs-history .ta-simple-list-item {
  height: 32px;
  min-height: 30px;
  right: 23px;
}

.ta-user-group-details .ta-dotted-timeline__item.small::before {
  top: 24%;
  left: 3px;
}

.ta-user-group-details .ta-dotted-timeline__item.small::after {
  border-left: 4px dotted #b6babd;
  height: 36px;
  left: 5px;
  bottom: -3px;
}

.ta-user-group-details .ta-plan-history__dotted-timeline .ta-dotted-timeline:last-child .ta-dotted-timeline__item.small::after, .ta-user-group-details .ta-activity-history__dotted-timeline .ta-dotted-timeline:last-child .ta-dotted-timeline__item.small::after {
  display: none;
}

.ta-user-group-details .ta-plan-history__dotted-timeline .ta-dotted-timeline:last-child .ta-dotted-timeline__item.small::before, .ta-user-group-details .ta-activity-history__dotted-timeline .ta-dotted-timeline:last-child .ta-dotted-timeline__item.small::before {
  display: none;
}
