.ta-intervals-input {
  position: relative;
}

.ta-intervals-input__interval {
  margin-bottom: 8px;
  position: relative;
  padding-left: 55px;
}

.ta-intervals-input__interval__checkbox {
  position: absolute;
  top: 9px;
  left: 0;
  width: 55px;
  z-index: 1;
  cursor: pointer;
}

.ta-intervals-input .ta-intervals-input__interval:last-child {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.ta-intervals-input__interval__from {
  display: inline-block;
  width: calc((100% - 20px) / 2);
}

.ta-intervals-input__interval.has-delete .ta-intervals-input__interval__from {
  width: calc((100% - 52px) / 2);
}

.ta-intervals-input__interval__separator {
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
  width: 20px;
  text-align: center;
  vertical-align: top;
  margin-top: 15px;
}

.ta-intervals-input__interval__until {
  display: inline-block;
  width: calc((100% - 20px) / 2);
}

.ta-intervals-input__interval.has-delete .ta-intervals-input__interval__until {
  width: calc((100% - 52px) / 2);
}

.ta-btn.ta-intervals-input__interval__btn-delete {
  width: 24px;
  height: 40px;
  line-height: 38px;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.ta-btn.ta-intervals-input__btn-add {
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 55px;
}

.ta-btn.ta-btn-block.ta-btn.ta-intervals-input__btn-add {
  width: calc(100% - 55px);
}

.ta-intervals-input.disabled .ta-intervals-input__btn-add {
  display: none;
}

.ta-intervals-input.disabled .ta-intervals-input__interval__btn-delete  {
  opacity: 0.5;
  cursor: default;
}

.ta-intervals-input.disabled .ta-intervals-input__interval__separator {
  opacity: 0.3;
}
