.ta-branches-services__form .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-branches-services__form .ta-services__form__description-hover-popup {
  width: 100%;
}

.ta-branches-services__form .ta-buffer-times__box {
  bottom: auto;
  top: 27px;
}

.ta-branches-services__form .ta-message.active {
  margin: 8px 0 0 0;
}

.ta-branches-services__form .ta-services-duration-tooltip {
  float: right;
}

.ta-branches-services__form .ta-services__form__reminder-container,
.ta-branches-services__form .ta-services__form__recipient-container {
  position: relative;
}

.ta-branches-services__form .ta-services__form__recipient-container .ta-form-group {
  padding-top: 3px;
}

.ta-branches-services__form .ta-bordered-box .ta-row:last-child {
  margin-bottom: 8px;
}

.ta-branches-services__form .ta-services__form__reminder.has-delete,
.ta-branches-services__form .ta-services__form__recipient.has-delete {
  margin-right: 30px;
}

.ta-branches-services__form .ta-services__form__times .ta-alert {
  margin-top: 8px;
}

.ta-branches-services__form .ta-btn.ta-services__form__btn-delete {
  width: 24px;
  height: 40px;
  line-height: 40px;
  padding: 0px;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 11px;
}

.ta-branches-services__form .ta-services__form__btn-add {
  font-weight: 500;
}

.ta-branches-services__form .ta-services__form__btn-add i {
  font-size: 14px;
}

.ta-branches-services__form .ta-branches-service-duration__question-circle-icon{
  font-size: 12px;
  color:rgba(80, 90, 94, 0.7)
}

.ta-branches-services__form .ta-services__form--outer-box {
  margin-bottom: 0;
}

.ta-branches-services__form .ta-row__no-margin {
  margin-bottom: 0;
}

.ta-branches-services__form .ta-row__no-margin .ta-hover-popup {
  width: 100%;
}

.ta-list-item__participant:before {
  color: #b6babd;
}

.ta-branches-services__form .ta-bordered-box .ta-hover-popup {
  width: 100%;
}

.ta-branches-services__form .ta-bordered-box .ta-row:last-child {
  margin-bottom: 8px;
}
