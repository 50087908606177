.ta-image-input-wrapper {
  width: 140px;
}

.ta-image-input {
  width: 100%;
  height: 100%;
  position: relative;
}

.ta-image-input:after {
  content: ' ';
  display: table;
  clear: both;
}

.ta-image-input .ta-image-input__buttons {
  width: 100%;
  position: relative;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
}

.ta-image-input .ta-image-input__buttons.center {
  justify-content: center;
}

.ta-image-input .ta-image-input__sliders {
  width: 100%;
  position: relative;
}

.ta-image-input .ta-btn.ta-btn-grey-ghost {
  width: calc(100% - 4px);
  min-width: 35px;
  height: 18px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 2px;
  border: 1px solid #505a5e;
}

.ta-image-input .ta-btn.ta-btn-grey-ghost:hover{
  background-color: rgba(80, 90, 94, 0.15);
}

.ta-image-input .ta-image-input__controls {
  padding: 4px;
  width: 100%;
  border: solid 1px rgba(182, 186, 189, 0.5);
  margin-top: -3px;
  border-radius: 0 0 3px 3px;
}

.ta-image-input__rotate-left {
  display: flex;
  align-items: center;
}

.ta-image-input__rotate-right {
  display: flex;
  align-items: center;
}

.ta-image-input .ta-single-slider__content {
  margin-top: 8px;
  display: flex;
  height: 16px;
  align-items: center;
}

.ta-image-input .ta-single-slider__left-label, .ta-image-input .ta-single-slider__right-label {
  transform: none;
}

.ta-image-input .ta-image-input__slider__icon {
  font-size: 12px;
}

.ta-image-input canvas {
  border-radius: 3px 3px 0 0;
}

.ta-image-input .ta-image-input-upload-area {
  width: 100%;
  height: 140px;
  background: #505a5e;
  border: 4px solid rgba(29, 50, 71, 0.3);
  color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  z-index: 2;
  cursor: pointer;
  text-align: center;
}

.ta-image-input .ta-image-input-upload-area__image {
  width: auto;
  height: 100%;
  z-index: 1;
}

.ta-image-input .ta-image-input-upload-area__icon {
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
}

.ta-image-input .ta-image-input-upload-area__text {
  width: 100%;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 8px;
  font-weight: 500;
  color: #fff;
}

.ta-image-input .ta-image-input-upload-area__formats {
  width: 100%;
  opacity: 0.7;
  font-size: 10px;
  font-weight: 400;
  color: #fff;
}

.ta-image-input .ta-image-input__dropzone {
  width: 100%;
  height: 100%;
}

.ta-image-input .ta-image-input__avatar-editor {
  width: 100%;
  height: 100%;
}

.ta-image-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.ta-image-wrapper img {
  width: 100%;
  height: 100%;
}

.ta-btn-full-width {
  width: 100% !important;
}

.ta-btn-full-width-label {
  font-size: 10px;
  font-weight: 500;
  padding-left: 4px;
}
