.ta-login__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
}

.ta-login__box .ta-form {
  padding: 0;
}

.ta-login__box .ta-checkbox {
  margin-bottom: 10px;
}

.ta-login__box-content {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  padding: 16px 16px 0 16px;
  position: relative;
  z-index: 2;
}

.ta-login__box-content .ta-loader {
  border-radius: 3px;
}

.ta-login__password-type-toggle-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #505a5e;
}

.ta-login__password-type-toggle-btn.disabled {
  cursor: not-allowed;
  color: rgba(80, 90, 94, 0.4);
}

.ta-login__link {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  opacity: 0.5;
  line-height: 32px;
}

.ta-login__link:hover {
  opacity: 1;
}

.ta-login__title {
  position: relative;
  margin: -14px -14px 18px -14px;
  height: 60px;
  line-height: 60px;
  background-color: rgba(80, 90, 94, 0.1);
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  text-align: center;
  color: #505a5e;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding-top: 6px;
}

.ta-login__box-footer {
  position: relative;
  z-index: 1;
  background: #efefef;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0 0 3px 3px;
  margin: 0 20px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.ta-login__box-footer a {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  display: block;
}

.ta-login__box-footer a:hover {
  text-decoration: underline;
}

.ta-login_forgot-password {
  display: inline-block;
  position: relative;
  font-size: 12px;
  font-weight: bold;
  opacity: 0.5;
  color: #505a5e;
}

.ta-login .ta-btn-secondary {
  position: absolute;
  top: 14px;
  left: 14px;
  width: 32px;
  height: 32px;
  background: #b6babd;
}

.ta-login .ta-login__title .ta-btn-secondary .fa-long-arrow-left {
  font-size: 14px;
  line-height: 1;
  text-align: center;
  color: #505a5e;
  margin: -7px;
}

.ta-forgotten-password-form .ta-form .ta-row, .ta-login .ta-row-login {
  padding-bottom: 16px;
  margin-bottom: 0;
}

.ta-login .ta-row-login {
  padding-top: 7px;
}

.ta-login__box .ta-checkbox {
  margin-bottom: 0;
}

.ta-login .ta-row-password {
  padding-top: 6px;
}

.ta-login__sso-btn {
  margin-top: 8px;
}
