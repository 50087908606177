.ta-user-preview {
  text-align: center
}

.ta-user-preview__name {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #505a5e;
}
