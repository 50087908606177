
.ta-statistics-export-form {
    width: 460px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
    transition: min-height .3s;
    padding: 15px;
  }
  
  .ta-statistics-export-form .ta-title {
    margin-top: 0;
  }
  
  .ta-statistics-export-form .ta-message.type-success.active {
    margin-top: 0;
  }
  
  .ta-statistics-export-form .ta-form {
    padding-bottom: 0;
  }
  
  .ta-statistics-export-form .ta-form .ta-form-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .ta-statistics-export-form .ta-form .ta-form-buttons i {
    margin-right: 5px;
  }
  
  /* 
    these pointer events are needed in order to make
    onMouseLeave function of HoverTrigger work
    (nasty browser bug, see https://github.com/facebook/react/issues/4492#issuecomment-426356566)
  */
  
  .ta-statistics-export-form .ta-btn.ta-btn-secondary.disabled {
    font-weight: 700;
    pointer-events: none;
  }
  
  .ta-statistics-export-form .ta-btn.ta-btn-primary {
    pointer-events: none;
  }
  
  .ta-statistics-export-form .ta-btn:not(:disabled) {
    pointer-events: all;
  }
  