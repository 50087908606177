.ta-plan-badge {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  font-weight: 600;
  color: #505a5e;
  border-radius: 3px;
}

.ta-plan-badge.premium {
  background: #ecd007;
}

.ta-plan-badge.enterprise {
  background: #CB3B43;
  color: #fff;
}

.ta-plan-badge.private {
  background: #505a5e;
  color: #fff;
}
