.ta-badge {
  border-radius: 3px;
  text-transform: capitalize;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  color: #ffffff;
  padding: 2px 4px;
  display: inline;
  margin-right: 4px;
  height: 16px;
  line-height: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ta-badge i {
  margin-right: 4px;
}