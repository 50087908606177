.ta-users {
  margin-bottom: 25px;
}

.ta-users .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 4px;
}

.ta-users__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-users__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-users .ta-title__content .ta-title__link {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
}

.ta-users .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

/* .ta-users .ta-title {
  margin-bottom: 10px;
} */

.ta-users .ta-fixed-content__body {
  top: 110px;
}

.ta-users .ta-simple-list-item {
  color: #505a5e;
  font-weight: 500;
  min-height: 77px;
}

.ta-users__user.is-stalled {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ecd007;
  width: 30px;
  border-radius: 0 2px 2px 0;
}

.ta-users__preview .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 54px;
  background: #fff;
  left: 24px;
  right: 24px;
}

.ta-users__user.is-stalled i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
}

.ta-users__user.is-stalled .ta-users__user.is-stalled {
  display: block;
}

.ta-users .ta-simple-list-item.draggable .ta-users__user.is-stalled {
  display: none;
}

.ta-users__user.updated {
  position: relative;
}

.ta-users__user.updated:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  animation-duration: 2s;
  animation-name: user-updated-before;
  animation-iteration-count: 1;
}

@keyframes user-updated-before {
  0% {
    opacity: 1;
    background: rgba(244, 243, 243, 1);
  }
  100% {
    opacity: 0;
    background: rgba(244, 243, 243, 0);
  }
}

.ta-users__user.updated:after {
  content: "\f021";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  opacity: 0;
  animation-duration: 2s;
  animation-name: user-updated-after;
  animation-iteration-count: 1;
}

@keyframes user-updated-after {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ta-users__user.deleted {
  position: relative;
}

.ta-users__user.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  animation-duration: 2s;
  animation-name: user-deleted-before;
  animation-iteration-count: 1;
}

@keyframes user-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0);
  }
  15% {
    background: rgba(244, 243, 243, 0.7);
  }
  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-users__user.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  animation-duration: 2s;
  animation-name: user-deleted-after;
  animation-iteration-count: 1;
}

.ta-users__user .ta-list-inline__item i {
  margin-right: 8px;
}

.ta-users__user .ta-users__avatar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ta-users__user .ta-users__avatar .ta-avatar {
  position: relative;
  width: 56px;
  height: 56px;
  line-height: 56px;
  margin-bottom: 0px;
  margin-right: 12px;
  float: left;
}

.ta-users__user .ta-users__user .ta-users__avatar {
  width: 56px;
  height: 56px;
}

.ta-users__user .ta-avatar__image {
  width: 56px;
  height: 56px;
}

.ta-users__user .ta-avatar__image img {
  width: 56px;
  height: 56px;
}

.ta-users .ta-form {
  padding-bottom: 8px;
}

.ta-users .ta-simple-list-item__title-icon {
  max-width: max-content;
  margin-left: 0;
}

.ta-users .ta-simple-list-item__title-text {
  margin-right: 8px;
}

.ta-users__user .ta-list-inline,
.ta-users__user .ta-simple-list-item__title {
  padding-left: 70px;
  word-break: break-all;
}

.ta-users__user .ta-simple-list-item__title .ta-simple-list-item__title-icon {
  top: -1px;
}

.ta-users__user .ta-list-inline {
  word-break: break-all;
}

.ta-users .ta-form-control {
  -webkit-text-fill-color: #505a5e;
}

.ta-users__user .ta-simple-list-item .ta-simple-list-item__content .ta-simple-list-item__title .ta-simple-list-item__title-icon .ta-badge {
  display: inline-block;
  margin-right: 0;
  width: max-content;
  max-width: 370px;
  margin-right: 4px;
}
