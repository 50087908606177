.ta-apps__preview .ta-title__label {
  max-width: 95%;
}

.ta-apps__preview .ta-fixed-content__body {
  bottom: 50px;
}

.ta-apps__preview.no-access .ta-fixed-content__body .ta-form {
  padding-bottom: 50px;
}

.ta-apps__preview .ta-fixed-content__body .ta-form {
  padding-bottom: 66px;
}

.ta-apps__preview .ta-offline {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ta-apps__preview .ta-offline .ta-offline-body {
  width: 100%;
  height: 100%;
}

.ta-apps__preview .ta-section-title.pricing {
  padding-left: 26px;
}

.ta-apps__preview .ta-section-title.pricing .ta-section-title__extra-label {
  text-transform: uppercase;
  float: right;
  color: #fff;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: 500;
  background-color: #95c5a6;
  border-radius: 3px;
  line-height: 1.2;
  height: 16px;
  margin-top: 2px;
}

.ta-apps__preview .ta-striped-title {
  margin-top: 16px;
}

.ta-apps__preview .ta-striped-title .ta-striped-title__label {
  font-size: 10px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.5px;
}

.ta-apps__preview.no-access .ta-apps__preview__fixed-bottom-box:after {
  display: none;
}

.ta-apps__preview .ta-bordered-box {
  margin: 0;
  box-shadow: #fff 0 -4px 16px 4px;
}

.ta-apps__preview__head-version {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  font-weight: 500;
  color: #505a5e;
  border-radius: 3px;
  background: #b6babd;
  margin-right: 3px;
  font-style: italic;
}

.ta-apps__preview__gallery {
  height: 220px;
  margin-bottom: 8px;
}

.ta-apps__preview__cover {
  width: 100%;
  margin-bottom: 8px;
}

.ta-apps__preview__cover img {
  width: 100%;
  border-radius: 3px;
}

.ta-apps__preview__description {
  font-size: 13px;
  font-weight: 100;
  color: #505a5e;
  line-height: 18px;
  margin-bottom: 16px;
}

.ta-apps__preview__usfeful-link {
  display: block;
  background: #b6babd;
  border-radius: 3px;
  height: 32px;
  line-height: 32px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
}

.ta-apps__preview__fixed-bottom-box {
  position: fixed;
  background: #fff;
  margin-bottom: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 15px;
  padding-top: 0;
}

.ta-apps__preview__fixed-bottom-box button {
  box-shadow: #fff 0 -4px 16px 4px;
}

.ta-apps-paid-install .ta-popup__content-inner {
  max-height: 600px;
}

.ta-apps__preview__install-text {
  padding-bottom: 0;
  font-weight: 300;
  line-height: 16px;
}

.ta-apps__preview__roles {
  font-size: 13px;
  font-weight: 100;
  color: #505a5e;
}

.ta-apps__preview__roles>ul>li {
  border: 1px solid rgba(182, 186, 189, 0.5);
  border-radius: 3px;
  margin-bottom: 8px;
  position: relative;
  padding-left: 40px;
}

.ta-apps__preview__roles-role {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
}

.ta-apps__preview__roles-icon {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #cb3b43;
  color: #fff;
  border-radius: 3px 0 0 3px;
}

.ta-apps__preview__roles-types {
  display: inline-block;
  margin-left: 5px;
}