.ta-permission-types__custom-accordion .ta-accordion__title .ta-title {
  border: none;
  margin-bottom: 4px;
}

.ta-permission-types__custom-accordion {
  padding: 3px 8px 0 8px;
  border-radius: 3px;
  border: solid 1px #cbced0;
  background-color: #fff;
  margin-bottom: 8px;
}

.ta-permission-types__custom-accordion .ta-title {
  padding: 0;
}

.ta-permission-types__custom-accordion.expand {
  border: solid 1px #969c9e;
  padding-bottom: 8px;
}

.ta-permission-types__custom-accordion .ta-title.small {
  margin-bottom: 0;
}

.ta-permission-types__custom-accordion .ta-accordion__title:after {
  content: "\f32d";
}

.ta-permission-types__custom-accordion.expand .ta-accordion__title:after {
  content: "\f331";
}
