.ta-chart {
  background: #fff;
  border-radius: 6px;
  padding: 4px;
  border: 1px solid #cbced0;
  min-height: 288px;
  position: relative;
}

.ta-chart .ta-hover-popup.ta-statistics__info-popup {
  position: absolute;
  bottom: 0;
  right: 0;
}

.ta-chart .ta-form {
  padding-bottom: 0;
}

.ta-chart .ta-form-group {
  padding-top: 0;
}

.ta-chart__header {
  background: #dcdedf;
  padding: 8px;
  height: 34px;
  line-height: 34px;
  border-radius: 3px 3px 0 0;
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.ta-chart__header.big {
  height: 48px;
  line-height: 48px;
}

.ta-chart .ta-statistics-box__label {
  width: 20px; 
  height: 14px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-chart .ta-statistics-box__label i {
  position: relative;
  color: #fff;
  font-size: 8px;
}

.ta-chart__header-label {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ta-chart__header .ta-dashboard-statistics {
  padding: 0;
  font-size: 16px;
  line-height: 16px;
}

.ta-chart__header .ta-dashboard-statistics__link {
  font-size: 16px;
  color: rgba(80, 90, 94, 0.6);
  cursor: pointer;
}

.ta-chart__header .ta-hover-popup__content {
  width: max-content;
  max-width: 256px;
  text-align: center;
  line-height: 1;
}

.ta-chart__header .ta-popup__trigger {
  display: flex;
  align-items: center;
}

.ta-chart__header-label-text {
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 900;
}

.ta-chart__header-info-text {
  font-weight: normal;
}

.ta-chart__header-label-second {
  font-weight: normal;
}

.ta-chart__header-label-third {
  color: rgba(80, 90, 94, 0.7);
  margin-right: 3px;
}

.ta-chart__header-extra--text {
  height: 14px;
  line-height: 14px;
  font-size: 10px;
  margin-right: 3px;
  display: flex;
  text-transform: uppercase;
}

.ta-chart-header-label__info-container {
  display: flex;
}

.ta-chart .recharts-tooltip-wrapper {
  text-align: center;
}

.ta-chart__header-increase {
  position: relative;
  float: right;
  font-size: 9px;
  color: #fff;
  height: 34px;
  line-height: 10px;
  padding-top: 20px;
  background: #769851;
  width: 34px;
  text-align: center;
}

.ta-chart__header-increase:after {
  content: "\f176";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 6px;
  left: 50%;
  font-size: 8px;
  color: #fff;
  transform: translateX(-50%);
}

.ta-chart__header-decrease {
  position: relative;
  float: right;
  font-size: 9px;
  color: #fff;
  height: 34px;
  line-height: 10px;
  padding-top: 20px;
  background: #cb3b43;
  width: 34px;
  text-align: center;
}

.ta-chart__header-decrease:after {
  content: "\f175";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 6px;
  left: 50%;
  font-size: 8px;
  color: #fff;
  transform: translateX(-50%);
}

.ta-chart__header-info {
  float: right;
  background: #385f87;
  color: #fff;
  padding: 0 20px;
  font-size: 12px;
}

.ta-chart__header-label-tag {
  width: min-content;
  height: 14px;
  padding: 4px;
  line-height: 14px;
  font-size: 10px;
  background: #b0b5b6;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-transform: uppercase;
}

.ta-chart__header-settings-button {
  float: right;
  color: rgba(80, 90, 94, 0.6);
  font-size: 16px;
  line-height: 16px;
  margin-top: 10px;
  cursor: pointer;
}

.ta-chart__vertical-legend {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  line-height: 16px;
  width: 100%;
}

.ta-chart__vertical-legend li {
  margin-bottom: 5px;
}

.ta-chart__vertical-legend__value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-chart__vertical-legend__color {
  width: 16px;
  height: 8px;
  transform: translateY(4px);
  border-radius: 3px;
  margin-right: 5px;
  float: left;
}

.ta-chart__vertical-legend__color__value--text {
  margin-left: 20px;
  opacity: 0.8;
  font-size: 10px;
  font-weight: 500;
}

.ta-chart__pie-active-label {
  font-size: 10px;
  fill: #505a5e;
  opacity: 0.8;
}

.ta-chart__pie-active-percent {
  font-size: 8px;
  fill: #505a5e;
  opacity: 0.6;
}

/* SVG Chart */

.recharts-legend-wrapper {
  top: 0!important;
  left: 58%!important;
  right: 0!important;
  bottom: 0!important;
  width: auto!important;
}

.recharts-layer .recharts-cartesian-axis-tick text {
  font-size: 10px;
  fill: #505a5e;
  opacity: 0.8;
}

.recharts-cartesian-grid-horizontal line, .recharts-cartesian-grid-vertical line {
  stroke: #c9c8c6;
}

.recharts-cartesian-axis-tick-line {
  stroke: none;
}

.recharts-cartesian-axis-line {
  stroke: none;
}

.recharts-text.recharts-pie-label-text {
  font-size: 10px;
  fill: #505a5e;
  opacity: 0.8;
}

.recharts-curve.recharts-pie-label-line {
  stroke: none;
}

.ta-chart__custom-tooltip {
  background: #fff;
  padding: 10px  10px 15px 10px;
  border: 1.2px solid #c9c8c6;
}

.ta-chart__custom-tooltip.new {
  width: 208px;
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  background: #fff;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #505a5e;
}

.ta-chart__custom-tooltip.new .ta-chart__custom-tooltip__data {
  color: #505a5e;
  margin-bottom: 2px;
  font-weight: 900;
}

.ta-chart__custom-tooltip__data {
  margin-top: 5px;
  color: #769851;
}

.ta-chart .ta-form-control__addon {
  top: 0;
}

.ta-chart__canvas .ta-statistic-box__title__no-results {
  text-align: center;
  width: max-content;
  position: absolute;
  left: 50%;
  top: 57%;
  transform: translate(-50%, -50%);
}

.ta-chart__canvas .ta-statistic-box__title__no-results i {
  margin-bottom: 5px;
}