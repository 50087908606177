.ta-company-tags__preview.global.edited .ta-simple-tabs.scrollable-content .ta-simple-tabs__body,
.ta-company-tags__preview.global.marked-for-deletion .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 194px;
}

.ta-company-tags__preview.global .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 143px;
}

.ta-company-tags__preview.global .ta-message.active {
  min-height: 88px;
}

.ta-company-tags__preview.global .ta-btn-reset-global,
.ta-company-tags__preview.global .ta-btn-delete-global {
  margin-top: 16px;
  width: min-content;
  white-space: nowrap;
}

.ta-company-tags__preview.global .ta-message .ta-popup__trigger {
  display: block;
  margin-top: 16px;
}

.ta-company-tags__preview.global.marked-for-deletion .ta-message .ta-popup__trigger {
  width: 110px;
}

.ta-company-tags__preview.global .ta-message .ta-popup__trigger {
  display: block;
  margin-top: 16px;
  width: 110px;
}

.ta-company-tags__preview__details .ta-simple-table {
  text-align: center;
}

.ta-company-tags__preview__details__header {
  position: relative;
  margin-bottom: 20px;
}

.ta-company-tags__preview__details__status {
  display: inline-block;
  padding: 0 5px;
  height: 12px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  background: #b6babd;
  font-size: 8px;
  font-weight: 500;
  color: #505a5e;
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
}

.ta-company-tags__preview__details__status.active {
  background: #769851;
  color: #fff;
}

.ta-company-tags__preview__details__owner {
  border: 2px solid #f4f3f3;
  background: #ecd007;
  font-size: 13px;
  color: #505a5e;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translateX(14px);
}

.ta-company-tags__preview__details .ta-user-preview__name {
  margin-top: -5px;
  margin-bottom: 0;
}

.ta-company-tags__preview .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  padding-bottom: 20px;
  overflow: visible;
}

.ta-company-tags__preview .ta-simple-tabs__body-item.ta-simple-tabs-courses, .ta-company-tags__preview .ta-simple-tabs__body-item.ta-simple-tabs-tags {
  padding-top: 5px;
}

.ta-company-tags__preview .ta-tags__preview__courses .ta-no-results, .ta-company-tags__preview .ta-company-tags__preview__tags .ta-no-results {
  margin-top: 15px;
}

.ta-company-tags__preview .ta-tags__preview__courses .ta-simple-list-item__title, .ta-company-tags__preview .ta-company-tags__preview__tags .ta-simple-list-item__title {
  font-weight: 500;
  margin-bottom: 0;
  position: relative;
  top: 1px;
}

.ta-company-tags__preview__details__color {
  position: static;
}

.ta-company-tags__preview__details__confirmed {
  display: inline-block;
  background: rgba(118, 152, 81, 0.15);
  color: #769851;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 11px;
  font-weight: 500;
  margin-left: 5px;
}

.ta-company-tags__preview__details__invitation {
  background: rgba(203, 59, 67, 0.15);
  color: #cb3b43;
  border-radius: 3px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  font-weight: 500;
  margin-top: 5px;
}

.ta-company-tags__preview__details__invitation i {
  margin-right: 5px;
}

.ta-company-tags__preview__details__invitation__cta {
  float: right;
  color: #505a5e;
  font-weight: 300;
  cursor: pointer;
  text-decoration: underline;
}

.ta-customer-fields__customer-field.disabled .ta-simple-list-item {
  background: none;
  border: 1px solid rgba(182, 186, 189, 0.5);
}

.ta-company-tags__preview--changed-locally--branch {
  min-height: 80px;
  border-radius: 3px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  background-color: #ffffff;
  color: #5b6569;
  padding: 16px;
  margin: 5px 0;
  display: flex;
}

.ta-company-tags__preview--changed-locally--branch__data {
  width: 70%;
}

.ta-company-tags__preview--changed-locally--branch__buttons {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ta-company-tags__preview--changed-locally--branch__name {
  font-size: 15px;
  font-weight: 500;
}

.ta-company-tags__preview--changed-locally--item {
  display: flex;
  margin: 5px 0;
  font-size: 12px;
}

.ta-company-tags__preview--changed-locally--item i {
  margin-right: 5px;
}