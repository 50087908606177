.ta-statistic-box .ta-statistic-box__title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  padding-top: 12px;
  color: #365e89;
}

.ta-simple-box.large {
  height: 170px;
}

.ta-simple-box.large .ta-statistic-box .ta-statistic-box__title {
  margin-top: 25px;
}

.ta-statistic-box .ta-statistic-box__info {
  margin-top: 8px;
  opacity: 0.7;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: left;
  color: #505a5e;
  padding-bottom: 17px;
}

.ta-statistic-box .ta-statistic-box__title__icon {
  font-size: 16px;
  margin-left: 8px;
}

.ta-statistic-box .ta-statistic-box__span {
  font-weight: 600;
}

.ta-statistic-box .ta-statistic-box__info__item {
  margin-bottom: 4px;
  text-transform: uppercase;
}

.ta-statistic-box .ta-statistic-box__title__no-results i {
  font-size: 16px;
  margin-bottom: 4px;
}

.ta-statistic-box .ta-statistic-box__title__no-results {
  font-size: 12px;
  font-weight: normal;
  color: rgba(80, 90, 94, 0.6)
}

.ta-statistic-box .ta-statistic-box__title__no-results > p {
  color: #505a5e;
  line-height: 1.33;
}

.ta-statistic-box .ta-statistic-box__title__no-results--text {
  font-weight: 900;
  color: #505a5e;
}

.ta-statistic-box .percentages {
  display: flex;
  flex-direction: column;
}

.ta-statistic-box .percentages .percentages__figures {
  font-size: 12px;
  color: #505a5e;
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  margin-bottom: 2px;
}

.ta-statistic-box .percentages .percentages__figures p span {
  font-weight: 900;
}

.ta-statistic-box .percentages__available-capacity {
  font-size: 10px;
  font-weight: 500;
  opacity: 0.8;
  text-transform: uppercase;
}

.ta-statistic-box .percentage-container {
  width: 100%;
  padding: 2px;
  height: 11px;
  overflow: hidden;
  background: #d3d6d7;
  border-radius: 5.5px;
}

.ta-statistic-box .percentage-container .percentage-bar {
  background: #385f87;
  height: 100%;
  border-radius: 5.5px;
}

.ta-statistic-box .percentages__data {
  width: 100%;
  display: flex;
}

.ta-statistic-box .percentages__data .percentages__data__type {
  font-size: 10px;
  font-weight: normal;
  color: #505a5e;
  display: flex;
  margin-top: 8px;
  margin-right: 8px;
}

.ta-statistic-box .percentages__data .percentages__data__type--color {
  width: 16px;
  height: 8px;
  border-radius: 3px;
  margin-right: 5px;
}
