.ta-services .ta-title__content .ta-btn,
.ta-services .ta-title__content .ta-btn.ta-btn-icon-only,
.ta-services__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 4px;
}

.ta-services .ta-title__content .ta-btn.services-folder-icon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 4px;
}

.ta-services .ta-title__content .ta-title__link {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
}

.ta-services .ta-services__category::after {
  content: "";
  position: fixed;
  top: 193px;
  left: 0px;
  height: 15px;
  width: calc(100% - 48px);
  margin: 0 24px;
  z-index: 999;
  background: linear-gradient(to bottom, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
}

.ta-services .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-services__preview .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  background: #fff;
  left: 24px;
  right: 24px;
}

.ta-services .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 54px;
  left: 24px;
  right: 24px;
}

.ta-services .ta-form {
  padding-bottom: 0;
}

.ta-services__list .ta-sortable-list {
  margin-top: 5px;
}

.ta-services__service.global .ta-simple-list-item {
  height: 88px;
  padding: 0;
}

.ta-services__preview .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-services__list .ta-hover-popup {
  color: #505a5e;
}

.ta-services__list .ta-hover-popup:hover {
  color: #385f87;
}

.ta-popup__services-help-box iframe {
  margin-top: 8px;
}

.ta-services__service.updated {
  position: relative;
}

.ta-services__service.updated:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  animation-duration: 2s;
  animation-name: service-updated-before;
  animation-iteration-count: 1;
}

@keyframes service-updated-before {
  0% {
    opacity: 1;
    background: rgba(244, 243, 243, 1);
  }

  100% {
    opacity: 0;
    background: rgba(244, 243, 243, 0);
  }
}

.ta-services__service.updated:after {
  content: "\f021";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  opacity: 0;
  animation-duration: 2s;
  animation-name: service-updated-after;
  animation-iteration-count: 1;
}

@keyframes service-updated-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-services__service.deleted {
  position: relative;
}

.ta-services__service.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  animation-duration: 2s;
  animation-name: service-deleted-before;
  animation-iteration-count: 1;
}

@keyframes service-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-services__service.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  animation-duration: 2s;
  animation-name: service-deleted-after;
  animation-iteration-count: 1;
}

@keyframes service-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-services__service__is-stalled {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ecd007;
  width: 30px;
  border-radius: 0 2px 2px 0;
}

.ta-services__service__is-stalled i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
}

.ta-services__service.is-stalled .ta-services__service__is-stalled {
  display: block;
}

.ta-services .ta-simple-list-item {
  border: 1px solid rgba(182, 186, 189, 0.5);
}

.ta-services .ta-simple-list-item.draggable .ta-services__service__is-stalled {
  display: none;
}

.ta-services__service>div {
  padding-bottom: 8px;
}

.ta-services__category {
  position: relative;
  padding: 0 0 15px 0;
}

.ta-services__category-type.global {
  color: #505a5e;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  height: 16px;
  line-height: 16px;
  padding: 0 5px;
  border: 1px solid #b6babd;
  border-radius: 3px;
  font-size: 10px;
  transform: translateY(-2px) translateZ(0);
}

.ta-services__category .ta-section-title {
  margin-top: 0;
}

.ta-services__category .ta-section-title .ta-grab {
  cursor: grab;
}

.ta-services__category.pending:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f4f3f3;
  opacity: 0.5;
  z-index: 99;
}

.ta-services__category.pending:after {
  content: "\f110";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #505a5e;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  z-index: 100;
}

.ta-services__category.deleted {
  position: relative;
}

.ta-services__category.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  animation-duration: 2s;
  animation-name: service-category-deleted-before;
  animation-iteration-count: 1;
}

@keyframes service-category-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0.5);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-services__category.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  animation-duration: 2s;
  animation-name: service-category-deleted-after;
  animation-iteration-count: 1;
}

@keyframes service-category-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-services .ta-simple-list-item {
  margin-bottom: 0;
}

.ta-services .ta-simple-list-item .ta-list-button {
  cursor: grab;
}

.ta-services .ta-simple-list-item .ta-list-button:hover {
  background: #b6babd;
  color: #505a5e;
}

.ta-services.categories-collapse .ta-services__list {
  max-height: 50px;
  overflow: hidden;
}

.ta-services.categories-collapse .ta-services__list {
  position: relative;
}

.ta-services.categories-collapse .ta-services__list:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.ta-services.categories-collapse .ta-services__list:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  z-index: 99;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#f4f3f3', GradientType=0);
}

.ta-services.categories-collapse .ta-list-button {
  display: none;
}

/* ***********************************************

FIX DND COMPONENT

Issue: https://github.com/atlassian/react-beautiful-dnd/issues/703
Remove left prop when fix is provide

*********************************************** */

.ta-services__category__dnd-wrapper,
.ta-services__service__dnd-wrapper {
  z-index: 0;
  left: 15px !important;
}

.ta-services__category__dnd-wrapper.is-dragging .ta-services__category {
  opacity: 0.8;
}

.ta-services__service__dnd-wrapper.is-dragging .ta-simple-list-item {
  opacity: 0.8;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
}

.ta-services .ta-services__category.has-buttons .ta-accordion .ta-accordion__title:after {
  right: 118px;
}

.ta-services .ta-services__category.no-delete-permissions .ta-accordion .ta-accordion__title:after {
  right: 90px;
}

.ta-services .ta-services__category.has-buttons.default .ta-accordion .ta-accordion__title:after {
  right: 62px;
}

.ta-services .ta-services__category.has-buttons.no-write-permissions .ta-accordion .ta-accordion__title:after {
  right: 33px;
}

.ta-services .ta-services__category.global .ta-hover-popup__trigger {
  white-space: nowrap;
}

.ta-hover-popup.ta-service__popup {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30px;
}

.ta-service__popup .ta-hover-popup__content.left {
  width: 300px;
}

.ta-simple-list-item.ta-services__service.global .ta-simple-list-item__buttons {
  top: 63%;
}

.ta-services__category__delete-btn {
  opacity: 0.5;
}

.ta-services .ta-accordion__body {
  position: relative;
  z-index: -1;
}

.ta-services__category .ta-accordion__title {
  position: relative;
  z-index: -1;
}

.ta-services__category .ta-accordion__title:hover {
  z-index: 1;
}

.ta-services .ta-simple-list-item__title-icon .global-label {
  font-size: 10px;
  border: 1px solid #b6babd;
  color: #505a5e;
  line-height: 1.2;
  padding: 1px 4px;
  font-weight: 500;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-services .ta-simple-list-item__title-icon .global-label.edited,
.ta-services__category .global.edited {
  border: solid 1px rgba(235, 182, 93, 0.4);
  background-color: rgba(235, 182, 93, 0.2);
}

.ta-services__category .global.edited {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ta-services__category .ta-default-category__text {
  margin-right: 5px;
}

.ta-services .ta-simple-list-item__title-icon .global-label.deleted {
  border: solid 1px rgba(203, 59, 67, 0.4);
  background-color: rgba(203, 59, 67, 0.2);
}

.ta-services .ta-simple-list-item__title-icon .ta-hover-popup__trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.ta-services .ta-simple-list-item__title-icon .ta-hover-popup__trigger i,
.ta-services__category .global.edited i {
  margin-right: 2px;
}

.ta-services-to-delete--branch {
  min-height: 80px;
  border-radius: 3px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  background-color: #ffffff;
  color: #5b6569;
  padding: 16px;
  margin: 5px 0;
  display: flex;
}

.ta-services-to-delete .ta-simple-list-item {
  padding-right: 10px;
}

.ta-services-to-delete .ta-message {
  margin-top: 0;
}

.ta-services-to-delete--branch__data {
  width: 70%;
}

.ta-services-to-delete--branch__buttons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-services-to-delete--branch__buttons button {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  background: #cb3b43;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.ta-services-to-delete--branch__name {
  font-size: 15px;
  font-weight: 500;
}

.ta-services-to-delete--item {
  display: flex;
  margin: 5px 0;
  font-size: 12px;
}

.ta-services-to-delete--item i {
  margin-right: 5px;
}

.ta-global-label__text {
  font-style: normal;
}

.ta-services .ta-simple-list-item.has-heading .ta-services__service__is-stalled {
  top: 24px;
  border-radius: 0 0 2px 0;
}

.ta-simple-list-item.has-heading .ta-simple-list-item__buttons {
  top: 64%;
}

.ta-services .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  height: 100%;
}

.ta-services .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item .ta-fixed-content__header {
  padding: 20px;
}

.ta-services .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item .ta-fixed-content__body {
  padding: 5px 20px 20px;
  top: 76px;
}

.ta-services .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item .ta-fixed-content:before {
  top: 170px;
  left: 35px;
  right: 35px;
  background: linear-gradient(to bottom, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
}

.ta-globals__hint {
  padding-left: 25px;
}

.ta-services__category .ta-accordion .ta-accordion__body {
  overflow: initial;
}

.ta-services .ta-dotted-timeline__item {
  line-height: 1;
}

.ta-services__service__branch-overtires-button.ta-btn {
  padding: 0;
  height: fit-content;
}

.ta-btn.ta-popup__services-create-type-select__btn {
  position: relative;
  border: 1px solid #cbced0;
  padding: 8px;
  padding-left: 32px;
  border-radius: 3px;
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  color: #505a5e;
  font-weight: normal;
  height: initial;
  line-height: inherit;
}

.ta-btn.ta-popup__services-create-type-select__btn:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 8px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border: 1px solid rgba(80, 90, 94, 0.6);
  transform: translateY(-50%);
}


.ta-btn.ta-popup__services-create-type-select__btn:hover {
  background: #f6f8fa;
  border-color: #505a5e;
}

.ta-btn.ta-popup__services-create-type-select__btn.last {
  margin-bottom: 0;
}

.ta-btn.ta-popup__services-create-type-select__btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ta-popup__services-create-type-select__btn__title {
  font-weight: 500;
  display: block;
}