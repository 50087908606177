.ta-branches-resources__form__times .ta-simple-tabs__body {
  margin-bottom: 0;
}

.ta-branches-resources__form .ta-branches-resources__form__dependency {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  color: #b6babd;
}

.ta-resources__form__acl .ta-accordion__title .ta-title {
  margin-bottom: 4px;
}

.ta-branches-resources__form__times .ta-message {
  margin-bottom: 0;
}

.ta-branches-resources__form__avatar {
  float: left;
  width: 134px;
  margin: 0 3px;
}

.ta-branches-resources__form__details {
  min-height: 134px;
}

.ta-branches-resources__form__details-fields {
  padding-left: 150px;
}

.ta-branches-resources__form__details-fields .ta-message.active {
  margin: 8px 0 0 0;
}

.ta-branches-resources__form__details .ta-alert {
  margin-top: 8px;
}

.ta-branches-resources__form__acl .ta-blur {
  height: 170px;
}

.ta-branches-resources__form__acl .ta-blur .ta-blur__content__text {
  margin-top: 0;
}

.ta-branches-resources__form__acl .ta-accordion {
  margin-bottom: 4px;
}

.ta-branches-resources__form__acl .ta-bordered-box .ta-row {
  margin-bottom: 0;
}

.ta-branches-resources__form__acl .ta-bordered-box .ta-bordered-box {
  margin-top: 4px;
  margin-bottom: 2px;
}

.ta-branches-resources__form__acl .ta-bordered-box .ta-bordered-box .ta-row {
  margin-bottom: 4px;
}

.ta-branches-resources__form__acl .ta-bordered-box .ta-bordered-box .ta-row:last-child {
  margin-bottom: 0;
}

.ta-branches-resources__form__acl .ta-bordered-box .ta-bordered-box .ta-striped-title {
  margin: 4px 0;
}

.ta-branches-resources__form__acl .ta-hint-text {
  margin-bottom: 4px;
}

.ta-branches-resources__form__acl__label {
  opacity: 0.7;
  font-size: 10px;
  font-weight: 500;
  color: #505a5e;
  line-height: 14px;
  margin-top: 8px;
}

.ta-branches-resources__form .ta-title__extra_text {
  color: #a1a6a8;
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
}

.ta-bordered-box.ta-branches-resources__form__details__unlink-box {
  position: relative;
  border-radius: 6px;
}

.ta-branches-resources__form__details__unlink-box.pending:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  z-index: 2;
}

.ta-branches-resources__form__details__unlink-box .ta-spinner {
  z-index: 4;
}

.ta-branches-resources__form__details__unlink-box .ta-popup__trigger {
  margin-left: 5px;
}

.ta-branches-resources__form .ta-branches-resources__form__question-circle-icon{
  font-size: 12px;
  color: rgba(80, 90, 94, 0.7);
}

.ta-branches-resources__form__permissions__overlay {
  display: block !important;
}

.ta-branches-resources__form__permissions__overlay__trigger {
  position: relative;
  height: 30px;
  background: #f4f3f3;
  opacity: 0.5;
  margin-bottom: -30px;
  z-index: 100;
  border-radius: 4px;
}

.ta-resources__form__acl .ta-blur {
  height: 170px;
}

.ta-resources__form__acl .ta-blur .ta-blur__content__text {
  margin-top: 0;
}

.ta-resources__form__acl .ta-accordion {
  margin-bottom: 4px;
}

.ta-resources__form__acl .ta-bordered-box .ta-row {
  margin-bottom: 0;
}

.ta-resources__form__acl .ta-bordered-box .ta-bordered-box {
  margin-top: 4px;
  margin-bottom: 2px;
}

.ta-resources__form__acl .ta-bordered-box .ta-bordered-box .ta-row {
  margin-bottom: 4px;
}

.ta-resources__form__acl .ta-bordered-box .ta-bordered-box .ta-row:last-child {
  margin-bottom: 0;
}

.ta-resources__form__acl .ta-bordered-box .ta-bordered-box .ta-striped-title {
  margin: 4px 0;
}

.ta-resources__form__acl .ta-hint-text {
  margin-bottom: 4px;
}

.ta-resources__form__acl__label {
  opacity: 0.7;
  font-size: 10px;
  font-weight: 500;
  color: #505a5e;
  line-height: 14px;
  margin-top: 8px;
}

/* IE FIX */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .ta-branches-resources__form__avatar .ta-image-input__sliders {
    display: none;
  }
}
