.ta-icon-list {
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: #505a5e;
  padding: 3px 0 3px 25px;
  position: relative;
  border-bottom: 1px dotted #919191;
  word-break: break-word;
}

.ta-icon-list.flex {
  display: flex;
}

.ta-icon-list.no-icon {
  padding-left: 0;
}

.ta-icon-list.no-border {
  border-bottom: none;
}

.ta-icon-list:last-child {
  border-bottom: none;
}

.ta-icon-list i.ta-icon-list__icon {
  position: absolute;
  top: 7px;
  left: 0;
  width: 20px;
  text-align: center;
}

.ta-icon-list a {
  color: #385f87;
  text-decoration: underline;
  padding: 0;
  font-weight: normal;
  height: auto;
}

.ta-icon-list a:hover {
  text-decoration: none;
}

.ta-icon-list .ta-popup__trigger a {
  color: #505a5e;
}

.ta-icon-list__title {
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}

.ta-icon-list:not(.flex) .ta-icon-list__title {
  vertical-align: top;
}

.ta-icon-list__title.with-border {
  border-bottom: 1px dotted #919191;
  padding-bottom: 3px;
}

.ta-icon-list__body {
  display: inline-block;
  word-break: break-word;
  max-width: 100%;
}

.ta-icon-list__body.block {
  display: block;
}

.ta-icon-list__body.with-border {
  border-bottom: 1px dotted #919191;
  padding-bottom: 3px;
  min-height: 25px;
}

.ta-icon-list__buttons {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.ta-icon-list__buttons .ta-btn {
  height: 22px;
  font-size: 10px;
  margin-left: 2px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-icon-list__buttons .ta-btn.ta-btn-icon-only {
  width: 22px;
  height: 22px;
  border-radius: 4px;
}

.ta-icon-list__buttons .ta-hover-popup {
  margin-left: 2px;
}

.ta-icon-list__buttons .ta-hover-popup .ta-btn {
  margin-left: 0;
}

.ta-icon-list__buttons .ta-spinner__wrapper {
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  font-size: 10px;
  position: relative;
  vertical-align: middle;
}