.ta-copy-to-clipboard {
  position: relative;
}

.ta-copy-to-clipboard__button.ta-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  width: 33px;
  z-index: 50;
  height: auto;
  font-size: 14px;
  border-radius: 4px;
  user-select: none;
  transition: all .2s;
}

.ta-copy-to-clipboard__button.ta-btn.text-button {
  font-size: 12px;
  font-weight: bold;
  width: auto;
  min-width: 33px;
  text-align: center;
  padding: 0 8px;
  height: 32px;
  line-height: 30px;
}

.ta-copy-to-clipboard.copied .ta-copy-to-clipboard__button.ta-btn {
  background: #769851;
  border-color: #769851;
  font-size: 0;
}

.ta-copy-to-clipboard.copied .ta-copy-to-clipboard__button.ta-btn:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  font-size: 18px;
}

.ta-copy-to-clipboard__button-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
