.ta-course-category--type {
  padding: 0 3px;
  border-radius: 3px;
  width: max-content;
  font-size: 10px;
  font-weight: 500;
  height: 16px;
  display: flex;
  align-items: center;
}

.ta-course-category--type.bookable {
  background: #769851;
  color: #fff;
}

.ta-course-category--type.dependency {
  background: #ecd007;
  color: #505a5e;
}

.ta-course-categories__preview--changed-locally--branch {
  min-height: 80px;
  border-radius: 3px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  background-color: #ffffff;
  color: #5b6569;
  padding: 16px;
  margin: 5px 0;
  display: flex;
}

.ta-course-categories__preview--changed-locally--branch__data {
  width: 70%;
}

.ta-course-categories__preview--changed-locally--branch__buttons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-course-categories__preview--changed-locally--branch__buttons button {
  font-size: 10px;
  font-weight: 500;
  color: #5b6569;
  background: #ecd007;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.ta-course-categories__preview--changed-locally--branch__name {
  font-size: 15px;
  font-weight: 500;
}

.ta-course-categories__preview--changed-locally--item {
  display: flex;
  margin: 5px 0;
  font-size: 12px;
}

.ta-course-categories__preview--changed-locally--item i {
  margin-right: 5px;
}

.ta-global-label__text {
  font-style: normal;
}