.ta-emails-settings .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 4px;
}

.ta-emails-settings__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-emails-settings__preview .ta-title__content> :not(:first-child) {
  margin-left: 4px;
}

.ta-emails-settings .ta-title__content .ta-title__link {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
}

.ta-emails-settings .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-emails-settings__form .ta-fixed-content__body {
  padding-top: 10px;
}

.ta-emails-settings .ta-simple-tabs__header {
  position: relative;
}

.ta-emails-settings .ta-simple-tabs__header:after {
  content: "";
  position: absolute;
  top: 30px;
  left: 0px;
  height: 15px;
  width: 100%;
  z-index: 999;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
}

.ta-emails-settings .ta-section-title {
  margin-top: 0;
  margin-bottom: 4px;
}

.ta-emails-settings .ta-form-section {
  margin-bottom: 0;
}

.ta-emails-settings li {
  list-style-type: none;
  position: relative;
  padding-left: 12px;
}

.ta-emails-settings li:before {
  content: "\f111";
  font-family: "Font Awesome 5 Pro";
  margin-right: 6px;
  font-weight: bold;
  font-size: 6px;
  position: absolute;
  left: 0;
}

.ta-settings__form .ta-settings-notifications__reminders-container,
.ta-settings__form .ta-settings-notifications__recipients-container {
  position: relative;
  margin-bottom: 8px;
}

.ta-settings__form .ta-settings-notifications__reminder.has-delete,
.ta-settings__form .ta-settings-notifications__recipient.has-delete {
  margin-right: 30px;
}

.ta-settings__form .ta-settings__btn-delete.ta-btn {
  width: 24px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
}

.ta-emails-settings__emails .ta-bordered-box.no-border {
  border: none;
  padding: 0 13px;
}

.ta-settings__notifications__daily-agenda__resource-group-item {
  display: inline-block;
  opacity: 1;
  border-radius: 4px;
  background-color: #a4a8aa;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  padding-left: 8px;
  padding-right: 8px;
  margin: 4px 4px 0 0;
  cursor: default;
  height: 24px;
  transition: background-color .5s,opacity .5s;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 205px;
  white-space: nowrap;
  line-height: 24px;
}

.ta-settings__form__daily-agenda__resources-group__bordered-box.ta-bordered-box {
  margin-bottom: 0;
}

.ta-settings__notifications__daily-agenda__resource-group-item__striped-title .ta-striped-title__label {
  background: #fff;
}