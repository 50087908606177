.ta-globals-settings .ta-section-title {
  margin-top: 0;
}

.ta-globals-settings .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 54px;
  background: #fff;
  left: 24px;
  right: 24px;
}

.ta-globals-settings .ta-simple-tabs__body::after {
  content: "";
  position: fixed;
  top: 118px;
  left: 0px;
  height: 15px;
  width: calc(100% - 48px);
  margin: 0 24px;
  z-index: 999;
  background: linear-gradient(to bottom, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
}

.ta-globals-settings__list strong {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
}

.ta-globals-settings__attributes_list {
  list-style: initial;
  margin-left: 12px;
}

.ta-globals-settings__attributes_list li {
  margin-bottom: 4px;
}

.ta-globals-settings__attributes_list li::marker {
  color: #b6babd;
  font-size: 12px;
}

.ta-globals-settings__section_title {
  font-weight: 700;
}

.ta-globals-attributes_container {
  margin-top: 4px;
}

.ta-globals-attributes_container li {
  margin: 0 0 4px 8px;
  font-size: 12px;
  line-height: 1.33;
  color: #60676a;
}

.ta-globals-attributes_container li::marker {
  font-size: 14px;
}

.ta-globals-attributes_container .ta-striped-title__label {
  color: rgba(28, 39, 43);
}

.ta-globals-attributes_container .ta-striped-title__label {
  background-color: #fff;
}

.ta-globals-attributes_container .ta-striped-title__label strong {
  color: inherit;
  font-weight: 700;
}

.ta-globals-settings__not_permitted_text {
  font-size: 12px;
  line-height: 1.33;
  color: #505a5e;
  margin-top: 4px;
}

.ta-globals-settings .ta-title__content .ta-btn,
.ta-globals-settings__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-globals-settings .ta-title__content > :not(:first-child),
.ta-globals-settings__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-globals-settings .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
}

.ta-globals-settings .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}
