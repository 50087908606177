.ta-files-input .filepond--root {
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  margin: 0;
}

.ta-files-input .filepond--file-action-button {
  cursor: pointer;
}

.ta-files-input .filepond--drop-label {
  color: #505a5e; 
}

.ta-files-input .filepond--image-preview-wrapper,
.ta-files-input .filepond--item-panel,
.ta-files-input .filepond--panel-root {
  border-radius: 3px;
}

.ta-files-input .filepond--item-panel {
  background-color: #f4f3f3;
}

.ta-files-input .filepond--file-action-button:hover,
.ta-files-input .filepond--file-action-button:focus {
  box-shadow: none;
}

.ta-files-input .filepond--file {
  color: #505a5e;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--item-panel,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--item-panel {
  background-color: rgba(203, 59, 67, 0.1);
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--file,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file {
  color: #cb3b43;
}

.ta-files-input [data-filepond-item-state*='error'] .filepond--file-action-button,
.ta-files-input [data-filepond-item-state*='invalid'] .filepond--file-action-button {
  background-color: #cb3b43;
}

.ta-files-input .filepond--panel-bottom:before,
.ta-files-input .filepond--panel-top:after {
  display: none;
}

.ta-files-input .filepond--image-preview-overlay-success {
  color: rgba(118, 152, 81, 0.8);
}

.ta-files-input .filepond--image-preview-overlay-failure {
  color: rgba(255, 255, 255, 0.8);
}

.ta-files-input .filepond--panel-root {
  background-color: #fff;
  border: 1px dashed rgba(80, 90, 94, 0.3);
}

.ta-files-input .filepond--root .filepond--drop-label {
  min-height: 107px;
}

.ta-files-input .filepond--root :not(text) .ta-files-input__max-size {
  opacity: 0.7;
  font-size: 10px;
  color: #505a5e;
}

.ta-files-input .filepond--root :not(text) .ta-files-input__drop-or-browse {
  font-size: 12px;
}

.ta-files-input .filepond--root :not(text) .ta-files-input__label i {
  font-size: 20px;
}

.ta-files-input__types {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
}

.ta-files-input .filepond--root :not(text) .ta-files-input__type { 
  font-size: 9px;
}

.ta-files-input__type {
  border: 1px solid #505a5e;
  border-radius: 30px;
  padding: 0 4px;
  line-height: 1;
  font-weight: 500;
  color: #505a5e;
}

.ta-files-input__type:not(:last-of-type) {
  margin-right: 4px;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: #fff;
  border: 1px solid #cbced0;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file {
  color: #505a5e;
  box-shadow: none;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file-action-button {
  background-color: rgba(0,0,0, 0.3);
  border-radius: 3px;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file-action-button:hover {
  background-color: #a3a7a9;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file-action-button svg {
  display: none;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file-action-button::after {
  content: "\f2ed";
  font-family: "Font Awesome 5 Pro";
  top: 20%;
  right: -40%;
  font-weight: 900;
  font-size: 12px;
  color: #505a5e;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file-info-main {
  font-size: 12px;
  font-weight: 900;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--action-revert-item-processing~.filepond--file-info .filepond--file-info-sub {
  font-size: 12px;
}

.ta-files-input [data-filepond-item-state='processing-complete'] .filepond--file-info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  min-width: 408px;
  transform: translate3d(0px, 4px, 0px) !important; /* !important needed because the filepond plugin styles the same property in the HTML style attribute and it overwrites CSS written without !important */ 
  /* valid for all !important statements */
}

.ta-files-input .filepond--item[data-filepond-item-state='processing-complete'] {
  width: 104.6% !important;
  transform: translate3d(-15px, -15px, 0) !important;
}

.ta-files-input .filepond--item[data-filepond-item-state='load-invalid'] .filepond--file-status {
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-files-input.has-value .filepond--panel-root {
  border: none;
  background: transparent;
}

.ta-files-input.has-value {
  height: 40px;
}

.ta-files-input.has-value .filepond--root {
  height: 40px !important;
}
