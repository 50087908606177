.ta-company-tags .ta-fixed-content__body {
  overflow: visible;
}

.ta-company-tags .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 4px;
}

.ta-company-tags__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-company-tags .ta-simple-tabs.scrollable-content .ta-simple-tabs__body,
.ta-company-tags__preview .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 54px;
  background: #fff;
  left: 24px;
  right: 24px;
}

.ta-company-tags .ta-title__content .ta-title__link {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
}

.ta-company-tags .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-company-tags .ta-form {
  padding-bottom: 0;
}

.ta-company-tags__list {
  padding-bottom: 15px;
}

.ta-company-tags__list .ta-simple-tabs__body::after {
  content: "";
  position: fixed;
  top: 118px;
  left: 0px;
  height: 15px;
  width: calc(100% - 48px);
  margin: 0 24px;
  z-index: 999;
  background: linear-gradient(to bottom, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
}

/* .ta-company-tags__list .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  overflow: visible;
} */

.ta-company-tags__list .ta-sortable-list {
  margin-top: 5px;
}

.ta-company-tags__tag.global .ta-simple-list-item {
  height: 88px;
  padding: 0;
}

.ta-company-tags__list .ta-hover-popup {
  margin-left: 8px;
  color: #505a5e;
}

.ta-company-tags__list .ta-simple-tabs__header-item .ta-hover-popup {
  margin-left: 0;
}

.ta-popup__tags-help-box iframe {
  margin-top: 8px;
}

.ta-company-tags__tag.updated {
  position: relative;
}

.ta-company-tags__tag.updated:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  animation-duration: 2s;
  animation-name: tag-updated-before;
  animation-iteration-count: 1;
}

@keyframes tag-updated-before {
  0% {
    opacity: 1;
    background: rgba(244, 243, 243, 1);
  }

  100% {
    opacity: 0;
    background: rgba(244, 243, 243, 0);
  }
}

.ta-company-tags__tag.updated:after {
  content: "\f021";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  opacity: 0;
  animation-duration: 2s;
  animation-name: tag-updated-after;
  animation-iteration-count: 1;
}

@keyframes tag-updated-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-company-tags__tag.deleted {
  position: relative;
}

.ta-company-tags__tag.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  animation-duration: 2s;
  animation-name: tag-deleted-before;
  animation-iteration-count: 1;
}

@keyframes tag-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-company-tags__tag.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  animation-duration: 2s;
  animation-name: tag-deleted-after;
  animation-iteration-count: 1;
}

@keyframes tag-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-company-tags__tag>div {
  padding-bottom: 8px;
}

.ta-company-tags__category {
  position: relative;
  padding: 0 0 15px 0;
}

.ta-company-tags__category-type.global {
  background: #cb3b43;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  height: 16px;
  line-height: 16px;
  padding: 0 5px;
  margin-right: 15px;
  border-radius: 3px;
  font-size: 10px;
  transform: translateY(-2px) translateZ(0);
}

.ta-company-tags__category .ta-section-title {
  margin-top: 0;
}

.ta-company-tags__category .ta-section-title .ta-btn {
  cursor: grab;
}

.ta-company-tags__category.first .ta-hover-popup__content.left {
  top: 0;
  transform: translateY(-8px) translateZ(0);
}

.ta-company-tags__category.first .ta-hover-popup__content.left:after {
  top: 11px;
  transform: translateY(0) translateZ(0);
}

.ta-company-tags__category.pending:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f4f3f3;
  opacity: 0.5;
  z-index: 99;
}

.ta-company-tags__category.pending:after {
  content: "\f110";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #505a5e;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  z-index: 100;
}

.ta-company-tags__category.deleted {
  position: relative;
}

.ta-company-tags__category.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  animation-duration: 2s;
  animation-name: tag-category-deleted-before;
  animation-iteration-count: 1;
}

@keyframes tag-category-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0.5);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-company-tags__category.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  animation-duration: 2s;
  animation-name: tag-category-deleted-after;
  animation-iteration-count: 1;
}

@keyframes tag-category-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-company-tags .ta-simple-list-item {
  margin-bottom: 0;
}

.ta-company-tags .ta-simple-list-item .ta-list-button {
  cursor: grab;
}

.ta-company-tags .ta-simple-list-item .ta-list-button:hover {
  background: #b6babd;
  color: #505a5e;
}

.ta-company-tags.categories-collapse .ta-tags__list {
  max-height: 50px;
  overflow: hidden;
}

.ta-company-tags.categories-collapse .ta-tags__list {
  position: relative;
}

.ta-company-tags.categories-collapse .ta-tags__list:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.ta-company-tags.categories-collapse .ta-tags__list:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  z-index: 99;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#f4f3f3', GradientType=0);
}

.ta-company-tags.categories-collapse .ta-list-button {
  display: none;
}

.ta-company-tags-mock-container {
  min-height: 700px;
  position: relative;
  height: 100%;
  width: auto;
  padding: 0;
}

/* ***********************************************

FIX DND COMPONENT

Issue: https://github.com/atlassian/react-beautiful-dnd/issues/703
Remove left prop when fix is provide

*********************************************** */

.ta-company-tags__category__dnd-wrapper,
.ta-tags__tag__dnd-wrapper {
  left: 15px !important;
}

.ta-company-tags__category__dnd-wrapper.is-dragging .ta-tags__category {
  opacity: 0.8;
}

.ta-company-tags__tag__dnd-wrapper.is-dragging .ta-simple-list-item {
  opacity: 0.8;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
}

.ta-company-tags .ta-simple-list-item__title-icon {
  margin-left: 0;
  vertical-align: middle;
  top: -5px;
}

.ta-company-tags .ta-simple-list-item__title-icon .global-label {
  font-size: 10px;
  border: 1px solid #b6babd;
  color: #505a5e;
  line-height: 1.2;
  padding: 1px 4px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-company-tags .ta-simple-list-item {
  border: 1px solid rgba(182, 186, 189, 0.5)
}


.ta-company-tags .ta-simple-list-item__title-icon .global-label.edited {
  border: solid 1px rgba(235, 182, 93, 0.4);
  background-color: rgba(235, 182, 93, 0.2);
}

.ta-company-tags .ta-simple-list-item__title-icon .global-label.deleted {
  border: solid 1px rgba(203, 59, 67, 0.4);
  background-color: rgba(203, 59, 67, 0.2);
}

.ta-company-tags .ta-simple-list-item__title-icon .ta-hover-popup__trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.ta-company-tags .ta-simple-list-item__title-icon .ta-hover-popup__trigger i {
  margin-right: 2px;
  transform: translateY(-1px);
}

.ta-company-tags .ta-accordion__title {
  z-index: -1;
}

.ta-company-tags-to-delete--branch {
  min-height: 80px;
  border-radius: 3px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  background-color: #ffffff;
  color: #5b6569;
  padding: 16px;
  margin: 5px 0;
  display: flex;
}

.ta-company-tags-to-delete--branch__data {
  width: 70%;
}

.ta-company-tags-to-delete--branch__buttons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-company-tags-to-delete--branch__buttons button {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  background: #cb3b43;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.ta-company-tags-to-delete--branch__name {
  font-size: 15px;
  font-weight: 500;
}

.ta-company-tags-to-delete--item {
  display: flex;
  margin: 5px 0;
  font-size: 12px;
}

.ta-company-tags-to-delete--item i {
  margin-right: 5px;
}

.ta-company-tags .ta-fixed-content:before {
  top: 97px;
  left: 30px;
  right: 30px;
  background: linear-gradient(to bottom, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
}